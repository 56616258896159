import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  groups: {},
};

const group = createSlice({
  name: "group",
  initialState,
  reducers: {
    updateGroup(state, action) {
      const groupId = action.payload.id;
      state.groups = { ...state.groups, [groupId]: action.payload };
    },

    updateToUpload(state, action) {
      const groupId = action.payload.id;
      const filesToUpload = "filesToUpload";

      // @ts-ignore
      state.groups[groupId] = {
        // @ts-ignore
        ...state.groups[groupId],

        [filesToUpload]: action.payload.filesToUpload,
      };
    },

    fileToDeleteBackEnd(state, action) {
      const groupId = action.payload.group;
      // @ts-ignore
      state.groups[groupId].filesBackend = action.payload.files;
    },

    fileToDelete(state, action) {
      const groupId = action.payload.group;
      // @ts-ignore
      state.groups[groupId].filesToUpload = action.payload.files;
    },
  },
});

export const {
  updateGroup,
  fileToDelete,
  updateToUpload,
  fileToDeleteBackEnd,
} = group.actions;

export default group.reducer;
