import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { theme } from "../assets/css/theme";
import BoxMilia from "../components/Atoms/BoxMilia";
import SigningTask from "../components/Molecules/StartTask/SigningTask";
import TaskNotFound from "../components/Molecules/StartTask/TaskNotFound";
import AdhocTask from "../components/Organisms/AdhocTask";
import UploadTask from "../components/Organisms/UploadTask";
import useClientTaskService from "../services/fetch/useClientTaskService";
import { saveTask } from "../store/slices/task";

const StartTask = () => {
  const { taskId } = useParams();
  const dispatch = useDispatch();
  const { readClientTask, clientTask } = useClientTaskService();
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchTask = async () => {
      try {
        const readClientTasksData = await readClientTask({
          taskId: parseInt(taskId!, 10),
        });

        dispatch(saveTask(readClientTasksData));
      } catch (e) {
        setError(true);
      }
    };
    fetchTask();
  }, [taskId]);

  const highLightContainer = () => {
    const container = document.getElementById("task-container") as HTMLElement;
    container.style.border = `2px solid ${theme.palette.secondary.main}`;
  };

  return (
    <Container disableGutters sx={{ padding: "0 16px" }}>
      <BoxMilia id="task-container" p={3} onFocus={highLightContainer}>
        {error && <TaskNotFound />}
        {clientTask?.type === "SIGNING_TASK" && (
          <SigningTask taskData={clientTask} />
        )}
        {clientTask?.type === "ADHOC_TASK" && (
          <AdhocTask taskData={clientTask} />
        )}
        {(clientTask?.type === "UPLOAD_TASK" || clientTask?.id === 1) && (
          <UploadTask taskData={clientTask} />
        )}
      </BoxMilia>
    </Container>
  );
};

export default StartTask;
