import { FormControlLabel as MuiFormControlLabel } from "@mui/material";
import { FormControlLabelProps as MuiFormControlLabelProps } from "@mui/material/FormControlLabel/FormControlLabel";
import MuiSwitch from "@mui/material/Switch";
import { SwitchProps as MuiSwitchProps } from "@mui/material/Switch/Switch";

export interface IFormControlLabelProps
  extends Omit<MuiFormControlLabelProps, "control"> {
  control?: any;
}

export interface SwitchProps extends MuiSwitchProps {
  error?: boolean;
}

const StyledMuiSwitch = (props: SwitchProps) => (
  <MuiSwitch
    disableRipple={true}
    sx={{
      padding: "12px 14px",
      "> .MuiButtonBase-root:hover": {
        background: "transparent !important",
      },
      ".MuiSwitch-track": {
        borderRadius: "10px",
      },
      thumb: {
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
        background: "#F5F5F5",
      },
      ".MuiSwitch-switchBase": {
        paddingRight: "11px !important",
      },
      track: () => ({
        opacity: "0.5",
        background: "#3D414D",
        "&:before, &:after": {
          top: "42%",
          height: 6,
          content: '""',
          position: "absolute",
          backgroundRepeat: "no-repeat",
        },
        "&:after": {
          width: 6,
          right: "32%",
          backgroundImage: `url('data:image/svg+xml;utf8,<svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="3" cy="3" r="2.5" stroke="white"/></svg>')`,
        },
        "&:before": {
          width: 1,
          left: "37%",
          backgroundImage: `url('data:image/svg+xml;utf8,<svg width="1" height="6" viewBox="0 0 1 6" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="0.5" x2="0.5" y2="6" stroke="black"/></svg>')`,
        },
        ".Mui-checked.Mui-checked + &": {
          backgroundColor: "#B7F235",
        },
        ...(props.disabled && {
          ".Mui-checked.Mui-checked + &": {
            backgroundColor: "#3D414D",
          },
        }),
      }),
    }}
    {...props}
  />
);

const Toggle = (props: SwitchProps & IFormControlLabelProps) => {
  return props.label ? (
    <MuiFormControlLabel
      sx={{
        "> .MuiTypography-root": {
          fontSize: "10px",
          color: "#3D414D",
          lineHeight: "14.3px",
          letterSpacing: "0.17px",
        },
      }}
      {...props}
      control={<StyledMuiSwitch {...props} />}
    />
  ) : (
    <StyledMuiSwitch {...props} />
  );
};

export default Toggle;
