import { useEffect, useRef } from "react";
import useReadDocumentPath from "../../../services/fetch/useReadDocumentPath";
import { IDocument } from "../../../types";

interface IProps {
  document: IDocument;
}

const DocumentViewer = ({ document }: IProps) => {
  const iframeRef = useRef<any>();
  const [readDocumentPath] = useReadDocumentPath();

  useEffect(() => {
    const downloadDoc = async () => {
      const downloadPath = document.downloadPath;
      const downloadUrl = await readDocumentPath({ downloadPath });
      iframeRef.current.src = downloadUrl;
    };

    downloadDoc();
  }, []);

  return (
    <>
      {/* <iframe src={``} width={"100%"} height="600px"></iframe> */}
      {/* <img ref={iframeRef} alt="" width={"100%"} /> */}
      <iframe
        title="document"
        ref={iframeRef}
        style={{ width: "100%", height: "400px", border: "none" }}
      ></iframe>
    </>
  );
};

export default DocumentViewer;
