import { styled } from "@mui/material";
import React from "react";

interface IDocChip {
  bgcolor: string;
  color: string;
  label?: string;
  width: number;
}

const DocChip = styled("div")<IDocChip>(({ ...props }) => {
  return {
    backgroundColor: props.bgcolor,
    color: props.color,
    maxWidth: `${props.width}px`,
    width: "100%",
    borderRadius: "16px",
    padding: "4px",
    margin: "0 auto",
    textTransform: "uppercase",
    textAlign: "center",
  };
});

export const DocumentTableChip = (props: IDocChip) => {
  const { bgcolor, color, width, label } = props;
  return (
    <DocChip bgcolor={bgcolor} color={color} width={width}>
      {label}
    </DocChip>
  );
};
