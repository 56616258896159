import { styled } from "@mui/system";

const LoadingWrapper = styled("div")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  height: "100%",
  width: "100%",
  backgroundColor: "rgba(61, 65, 77, 0.6)",
  zIndex: "2000",
  "& svg": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    height: "50vh",
    maxWidth: "200px",
    maxHeight: "200px",
  },
}));

const LoadingAnimation = () => {
  return (
    <LoadingWrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <g transform="translate(0 -7.5)">
          <circle cx="50" cy="41" r="10" fill="#B7F235">
            <animateTransform
              attributeName="transform"
              type="rotate"
              dur="1s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 50 50;360 50 50"
            ></animateTransform>
            <animate
              attributeName="r"
              dur="1s"
              repeatCount="indefinite"
              calcMode="spline"
              keyTimes="0;0.5;1"
              values="0;15;0"
              keySplines="0.2 0 0.8 1;0.2 0 0.8 1"
            ></animate>
          </circle>
          <circle cx="50" cy="41" r="10" fill="#1129f5">
            <animateTransform
              attributeName="transform"
              type="rotate"
              dur="1s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="180 50 50;540 50 50"
            ></animateTransform>
            <animate
              attributeName="r"
              dur="1s"
              repeatCount="indefinite"
              calcMode="spline"
              keyTimes="0;0.5;1"
              values="15;0;15"
              keySplines="0.2 0 0.8 1;0.2 0 0.8 1"
            ></animate>
          </circle>
        </g>
      </svg>
    </LoadingWrapper>
  );
};

export default LoadingAnimation;
