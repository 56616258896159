import { updateStep } from "../../../../store/slices/onboarding";
import { useDispatch } from "react-redux";
import {
	Grid as MuiGrid,
	Button as MuiButton,
	Typography as MuiTypography, useTheme, useMediaQuery,
} from "@mui/material";
import Daumen from "../../../../assets/images/daumen.png";

import Container from "../Container";
import ImageContainer from "../ImageContainer";

const Step6 = () => {
	const dispatch = useDispatch();

	const goToStep7 = () => {
		dispatch(updateStep(7));
	};

	const theme = useTheme();
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
	const width = isSmallDevice ? '327px' : '440px';

	return (
		<Container withBackground>
			<ImageContainer src={Daumen} />
			<MuiGrid
				item
				alignItems="center"
				justifyContent="center"
			>
				<MuiTypography align="center" variant="h6">
					Herzlich Glückwunsch!
				</MuiTypography>
				<MuiTypography style={{maxWidth: '327px'}} align="center" paragraph variant="body2">
					Sie haben erfolgreich Ihre persönlichen Daten eingegeben. Jetzt benötigen wir Informationen über Ihr Einzelunternehmen.
				</MuiTypography>
			</MuiGrid>
			<MuiGrid style={{paddingBottom: '80px'}}>
				<MuiButton
					sx={{ width }}
					color="primary"
					onClick={goToStep7}
				>
					Los gehts
				</MuiButton>
			</MuiGrid>
		</Container>
	);
}

export default Step6;
