import {
  Button as MuiButton,
  Grid as MuiGrid,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Company from "../../../../assets/images/company.png";
import {
  updateNameType,
  updateStep,
} from "../../../../store/slices/onboarding";
import { IStore } from "../../../../store/types";
import Container from "../Container";
import ImageContainer from "../ImageContainer";
import { arrayButton } from "./const";

const Step6 = () => {
  const dispatch = useDispatch();
  const nameType = useSelector((state: IStore) => state.onboarding.nameType);

  const goToStep7 = () => {
    dispatch(updateStep(7));
  };

  const onSelect = (e: any) => {
    dispatch(updateNameType(e));
  };

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const width = isSmallDevice ? "327px" : "440px";

  return (
    <Container activeStep={5}>
      <ImageContainer
        src={Company}
        marginTopMd="86px"
        marginTopSm="122px"
        marginBottomMd="6px"
        marginBottomSm="6px"
      />
      <MuiGrid
        item
        alignItems="center"
        justifyContent="center"
        paddingLeft="24px"
        paddingRight="24px"
      >
        <MuiTypography
          align="center"
          variant="body1"
          color="rgba(61, 65, 77, 0.5)"
        >
          Unternehmensname default
        </MuiTypography>
        <MuiTypography sx={{ marginTop: "18px" }} align="center" variant="h6">
          Bitte legen Sie eine Person an.
        </MuiTypography>
      </MuiGrid>
      {arrayButton.map((item, index: number) => {
        const isActive = nameType === index + 1;
        return (
          <MuiGrid key={index} sx={{ marginTop: "16px" }} item>
            <MuiButton
              value={nameType}
              onClick={() => onSelect(index + 1)}
              sx={{
                width: width,
                ...(isActive && {
                  backgroundColor: "#B7F235 !important",
                }),
              }}
            >
              {item.title}
            </MuiButton>
          </MuiGrid>
        );
      })}
      <MuiGrid
        sx={{ marginTop: "40px" }}
        paddingBottom={isSmallDevice ? "0px" : "122px"}
        item
      >
        <MuiButton
          disabled={!nameType}
          onClick={goToStep7}
          color="primary"
          sx={{ width }}
        >
          weiter
        </MuiButton>
      </MuiGrid>
    </Container>
  );
};

export default Step6;
