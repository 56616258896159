import { Button, CssBaseline, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useClientTaskService from "../../../services/fetch/useClientTaskService";
import { saveTask } from "../../../store/slices/task";
import { ITask } from "../../../types";
import TaskInfo from "../TaskOverview/TaskInfo";
import TaskInfoHeader from "../TaskOverview/TaskInfoHeader";

interface IProps {
  taskData: ITask;
}

const SigningTask = ({ taskData }: IProps) => {
  const {
    updateClientTask,
    readClientTask,
    readClientTaskSignUrl,
    clientTaskSignUrl,
  } = useClientTaskService();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [docusignLoaded, setDocusignLoaded] = useState(0);

  const isTaskActive = taskData.status === "ACTIVE";
  const isTaskFinished = taskData.status === "FINISHED";

  useEffect(() => {
    const fetchSign = async () => {
      await readClientTaskSignUrl({
        taskId: taskData.id,
      });
    };

    if (isTaskActive) {
      fetchSign();
    }
  }, []);

  const updateTaskStatusToFinished = () => {
    updateClientTask({
      taskId: taskData.id,
      data: {
        status: "FINISHED",
      },
    }).then(() => {
      const readClientTasksData = readClientTask({
        taskId: taskData.id,
      });
      dispatch(saveTask(readClientTasksData));
    });
  };

  const handleIframeRedirect = () => {
    if (docusignLoaded === 1) {
      updateTaskStatusToFinished();
    }

    setDocusignLoaded(docusignLoaded + 1);
  };

  return (
    <>
      <CssBaseline />
      <Grid container>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Stack direction={"column"} gap="24px">
            <TaskInfoHeader data={taskData} />
            <TaskInfo
              data={
                isTaskFinished
                  ? { ...taskData, description: "Client signed." }
                  : taskData
              }
            />
            {isTaskActive ? (
              clientTaskSignUrl.url !== null ? (
                <iframe
                  src={clientTaskSignUrl.url}
                  width="100%"
                  title="docusign"
                  data-testid="docusign"
                  style={{
                    height: "100vh",
                  }}
                  onLoad={handleIframeRedirect}
                />
              ) : (
                <iframe
                  width="100%"
                  title="docusign"
                  data-testid="docusign"
                  height="auto"
                />
              )
            ) : null}
            <Stack style={{ justifyContent: "flex-end" }} direction={"row"} gap={1.5}>
              <Button onClick={() => navigate("/")}>Schließen</Button>
              <Button
                color="primary"
                sx={{ boxShadow: "0px 4px 8px -2px rgba(42, 51, 60, 0.15), 0px 6px 16px -2px rgba(42, 51, 60, 0.06)" }}
              >Test</Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default SigningTask;
