import { updateStep } from "../../../../store/slices/onboarding";
import { useDispatch } from "react-redux";
import {
  Grid as MuiGrid,
  Button as MuiButton,
  Typography as MuiTypography, useTheme, useMediaQuery,
} from "@mui/material";
import Daumen from "../../../../assets/images/daumen.png";

import Container from "../Container";
import ImageContainer from "../ImageContainer";

const WelcomeScreen = () => {
  const dispatch = useDispatch();

  const goToStep1 = () => {
    dispatch(updateStep(1));
  };

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const width = isSmallDevice ? '327px' : '440px';

  return (
    <Container withBackground>
      <ImageContainer src={Daumen} />
      <MuiGrid
        item
        alignItems="center"
        justifyContent="center"
      >
        <MuiTypography align="center" variant="h6">
          Hi, ich bin milia!
        </MuiTypography>
        <MuiTypography align="center" paragraph variant="body2">
          Sie können nun mit dem Onboarding in <br/>
          Ihrer Kanzlei der Zukunft starten. <br/>
          Folgen Sie einfach den Schritten, <br/>
          die ich Ihnen aufzeige.
        </MuiTypography>
      </MuiGrid>
      <MuiGrid style={{paddingBottom: '80px'}}>
        <MuiButton
          sx={{ width }}
          color="primary"
          onClick={goToStep1}
        >
          Los gehts
        </MuiButton>
      </MuiGrid>
    </Container>
  );
}

export default WelcomeScreen;
