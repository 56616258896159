import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import { Container, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { DocumentFilter } from "../components/Molecules/DocumentOverview/DocumentFilter";
import { DocumentTable } from "../components/Molecules/DocumentOverview/DocumentTable";
import WidgetWrapper from "../components/Molecules/WidgetWrapper";
import useClientDocumentService from "../services/fetch/useClientDocumentService";
import { IDocument } from "../types";

const DocumentOverview = () => {
  const [filter, setFilter] = useState({
    size: 20,
    page: 0,
    days: 10,
    category: "",
    type: "",
    sort: "creationDate,desc",
  });
  const { readClientDocuments, clientDocuments } = useClientDocumentService();
  const [tableData, setTableData] = useState<Array<IDocument>>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    readClientDocuments({ filter }).finally(() => {
      setIsLoading(false);
    });
  }, [filter]);

  useEffect(() => {
    if (clientDocuments?.items) {
      if (filter.page === 0 || !tableData?.length) {
        setTableData(clientDocuments.items);
      } else {
        setTableData((values: any) => [...values, ...clientDocuments.items]);
      }
    } else {
      setTableData([]);
    }
  }, [clientDocuments]);

  const ScrollingElement = () => {
    // eslint-disable-next-line
    const [, setScrollY] = useState(0);

    const html = document.querySelector("html");
    function handleScroll() {
      setScrollY(window.pageYOffset);

      const cH = html?.clientHeight ? html?.clientHeight : 0;
      const sH = html?.scrollHeight ? html?.scrollHeight : 0;
      const sT = html?.scrollTop ? html?.scrollTop : 0;
      if (cH + sT >= sH) {
        loadNextPage();
      }
    }

    useEffect(() => {
      const watchScroll = () => {
        window.addEventListener("scroll", handleScroll);
      };
      watchScroll();
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    });
  };
  console.log(isLoading);
  const loadNextPage = () => {
    if (
      clientDocuments.currentPage !== undefined &&
      clientDocuments.currentPage + 1 !== clientDocuments.totalPages
    ) {
      setIsLoading(true);
      setFilter((value) => {
        return { ...value, page: clientDocuments.currentPage + 1 };
      });
    }
  };

  ScrollingElement();

  return (
    <Container>
      <WidgetWrapper
        title={{
          caption: "Dokumente",
          // onClick: () => navigate("/documentoverview"),
        }}
        icon={<InsertDriveFileRoundedIcon color="primary" />}
        highlightOnFocus={true}
        add={{ display: true, disabled: true }}
      >
        <Stack direction="column" height="100%">
          <DocumentFilter filter={filter} setFilter={setFilter} />
          <DocumentTable data={tableData} />
        </Stack>
      </WidgetWrapper>
    </Container>
  );
};

export default DocumentOverview;
