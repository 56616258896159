import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ITask } from "../../../types";
import { DateDisplay } from "../../Atoms/DateDisplay";
import TaskAssign from "./TaskAssign";
import TaskInfo from "./TaskInfo";
import TaskInfoHeader from "./TaskInfoHeader";

interface IProps {
  taskData: ITask;
  handleClose: any;
}

const TaskModal = (props: IProps) => {
  const navigate = useNavigate();

  return (
    <Stack direction={"column"} spacing={3} padding={3}>
      <TaskInfoHeader data={props.taskData} />
      <TaskInfo data={props.taskData} />

      <DateDisplay
        displayValue={new Date(props.taskData.creationDate)}
        label="Erstellungsdatum"
      ></DateDisplay>

      <TaskAssign />

      <Stack style={{ justifyContent: "flex-end" }} direction={"row"} spacing={1.3}>
        <Button onClick={props.handleClose}>Schließen</Button>
        <Button
          color="primary"
          sx={{ boxShadow: "0px 4px 8px -2px rgba(42, 51, 60, 0.15), 0px 6px 16px -2px rgba(42, 51, 60, 0.06)" }}
          onClick={() => navigate(`/task/${props.taskData.id}`)}
        >
          Starten
        </Button>
      </Stack>
    </Stack>
  );
};

export default TaskModal;
