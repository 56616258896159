// TODO: Move to consst

export const arrayButton = [
  {
    title: "Gesellschafter",
  },
  {
    title: "Gesetzlicher Vertreter",
  },
  {
    title: "Wirtschaftlich Berechtigter",
  },
];
