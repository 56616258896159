import { AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import { IStore } from "../../store/types";
import { IClient } from "../../types";
import { useFetchData } from "../fetchData";

const useUpdateClient = () => {
  const currentClient: IClient = useSelector(
    (state: IStore) => state.currentClient
  );
  const { fetchData } = useFetchData();
  const updateClient = async ({
    data,
  }: any): Promise<AxiosResponse<any, any>> => {
    const res = await fetchData(`clients/${currentClient.id}`, {
      method: "PUT",
      data: JSON.stringify(data),
    });
    return res;
  };

  return [updateClient];
};

export default useUpdateClient;
