import { createSlice } from "@reduxjs/toolkit";
import { IClient } from "../../types";

const initialState: IClient | {} = {};

const currentClient = createSlice({
  name: "currentClient",
  initialState,
  reducers: {
    updateCurrentClient(state, action) {
      return action.payload;
    },
  },
});

export const { updateCurrentClient } = currentClient.actions;

export default currentClient.reducer;
