import {
  Button as MuiButton,
  Grid as MuiGrid,
  Link as MuiLink,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import Daumen from "../../../../assets/images/daumen.png";
import {
  updateIsOnboard,
  updateStep,
} from "../../../../store/slices/onboarding";
import Container from "../Container";
import ImageContainer from "../ImageContainer";

const Step8 = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const width = isSmallDevice ? "327px" : "440px";

  const goToStep9 = () => {
    dispatch(updateStep(9));
  };

  const completeOnboarding = () => {
    dispatch(updateIsOnboard(false));
  };

  return (
    <Container withBackground>
      <ImageContainer src={Daumen} />
      <MuiGrid item alignItems="center" justifyContent="center">
        <MuiTypography sx={{ maxWidth: "388px" }} align="center" variant="h6">
          Ihre Stammdatenerfassung ist fertig!
        </MuiTypography>
        <MuiTypography
          sx={{ maxWidth: "388px", marginTop: "24px" }}
          align="center"
          paragraph
          variant="h6"
        >
          Wilkommen zu Ihrem Dashboard und <br />
          genießen Sie die neue Art der <br />
          Steuerberatung.
        </MuiTypography>
      </MuiGrid>
      <MuiGrid>
        <MuiButton
          sx={{ width, marginTop: "64px" }}
          color="primary"
          onClick={completeOnboarding}
        >
          fertig
        </MuiButton>
      </MuiGrid>
      <MuiGrid sx={{ marginTop: "8px", paddingBottom: "32px" }}>
        <MuiLink
          sx={{ cursor: "pointer" }}
          align="center"
          onClick={goToStep9}
          variant="body1"
          underline="none"
        >
          Ich habe noch Fragen.
        </MuiLink>
      </MuiGrid>
    </Container>
  );
};

export default Step8;
