import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {};

const task = createSlice({
  name: "task",
  initialState,
  reducers: {
    saveTask(state, action) {
      state.task = action.payload;
    },
  },
});

export const { saveTask } = task.actions;

export default task.reducer;
