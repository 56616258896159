import { Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Chip from "./Chip";

interface IProps {
  onChange: Function;
  data: Array<string>;
  translation?: any;
}

const ChipSlider = (props: IProps) => {
  const [active, setActive] = useState("");
  const boxSlider: any = useRef();
  useEffect(() => {
    const boxSliderRefCopy = boxSlider.current;
    let mouseDown = false;
    let startX: number;
    let scrollLeft: number;

    const startDragging = (e: any) => {
      mouseDown = true;
      startX = e.pageX - boxSlider.current.offsetLeft;
      scrollLeft = boxSlider.current.scrollLeft;
    };
    const stopDragging = () => {
      mouseDown = false;
    };
    const sliderMove = (e: any) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - boxSlider.current.offsetLeft;
      const scroll = x - startX;
      boxSlider.current.scrollLeft = scrollLeft - scroll;
    };
    if (boxSlider.current) {
      boxSlider.current.addEventListener("mousemove", sliderMove);
      boxSlider.current.addEventListener("mousedown", startDragging, false);
      boxSlider.current.addEventListener("mouseup", stopDragging, false);
      boxSlider.current.addEventListener("mouseleave", stopDragging, false);
    }

    return () => {
      boxSliderRefCopy.removeEventListener("mousemove", sliderMove);
      boxSliderRefCopy.removeEventListener("mousedown", startDragging, false);
      boxSliderRefCopy.removeEventListener("mouseup", stopDragging, false);
      boxSliderRefCopy.removeEventListener("mouseleave", stopDragging, false);
    };
  }, []);

  const handleClick = (e: any) => {
    props.onChange(e);
    active === e.target.outerText
      ? setActive("")
      : setActive(e.target.outerText);
  };

  return (
    <Stack
      id="boxSlider"
      ref={boxSlider}
      direction="row"
      gap="4px"
      sx={{
        overflowX: "scroll",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      {props.data?.map((item: any) => {
        return (
          <Chip
            key={item}
            bgcolor={
              active ===
              (props.translation
                ? props.translation[item]
                  ? props.translation[item].toUpperCase()
                  : item.toUpperCase()
                : item.toUpperCase())
                ? "#B7F235"
                : "#EAEAEA"
            }
            color="white"
            padding="3.5px 10px"
            label={
              props.translation.hasOwnProperty(item)
                ? props.translation[item]
                : item
            }
            onClick={(e: any) => handleClick(e)}
          ></Chip>
        );
      })}
    </Stack>
  );
};

export default ChipSlider;
