import { styled } from "@mui/material";

interface IChip {
  color: string;
  bgcolor: string;
  padding: string;
  height?: string;
  label?: string;
  onClick?: any;
}

const StyledChip = styled("div")<IChip>(({ theme, ...props }) => {
  const cursor = props.onClick ? "pointer" : "default";
  return {
    color: props.color,
    backgroundColor: props.bgcolor,
    borderRadius: "64px",
    padding: props.padding,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "12px",
    maxWidth: "100%",
    width: "fit-content",
    textOverflow: "clip",
    height: props.height || "24px",
    fontFamily: theme.typography.fontFamily,
    whiteSpace: "nowrap",
    cursor: `${cursor}`,
  };
});

const Chip = ({ color, bgcolor, padding, label, onClick, height }: IChip) => {
  return (
    <StyledChip
      color={color}
      bgcolor={bgcolor}
      height={height}
      padding={padding}
      onClick={onClick}
    >
      {label}
    </StyledChip>
  );
};

export default Chip;
