import { Stack, Container, useTheme, useMediaQuery} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NavigationMenu } from "./NavigationMenu";
import ProfileMenu from "./ProfileMenu";

const TopNavbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  return (
    <Container disableGutters={true} sx={{ padding: isMobile ? "12px 16px 0px 16px" : "12px 16px" }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <img src="/img/logo-milia-sm.svg" alt="logo-milia" style={{ cursor: "pointer" }} onClick={() => navigate("/")} />
        <Stack direction="row" spacing="10px">
          <NavigationMenu />
          <ProfileMenu />
        </Stack>
      </Stack>
    </Container>
  );
};

export default TopNavbar;
