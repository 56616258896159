import DeleteIcon from "@mui/icons-material/Delete";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useState } from "react";
import useClientDocumentService from "../../../services/fetch/useClientDocumentService";

interface IProps {
  id: number;
  name: string;
  setSelectedId?: any;
}

export const DocumentMenu = (props: IProps) => {
  const { readClientDocumentDownload } = useClientDocumentService();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (props.setSelectedId) props.setSelectedId(props.id);
  };
  const handleClose = () => {
    setAnchorEl(null);
    if (props.setSelectedId) props.setSelectedId(-1);
  };
  const toggleDialog = () => {
    handleClose();
    setOpenDialog(!openDialog);
  };

  const handleDownload = async () => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = props.name;
    link.href = (await readClientDocumentDownload({
      documentId: props.id,
    })) as string;
    link.click();
    toggleDialog();
  };

  return (
    <>
      <Stack
        alignItems="center"
        justifyContent="flex-end"
        onClick={handleClick}
        sx={{ cursor: "pointer" }}
      >
        <MoreHorizIcon color="primary" />
      </Stack>
      <Menu
        id="doc-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuItem onClick={handleDownload}>
          <Stack direction="row" gap="14px" alignItems="center">
            <DownloadForOfflineIcon
              color="primary"
              sx={{ width: "24px", height: "24px" }}
            />
            <Typography variant="body1">Herunterladen</Typography>
          </Stack>
        </MenuItem>
        <MenuItem>
          <Stack direction="row" gap="14px" alignItems="center">
            <DeleteIcon
              color="primary"
              sx={{ width: "24px", height: "24px" }}
            />
            <Typography variant="body1">Löschen</Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </>
  );
};
