import { Container as MuiContainer, Grid as MuiGrid } from "@mui/material";
import { useSelector } from "react-redux";
import BaseDataCompanyRole from "../components/Molecules/ClientProfile/BaseDataCompanyRole";
import StammdatenList from "../components/Molecules/ClientProfile/StammdatenList";
import Steuerdaten from "../components/Molecules/ClientProfile/Steuerdaten";
import WidgetWrapper from "../components/Molecules/WidgetWrapper";
import { IStore } from "../store/types";
import { EClientType } from "../types";
import { avatarTilman } from "../utils";

const ClientProfile = () => {
  const currentClient = useSelector((state: IStore) => state.currentClient);

  return (
    <MuiContainer disableGutters={true} sx={{ padding: "0 16px" }}>
      <MuiGrid container>
        <WidgetWrapper
          title={{ caption: `${currentClient?.name}` }}
          subTitle={`${(EClientType as any)[currentClient?.type]}`}
          img={{ src: avatarTilman }}
        />

        <WidgetWrapper
          title={{ caption: "Stammdaten" }}
          img={{ src: "/img/stammdaten.svg" }}
          highlightOnFocus={true}
        >
          <StammdatenList />
        </WidgetWrapper>
        <WidgetWrapper
          title={{ caption: "Steuer- & Firmendaten" }}
          img={{ src: "/img/apartment.svg" }}
          highlightOnFocus={true}
        >
          <Steuerdaten />
        </WidgetWrapper>

        <WidgetWrapper
          title={{ caption: "Rollen im Unternehmen" }}
          img={{ src: "/img/diversity.svg" }}
          highlightOnFocus={true}
        >
          <BaseDataCompanyRole />
        </WidgetWrapper>
      </MuiGrid>
    </MuiContainer>
  );
};

export default ClientProfile;
