const TypeTranslation: any = {
  AUDIT: "Betriebsprüfung",
  BUSINESS_CONSULTING: "Wirtschaftsberatung",
  LEGAL_ADVICE: "Rechtsberatung",
  PROFESSIONAL_ASSOCIATION: "Berufsgenossenschaft",
  CONTRACTS: "Verträge",
  EVALUATION: "Auswertungen",
  RECEIPTS: "Belege",
  NOTICES: "Bescheide",
  OTHER: "Sonstiges",
  LEGITIMATION_DOCUMENTS: "Legitimationsunterlagen",
  WORKING_PAPERS: "Arbeitspapiere",
  EMPLOYEES: "Arbeitnehmer",
  SPECIFICS: "Besonderheiten",
  EXPLANATIONS: "Erklärungen",
  AUTHORISATIONS: "Vollmachten",
  TAX_CONSULTING: "Steuerberatung",
  CORRESPONDENCE: "Korrespondenz",
  COMPANY_DATA: "Unternehmensdaten",
  MANDATE_RELATIONSHIP: "Mandatsverhältnis",
  INSURANCES: "Versicherungen",
  VERIFICATION: "Prüfungen",
  UNSORTED_DOCUMENTS: "Unsortierte Dokumente",
  NON_SELFEMPLOYED_WORK: "Nicht selbstständige Tätigkeit",
  EXCEPTIONAL_COSTS: "Außergewöhnliche Kosten",
  COMMERCIAL_ENTERPRISE_OR_SELF_EMPLOYMENT: "Gewerbliches Unternehmen oder selbständige Tätigkeit",
  CRAFTSMAN_AND_HOUSEHOLD_RELATED_SERVICES: "Handwerkliche und Haushaltsbezogene Dienstleistungen",
  CAPITAL_INVESTMENTS: "Kapital Investitionen",
  CHILDREN: "Kinder",
  PENSIONS: "Renten",
  SPECIAL_EXPENSES_OR_DONATIONS: "Sonderausgaben oder Spenden",
  RENTAL_INCOME: "Mieteinnahmen",
  INCOMING_INVOICES: "Eingangsrechnungen",
  OUTGOING_INVOICES: "Ausgangsrechnungen",
  CASH_REGISTER: "Kasse",
  CREDIT_CARD: "Kreditkarte",
  GENERIC_SIGNATURE: "Generische Signatur",
  ENCLOSURE_TASK: "Anlage Task",
  ENCLOSURE_EMAIL: "E-Mail Anhang",
  EMAIL: "E-Mail",
  SCAN: "Scan",
};

export default TypeTranslation;
