import {Box, Modal, Paper, Typography as MuiTypography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ImageContainer from "../ImageContainer";
import HelperImage from "../../../../assets/images/helper1.png";
import React from "react";

export interface Props {
	open?: boolean,
	setOpen?: Function,
}

const WhatVAT = ({
									 open = false, setOpen = () => {
	}
								 }: Props) => {
	return <Modal
		open={open}
		onClose={() => setOpen(false)}
		aria-labelledby="parent-modal-title"
		aria-describedby="parent-modal-description"
		style={styles.containerModal}
	>
		<Paper
			style={{
				width: '327px',
				backgroundColor: 'white',
				borderRadius: '20px',
				padding: '24px',
				overflow: 'auto',
			}}>
			<Box style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-between'}}>
				<MuiTypography id="modal-modal-title" variant="h6"
											 style={{fontSize: '18px'}}>
					Wie lautet Ihre USt-Id Nr.?
				</MuiTypography>
				<CloseIcon color="primary" onClick={() => setOpen(false)}/>
			</Box>
			<Box>
				<ImageContainer
					src={HelperImage}
					marginTopMd="20px"
					marginTopSm="20px"
				/>
				<MuiTypography>
					Unternehmen. die Lieferungen und Leistungen innerhalb des Europäischen Binnenmarktes
					erbringen oder erhalten, benötigen für die Abwicklung eine
					Umsatzsteuer-Identifikationsnummer (USt-IdNr).

					Sie haben noch keine USt-IdNr?
					Fragen Sie bei Ihrem zuständigen Finanzamt nach, beantragen Sie die USt-IdNr bei der
					Unternehmensgründung oder geben Sie direkt hier an, dass sie noch keine USt-IdNr haben.
					Wir kümmern uns anschließend darum.

				</MuiTypography>
			</Box>
		</Paper>
	</Modal>
};

const styles = {
	containerModal: {
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		flex: 1,
	},
}

export default WhatVAT
