import { useState } from "react";
import { Controller } from "react-hook-form";
import CustomDatePicker from "./CustomDatePicker";

interface IDateDisplay {
  label?: string;
  helperText?: string;
  withInput?: boolean;
  disabled?: boolean;
  displayValue?: Date;
  valueSetter?: any;
  name?: string;
  control?: any;
  rules?: any;
  minDate?: Date;
  error?: boolean;
  width?: string;
  placeholder?: string;
}

export const DateDisplay = (props: IDateDisplay) => {
  const {
    label,
    helperText,
    withInput = true,
    disabled,
    displayValue,
    name,
    control,
    rules,
    minDate,
    valueSetter,
    error,
    placeholder,
  } = props;

  const [dateValue, setDateValue] = useState<Date | null>(
    displayValue ? displayValue : null
  );

  const handleDateChange = (newValue: any) => {
    setDateValue(newValue);
    if (valueSetter) valueSetter(newValue);
  };

  if (name && control) {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value } }) => {
          return (
            <CustomDatePicker
              width="116px"
              height="36px"
              onChange={onChange}
              value={value}
              label={label}
              helperText={helperText}
              withInput={withInput}
              minDate={minDate}
              error={error}
              placeholder={placeholder}
            />
          );
        }}
      />
    );
  }

  return (
    <CustomDatePicker
      width="116px"
      height="36px"
      value={dateValue}
      onChange={handleDateChange}
      disabled={disabled}
      label={label}
      error={error}
      helperText={helperText}
      withInput={withInput}
      placeholder={placeholder}
    />
  );
};

export default DateDisplay;
