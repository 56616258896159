import AddCommentRoundedIcon from "@mui/icons-material/AddCommentRounded";
import {
  Box,
  Button,
  Dialog,
  Stack,
  TextField,
  Typography,
	useTheme,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { IChatMessage } from "../../../ressources/interfaces";
import { useFetchData } from "../../../services/fetchData";
import { IStore } from "../../../store/types";
import { IClient } from "../../../types";
import Chat from "../../Atoms/Chat";
import { DateDisplay } from "../../Atoms/DateDisplay";
import { FileUpload } from "../../Atoms/Upload";
import { avatarTilman } from "../../../utils";

interface IProps {
  toggleDialog: any;
  onTaskCreatedCompleted?: () => void;
}

const AdhocTask = (props: IProps) => {
  const currentClient: IClient = useSelector(
    (state: IStore) => state.currentClient
  );
  const { toggleDialog } = props;
  const { fetchData, postDocument } = useFetchData();
  const { enqueueSnackbar } = useSnackbar();
  const [chatOpen, setChatOpen] = useState<boolean>(false);
  const [chat, setChat] = useState<Array<IChatMessage>>([]);
  const [documentToupload, setDocumentToupload] = useState<
    Array<{ file: File; groupId: number }>
  >([]);

  const toggleChat = () => {
    setChatOpen(!chatOpen);
  };
  const defaultValues = {
    headline: "",
    description: "",
    creationDate: new Date(),
    deadlineDate: null,
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });

	const theme = useTheme();

  const createAdhocTask = (data: any) => {
    const adhocCreationDate = moment(data.creationDate).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const adhocDeadlineDate = data.deadlineDate
      ? moment(data.deadlineDate).format("YYYY-MM-DD HH:mm:ss")
      : null;
    const adhocStatus = documentToupload.length > 0 ? "DRAFT" : "ACTIVE";
    const adhocData = {
      name: data.headline,
      description: data.description,
      type: "ADHOC_TASK",
      creationDate: adhocCreationDate,
      deadlineDate: adhocDeadlineDate,
      messages: chat,
      status: adhocStatus,
      payload: {},
    };
    const url = `clients/${currentClient.id}/tasks`;
    const options = { method: "POST", data: JSON.stringify(adhocData) };

    if (adhocStatus === "ACTIVE") {
      fetchData(url, options).finally(() => {
        enqueueSnackbar("Adhoc task erstellt", { variant: "success" });
        toggleDialog();
        props.onTaskCreatedCompleted && props.onTaskCreatedCompleted();
      });
    } else {
      fetchData(url, options)
        .then((data) => {
          const url = `clients/${currentClient.id}/tasks/${data.data.id}/groups/${data.data.groups[0].id}/documents`;
          const promises = documentToupload.map(
            (item: { file: File; groupId: number }) => {
              const formData = new FormData();
              formData.append("file", item.file);
              return postDocument(url, formData);
            }
          );

          Promise.all(promises)
            .then(() => {
              fetchData(`clients/${currentClient.id}/tasks/${data.data.id}`, {
                method: "PATCH",
                data: { status: "ACTIVE" },
              });
              enqueueSnackbar("Adhoc Task erstellt", {
                variant: "success",
              });
              props.onTaskCreatedCompleted && props.onTaskCreatedCompleted();
            })
            .catch(() =>
              enqueueSnackbar(
                "Es gab einen Fehler bitte kontrollieren Sie den Adhoctask unter Meine Aufgaben -> Drafts",
                {
                  variant: "error",
                }
              )
            );
        })
        .catch(() =>
          enqueueSnackbar(
            "Es gab einen Fehler bitte kontrollieren Sie den Adhoctask unter Meine Aufgaben -> Drafts",
            {
              variant: "error",
            }
          )
        )
        .finally(() => {
          toggleDialog();
        });
    }
  };

  return (
    <>
      <Box>
        <form
          onSubmit={handleSubmit((data) => {
            createAdhocTask(data);
          })}
        >
          <Box>
            <Typography variant="caption">Überschrift</Typography>
          </Box>

          <Controller
            name="headline"
            control={control}
            rules={{ required: true, minLength: 1, validate: {} }}
            render={({ field: { onChange, value } }) => (
              <TextField
                multiline
                fullWidth
                variant="standard"
                rows={2}
                onChange={onChange}
                value={value}
                placeholder="Bitte geben Sie eine Überschrift ein"
                InputProps={{ disableUnderline: true }}
                sx={{
                  marginTop: "8px",

                  "& textarea": {
                    fontSize: "18px",
                    fontWeight: "bold",
                    "&::placeholder": {
                      color: errors.headline?.type === "required" ? "red" : "",
                    },
                  },
                }}
              />
            )}
          />

          <Typography marginTop="24px" variant="caption">
            Beschreibung
          </Typography>
          <Controller
            name="description"
            control={control}
            rules={{ required: true, minLength: 1 }}
            render={({ field: { onChange, value } }) => (
              <TextField
                multiline
                fullWidth
                variant="standard"
                rows={3}
                onChange={onChange}
                value={value}
                InputProps={{ disableUnderline: true }}
                placeholder="Bitte geben Sie eine Beschreibung ein"
                sx={{
                  marginTop: "8px",
                  "& textarea": {
                    "&::placeholder": {
                      color:
                        errors.description?.type === "required" ? "red" : "",
                    },
                  },
                }}
              />
            )}
          />
          <Stack direction="row" gap="16px" mt={8}>
            <DateDisplay
              label="Erstellungsdatum"
              disabled
              displayValue={new Date()}
            />
            <DateDisplay
              label="Ablaufdatum"
              control={control}
              name={"deadlineDate"}
              minDate={new Date()}
            />
          </Stack>
          <Box mt={3}>
            <Typography variant="caption">Erstellt von</Typography>
          </Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mt={1}>
            <img src={avatarTilman} style={{ borderRadius: '16px' }} width="32px" height="32px" alt="profile" />
            <Button
              color="primary"
              onClick={toggleChat}
              startIcon={
                <AddCommentRoundedIcon
                  sx={{ color: "white", transform: "rotateY(180deg)" }}
                />
              }
            >
              Dialog
            </Button>
          </Stack>
          <Box mt={3}>
            <FileUpload
              filesToUpload={setDocumentToupload}
              nameOfUpload="Dokumente anhängen"
            />
          </Box>
					<Stack
						mt={6.5}
						direction="row"
						justifyContent="flex-end"
					>
						<Button onClick={() => toggleDialog()}>Schliessen</Button>
						<Button
              color="primary"
              sx={{ marginLeft: "12px", boxShadow: "0px 4px 8px -2px rgba(42, 51, 60, 0.15), 0px 6px 16px -2px rgba(42, 51, 60, 0.06)" }}
              type="submit">
							Erstellen
						</Button>
					</Stack>
        </form>
      </Box>
      <Dialog
        open={chatOpen}
        onClose={toggleChat}
        PaperProps={{
          sx: {
            maxWidth: "430px",
            border: `2px solid ${theme.palette.secondary.main}`,
          },
        }}
      >
        <Chat toggleDialog={toggleChat} messages={chat} setChat={setChat} />
      </Dialog>
    </>
  );
};

export default AdhocTask;
