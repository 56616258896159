import {updateStep} from "../../../../store/slices/onboarding";
import {useDispatch} from "react-redux";
import {
	Box,
	Button as MuiButton,
	Grid as MuiGrid, InputAdornment as MuiInputAdornment, MenuItem, Modal,
	TextField as MuiTextField,
	Typography as MuiTypography, useMediaQuery, useTheme,
} from "@mui/material";
import Smile from "../../../../assets/svg/user.svg"
import Container from "../Container";
import ImageContainer from "../ImageContainer";
import {Info as InfoIcon} from "@mui/icons-material";
import {useState} from "react";

const endIcon = <MuiInputAdornment position="end">
	<InfoIcon/>
</MuiInputAdornment>;

const Step4 = () => {
	const dispatch = useDispatch();

	const goToStep3 = () => {
		dispatch(updateStep(3));
	};

	const goToStep5 = () => {
		dispatch(updateStep(5));
	};
	const [relationship, setRelationship] = useState('0');

	const theme = useTheme();
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
	const width = isSmallDevice ? '327px' : '440px';

	const [open, setOpen] = useState(false);
	const handleClose = () => setOpen(false);
	const openModal = () => setOpen(true);

	return <Container totalSteps={9} withBackground={false} activeStep={4}>
		<ImageContainer
			src={Smile}
			marginTopMd="86px"
			marginTopSm="122px"
			marginBottomMd="6px"
			marginBottomSm="6px"
		/>
		<MuiGrid
			item
			alignItems="center"
			justifyContent="center"
		>
			<MuiTypography align="center" variant="body1" color="rgba(61, 65, 77, 0.5)">
				Herr Michael Müller
			</MuiTypography>
			<MuiTypography style={{maxWidth: width}} align="center" variant="h6">
				Ich benötige weitere Informationen zu Deinem Partner:in.
			</MuiTypography>
		</MuiGrid>
		<MuiGrid
			sx={{marginTop: '40px'}}
			display="flex"
			flexDirection="column"
			spacing="12px"
		>
			<MuiGrid item>
				<MuiTextField style={{width, marginTop: '12px'}}
											placeholder="Vorname "/>
			</MuiGrid>
			<MuiGrid item>
				<MuiTextField style={{width, marginTop: '12px'}}
											placeholder="Nachname"/>
			</MuiGrid>
			<MuiTextField
				sx={{width, marginTop: '12px'}}
				placeholder="Geburtsdatum"
				InputProps={{
					endAdornment: endIcon
				}}
			/>
			<MuiGrid item>
				<MuiTypography style={{marginTop: '40px', maxWidth: width}} align="center" variant="h6">
					In welchem Verhältnis stehst du zu diesem Kind?
				</MuiTypography>
				<MuiTextField value={relationship} select sx={{width, marginTop: '12px'}}>
					<MenuItem onClick={() => setRelationship('0')} value="0" className="SelectMenuItem">Leibliches Kinderbeziehung</MenuItem>
					<MenuItem onClick={() => setRelationship('1')} value="1" className="SelectMenuItem">Leibliches Kinderbeziehung</MenuItem>
				</MuiTextField>
			</MuiGrid>
			<MuiGrid style={{paddingBottom: '80px'}} item>
				<MuiButton
					sx={{width, marginTop: "36px"}}
					color="primary"
					onClick={openModal}
				>
					weiter
				</MuiButton>
			</MuiGrid>
		</MuiGrid>
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="parent-modal-title"
			aria-describedby="parent-modal-description"
			style={styles.containerModal}
		>
			<Box
				style={{width: '340px', backgroundColor: 'white', borderRadius: '20px', padding: '24px'}}>
				<MuiTypography id="modal-modal-title" variant="subtitle1"
											 style={{textAlign: 'center', fontSize: '18px'}}>
					Haben Sie weiteres Kind?
				</MuiTypography>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						marginTop: 24,
					}}>
					<MuiButton
						sx={{width: '95px',}}
						color="primary"
						onClick={goToStep3}
					>
						ja
					</MuiButton>
					<MuiButton
						sx={{width: '95px', marginLeft: '10px'}}
						color="secondary"
						onClick={goToStep5}
					>
						nein
					</MuiButton>
				</Box>
			</Box>
		</Modal>
	</Container>
};

const styles = {
	containerModal: {
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		flex: 1,
	},
}

export default Step4;
