import {
  AddCircle as AddCircleIcon,
  Task as TaskIcon,
} from "@mui/icons-material";
import {
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import BoxMilia from "../components/Atoms/BoxMilia";
import ChipSlider from "../components/Atoms/ChipSlider";
import TableContent from "../components/Molecules/TaskOverview/TableContent";
import {
  ChipTranslation,
  TypeTranslation,
} from "../components/Molecules/TaskOverview/translations";
import useClientTaskService from "../services/fetch/useClientTaskService";

const TaskOverview = () => {
  const theme = useTheme();
  const {
    readClientTasksFilter,
    readClientTasks,
    clientTasksFilter,
    clientTasks,
  } = useClientTaskService();

  // const [tasksFilter, setTasksFilter] = useState<any>();
  // const [tasks, setTasks] = useState<any>();
  const [tasksLoading, setTasksLoading] = useState(false);
  const [tableData, setTableData] = useState<any[]>();
  const [filter, setFilter] = useState({
    days: 10,
    status: "",
    page: 0,
    sort: "creationDate,desc",
    type: "",
    size: 20,
  });

  const ScrollingElement = () => {
    const [, setScrollY] = useState(0);

    function handleScroll() {
      setScrollY(window.scrollY);

      const cH = html?.clientHeight ? html?.clientHeight : 0;
      const sH = html?.scrollHeight ? html?.scrollHeight : 0;
      const sT = html?.scrollTop ? html?.scrollTop : 0;
      if (cH + sT >= sH) {
        loadNextPage();
      }
    }

    useEffect(() => {
      const watchScroll = () => {
        window.addEventListener("scroll", handleScroll);
      };
      watchScroll();
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    });
  };

  ScrollingElement();

  useEffect(() => {
    const fetchTasksFilter = async () => {
      await readClientTasksFilter();
    };

    fetchTasksFilter();
  }, []);

  useEffect(() => {
    const fetchTasks = async () => {
      setTasksLoading(true);
      await readClientTasks({
        filter,
      });
      setTasksLoading(false);
    };

    fetchTasks();
  }, [filter]);

  useEffect(() => {
    if (clientTasks?.items) {
      if (filter.page === 0 || !tableData?.length) {
        setTableData(clientTasks.items);
      } else {
        setTableData((values: any) => [...values, ...clientTasks.items]);
      }
    } else {
      setTableData([]);
    }
  }, [clientTasks]);

  const loadNextPage = () => {
    if (clientTasks.currentPage + 1 !== clientTasks.totalPages) {
      setFilter((value) => {
        return { ...value, page: clientTasks.currentPage + 1 };
      });
    }
  };

  const onChipClick = (e: any) => {
    let status: string;
    const objValue: any = Object.keys(ChipTranslation).find(
      (key) => ChipTranslation[key] === e.target.outerText
    );
    filter.status === objValue ? (status = "") : (status = objValue);
    setFilter((oldValues) => {
      return { ...oldValues, status: status, page: 0 };
    });
  };

  const html = document.querySelector("html");

  const handleDaysChange = (e: any) => {
    setFilter((oldValues) => {
      return { ...oldValues, days: e.target.value };
    });
  };

  const handleCategoryChange = (e: any) => {
    setFilter((oldValues) => {
      return { ...oldValues, type: e.target.value };
    });
  };

  const highlightContainer = () => {
    const container = document.getElementsByClassName(
      "taskOverview"
    )[0] as HTMLElement;
    container.style.border = `2px solid ${theme.palette.secondary.main}`;
  };

  return (
    <Container disableGutters sx={{ padding: "0 16px" }}>
      <Grid container>
        <Grid item xs={12}>
          <BoxMilia
            padding="24px"
            className="taskOverview"
            onFocus={highlightContainer}
          >
            <Stack direction={"row"} alignItems="center" mb="12px" gap="8px">
              <TaskIcon color="primary" />
              <Typography variant="h6">Aufgaben</Typography>
              <AddCircleIcon
                fontSize="large"
                color="primary"
                sx={{ ml: "auto" }}
              />
            </Stack>
            <Stack direction={"row"} gap="16px" flexWrap={"wrap"} mb="16px">
              <Stack direction="column" gap="4px">
                <Typography variant="body2">Kategorien</Typography>
                <TextField
                  select
                  value={filter.type}
                  onChange={handleCategoryChange}
                >
                  <MenuItem value="">Alle Aufgaben</MenuItem>
                  {clientTasksFilter?.filters[2].values?.map((item: any) => (
                    <MenuItem value={item} key={item}>
                      {TypeTranslation.hasOwnProperty(item)
                        ? TypeTranslation[item]
                        : item}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction="column" gap="4px">
                <Typography variant="body2">Zeitraum</Typography>
                {clientTasksFilter?.filters[0].values.length > 0 && (
                  <TextField
                    select
                    value={filter.days}
                    onChange={handleDaysChange}
                  >
                    {clientTasksFilter?.filters[0].values?.map((item: any) => (
                      <MenuItem value={item} key={item}>
                        {`${item} Tage`}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Stack>
            </Stack>

            <div style={{ marginBottom: "24px" }}>
              <ChipSlider
                data={clientTasksFilter?.filters[1].values}
                translation={ChipTranslation}
                onChange={onChipClick}
              ></ChipSlider>
            </div>

            <TableContent tableData={tableData} />

            {tasksLoading && (
              <Stack alignItems={"center"} width="100%">
                <CircularProgress />
              </Stack>
            )}
          </BoxMilia>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TaskOverview;
