import { Button as MuiButton, ButtonProps } from "@mui/material";
import { ReactNode } from "react";

export interface IIconButtonProps {
  icon: ReactNode;
}

const IconButton = (props: ButtonProps & IIconButtonProps) => {
  return (
    <MuiButton
      {...props}
      startIcon={props.icon}
      sx={{
        padding: "8px !important",
        "&.MuiButton-sizeSmall": {
          height: "32px",
          minWidth: "32px",
          borderRadius: "20px",
          "> .MuiButton-iconSizeSmall > .MuiSvgIcon-root": {
            width: "16px",
            height: "16px",
          },
        },
        "&.MuiButton-sizeMedium": {
          height: "36px",
          minWidth: "36px",
          borderRadius: "20px",
          "> .MuiButton-sizeMedium > .MuiSvgIcon-root": {
            width: "20px",
            height: "20px",
          },
        },
        "&.MuiButton-sizeLarge": {
          height: "40px",
          minWidth: "40px",
          borderRadius: "25px",
          "> .MuiButton-sizeLarge > .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
        },
        "> .MuiButton-startIcon": {
          marginLeft: 0,
          marginRight: 0,
        },
        ...props.sx,
      }}
    />
  );
};

export default IconButton;
