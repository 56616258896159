import {
  Container as MuiContainer,
  Grid as MuiGrid,
  Paper as MuiPaper,
  Stack as MuiStack,
  Typography as MuiTypography,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import UserList from "../components/Molecules/UserProfile/UserList";
import { IStore } from "../store/types";
import { IUser } from "../types";
import { avatarTilman } from "../utils";

const UserProfile = () => {
  const theme = useTheme();
  const user: IUser = useSelector((state: IStore) => state.user);

  const highlightContainer = () => {
    const container = document.getElementsByClassName(
      "user-profile"
    )[0] as HTMLElement;
    container.style.border = `2px solid ${theme.palette.secondary.main}`;
  };

  return (
    <MuiContainer disableGutters={true} sx={{ padding: "0 16px" }}>
      <MuiGrid container>
        <MuiGrid item xs={12} marginBottom="12px" onFocus={highlightContainer}>
          <MuiPaper
            className="user-profile"
            sx={{
              border: "2px solid rgb(93, 248, 151) !important",
              padding: "24px",
              boxShadow:
                "0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)",
            }}
          >
            <MuiStack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing="10px"
              sx={{ marginBottom: "16px" }}
            >
              <img src={avatarTilman} alt="profile" width="24" height="24" />
              <MuiTypography variant="h6" sx={{ lineHeight: "133.4%" }}>
                {user?.firstName} {user?.lastName}
              </MuiTypography>
            </MuiStack>
            <UserList />
          </MuiPaper>
        </MuiGrid>
      </MuiGrid>
    </MuiContainer>
  );
};

export default UserProfile;
