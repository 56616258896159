export interface IBreadcrumbPath {
  text?: string;
  onClick?: (e: any) => void;
  hasPrefix?: boolean;
  isHighLight?: boolean;
}

const BreadcrumbPath = ({
  text,
  onClick,
  isHighLight,
  hasPrefix,
}: IBreadcrumbPath) => {
  return (
    <>
      {hasPrefix && (
        <span style={{ color: "#0B29F9", padding: "0 8px", cursor: "default" }}>
          _
        </span>
      )}
      <span
        onClick={onClick}
        style={{
          color: isHighLight ? "#5DF897" : "#3D414D",
          cursor: isHighLight ? "default" : "pointer",
        }}
      >
        {text}
      </span>
    </>
  );
};

export default BreadcrumbPath;

// return (
//   <>
//     {/* {hasPrefix && (
//       <span style={{ color: "#0B29F9", padding: "0 8px", cursor: "default" }}>
//         _
//       </span>
//     )} */}
//     <span
//       onClick={onClick}
//       style={{
//         borderRadius: "5px 5px 0px 5px",
//         padding: "3px 6px",
//         color: "#3D414D",
//         ...(isHighLight
//           ? {
//               backgroundColor: "#B7F235",
//               cursor: "default",
//             }
//           : {
//               backgroundColor: "#FFF",
//               cursor: "pointer",
//             }),
//         // color: isHighLight ? "#B7F235" : "#3D414D",
//         // cursor: isHighLight ? "default" : "pointer",
//       }}
//     >
//       {text}
//     </span>
//   </>
// );
