import { createSlice } from "@reduxjs/toolkit";

export const IS_ONBOARD_LOCALSTORAGE_KEY = "isOnboard"

const initialState: any = {
  step: 0,
  isWife: false,
  isChild: false,
  isOnboard: JSON.parse(localStorage.getItem(IS_ONBOARD_LOCALSTORAGE_KEY) as string),
  ACTOR_TYPE: localStorage.getItem("ACTOR_TYPE"),
  nameType: 0,
};

const onboarding = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    updateIsOnboard(state, action) {
      localStorage.setItem(IS_ONBOARD_LOCALSTORAGE_KEY, action.payload);
      state.isOnboard = action.payload;
    },
    updateStep(state, action) {
      state.step = action.payload;
    },
    updateIsWife(state, action) {
      state.isWife = action.payload;
    },
    updateIsChild(state, action) {
      state.isChild = action.payload;
    },
    updateNameType(state,action) {
      state.nameType = action.payload;
    }
  },
});

export const {
  updateStep,
  updateIsWife,
  updateIsChild,
  updateIsOnboard,
  updateNameType,
} = onboarding.actions;

export default onboarding.reducer;
