import { Container, Grid, Typography } from "@mui/material";

const SignedSucess = () => {
  return (
    <Container>
      <Grid container justifyContent={"center"} sx={{ p: 5 }}>
        <Typography variant="h6">Erfolgreich signiert.</Typography>
      </Grid>
    </Container>
  );
};

export default SignedSucess;
