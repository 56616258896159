// TODO: remove this from here

const ChipTranslation: any = {
  ACTIVE: "AKTIV",
  FINISHED: "ERLEDIGT",
  ABORTED: "ABGEBROCHEN",
  DRAFT: "DRAFT",
};

export default ChipTranslation;
