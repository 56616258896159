import { styled, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ITask } from "../../../types";
import TaskMenu from "../TaskOverview/TaskMenu";
import TaskStatus from "../TaskOverview/TaskStatus";
import { avatarMichel, avatarTilman, avatarVincent } from "../../../utils";

const TaskRow = styled("div")(({ theme }) => ({
  borderRadius: "12px",
  display: "flex",
  justifyContent: "space-between",
  gap: "8px",
  maxWidth: "100%",
  overflow: "hidden",
  position: "relative",
  paddingTop: "4px",
  paddingBottom: "4px",
  paddingLeft: "16px",
  paddingRight: "16px",
  "& .menuIcon": {
    transform: "rotate(90deg)",
    cursor: "pointer",
    whiteSpace: "nowrap",
    padding: 0,
  },
  "&:last-child": {
    borderBottom: "none",
  },

  "&::after": {
    content: '""',
    height: "100%",
    width: "0",
    position: "absolute",
    top: 0,
    left: 0,
    transition: "width 150ms",
    backgroundColor: `${theme.palette.secondary.main}30`,
  },
  "&:hover": {
    background: "#B7F23530",
    borderRadius: "12px",
  },
  "&.highlight::after": {
    width: "100%",
  },
}));

const TaskName = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  flex: 1,
  minWidth: 0,

  "& .name": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    cursor: "pointer",
  },
  "& .name p": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const AvatarDisplay = styled("div")(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("lg")]: {
    display: "flex",
    alignItems: "center",
    gap: "3px",
  },
}));

interface IProps {
  data: ITask;
}

const Task = (props: IProps) => {
  const [selected, setSelected] = useState<boolean>(false);
  const navigate = useNavigate();

  const { status, name, id } = props.data;

  return (
    <TaskRow className={selected ? "highlight" : ""}>
      <TaskName>
        <TaskStatus status={status} />
        <div className="name" onClick={() => navigate(`/task/${id}`)}>
          <Typography variant="body2" textTransform={"uppercase"}>
            {name}
          </Typography>
        </div>
      </TaskName>
      <AvatarDisplay>
        <img src={avatarMichel} style={{ borderRadius: '12px' }} width="24px" height="24px" alt="profile" />
        <img src={avatarTilman} style={{ borderRadius: '12px' }} width="24px" height="24px" alt="profile" />
        <img src={avatarVincent} style={{ borderRadius: '12px' }} width="24px" height="24px" alt="profile" />
      </AvatarDisplay>
      <TaskMenu
        iconColor={"#00000087"}
        taskData={props.data}
        setSelected={setSelected}
      />
    </TaskRow>
  );
};

export default Task;
