import {
  Avatar,
  Badge,
  Box,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ChatIcon } from "../../../assets/svg/chat.svg";
import { IDocument } from "../../../types";
import { DocumentMenu } from "./DocumentMenu";
import { DocumentTableChip } from "./DocumentTableChip";
import { CategoryTranslation, TypeTranslation } from "./translations";
import { avatarTilman } from "../../../utils";

const reduceChipToFourLetter = (label: string) => {
  if (label) return label.split("").slice(0, 4).join("");
  else return "";
};

export const DocumentsTableContent = ({ data, smallDevice }: any) => {
  const landscapeTablet = useMediaQuery("(min-width:1024px)");
  const navigate = useNavigate();
  return (
    <TableBody>
      {data?.map((doc: IDocument) => {
        const documentCategory: any = reduceChipToFourLetter(
          CategoryTranslation[doc.category]
        );
        const documentType = reduceChipToFourLetter(TypeTranslation[doc.type]);
        let date = moment(doc.creationDate).format("DD.MM");
        if (landscapeTablet)
          date = moment(doc.creationDate).format("DD.MM.YYYY");
        return (
          <TableRow key={doc.id}>
            <TableCell>
              <Typography
                variant="body2"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                onClick={() => navigate(`/document/${doc.id}`)}
                sx={{ cursor: "pointer" }}
              >
                {doc.name}
              </Typography>
            </TableCell>
            <TableCell align="center">
              {doc.category?.length && (
                <DocumentTableChip
                  width={44}
                  color="white"
                  bgcolor="#7A86EC"
                  label={documentCategory}
                />
              )}
            </TableCell>
            {smallDevice && (
              <TableCell align="center">
                {doc.type?.length && (
                  <DocumentTableChip
                    width={58}
                    color="white"
                    bgcolor="#EAEAEA"
                    label={documentType}
                  />
                )}
              </TableCell>
            )}
            <TableCell align="center">
              <Typography
                variant="body2"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {date}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Box sx={{ margin: "0 auto", width: "fit-content" }}>
                <Badge
                  variant="dot"
                  overlap="circular"
                  color="secondary"
                  sx={{
                    top: "20%",
                    right: "20%",
                    maxWidth: "100%",
                  }}
                >
                  <ChatIcon />
                </Badge>
              </Box>
            </TableCell>
            <TableCell sx={{ display: { xs: "none", xl: "table-cell" } }}>
              <Avatar
                sx={{
                  bgcolor: "green",
                  color: "green",
                  width: "16px",
                  height: "16px",
                  margin: "0 auto",
                }}
              >
                <img src={avatarTilman} style={{ borderRadius: '8px' }} width="16px" height="16px" alt="profile" />
              </Avatar>
            </TableCell>
            <TableCell align="right">
              <DocumentMenu id={doc.id} name={doc.name} />
            </TableCell>
          </TableRow>
        );
      })}
      {/* <TableRow>
        <TableCell>
          <Typography variant="body2" overflow="hidden" textOverflow="ellipsis">
            {"thisisaverylongdocumentname.pdf"}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <DocumentTableChip
            width={44}
            color="white"
            bgcolor="#7A86EC"
            label={"CATE"}
          />
        </TableCell>
        <TableCell align="center" width="82px">
          <DocumentTableChip
            width={58}
            color="white"
            bgcolor="#EAEAEA"
            label={"Type"}
          />
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">{"08.12"}</Typography>
        </TableCell>
        <TableCell>
          <Badge
            variant="dot"
            color="secondary"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            overlap="circular"
          >
            <ChatRoundedIcon color="primary" fontSize="small" />
          </Badge>
        </TableCell>
        <TableCell align="center" width="10%">
          <Avatar
            sx={{
              bgcolor: "green",
              color: "green",
              width: "16px",
              height: "16px",
            }}
          />
        </TableCell>
        <TableCell align="right">
          <MoreHorizIcon color="primary" />
        </TableCell>
      </TableRow> */}
    </TableBody>
  );
};
