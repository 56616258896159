import { TodayOutlined as TodayOutlinedIcon } from "@mui/icons-material";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { de } from "date-fns/locale";
import "./style.css";

interface IProps {
  label?: string;
  helperText?: string;
  withInput?: boolean;
  value: any;
  onChange: any;
  disabled?: boolean;
  minDate?: Date;
  error?: boolean;
  placeholder?: string;
  width?: string;
  height?: string;
}

const CustomDatePicker = (props: IProps) => {
  const {
    error,
    value,
    label,
    minDate,
    onChange,
    disabled,
    helperText,
    placeholder,
    withInput = true,
    width,
    height,
  } = props;

  const color = disabled ? "#EAEAEA" : error ? "#FF0000" : "#1129F5";

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
      <Stack
        direction={"column"}
        sx={{
          "&:has(.MUI-DatepickerWrapper):has(.MuiFormControl-root):has(.Mui-error) > .MuiTypography-root":
            {
              color: "#FF0000",
            },
        }}
      >
        {label && (
          <Typography
            variant="caption"
            data-testid="dateLabel"
            sx={{
              color: disabled ? "#EAEAEA" : error ? "#FF0000" : "#707070",
              lineHeight: "143%",
              marginBottom: "4px",
            }}
          >
            {label}
          </Typography>
        )}

        <Box className="MUI-DatepickerWrapper">
          <DesktopDatePicker
            views={["year", "month", "day"]}
            InputProps={{
              sx: {
                // Input container styles
                minWidth: "72px",
                padding: "8px",
                border: `2px solid ${color}`,
                borderRadius: "8px",
                background: "#FFFFFF",
                width: width ? width : "auto",
                height: height ? height : "auto",

                // Input styles
                "> .MuiInputBase-input": {
                  height: "20px",
                  color: color,
                  fontSize: "14px",
                  fontWeight: 700,
                  fontHeight: "143%",
                  letterSpace: "0.17px",
                },

                // Placeholder styles
                "> .MuiInputBase-input::placeholder": {
                  opacity: 1,
                  fontSize: "12px",
                },

                // Open picker button styles
                "> .MuiInputAdornment-root > .MuiButtonBase-root": {
                  paddingLeft: 0,
                },
                "> .MuiInputAdornment-root > .MuiButtonBase-root > .MuiSvgIcon-root":
                  {
                    color: color,
                    width: "20px",
                    height: "20px",
                  },
                "&.Mui-disabled > .MuiOutlinedInput-input": {
                  opacity: 0.5,
                },
                "&.Mui-error": {
                  color: "#FF0000",
                  border: "2px solid #FF0000",
                },
                "&.Mui-disabled": {
                  color: "#EAEAEA",
                  background: "#FFFFFF",
                  border: "2px solid #EAEAEA",
                },

                // Without input
                ...(!withInput && {
                  // Input container styles
                  minWidth: "36px",
                  width: "36px",
                  height: "36px",

                  // Input styles
                  "> .MuiInputBase-input": {
                    display: "none",
                  },

                  // Open picker button styles
                  "> .MuiInputAdornment-root > .MuiButtonBase-root": {
                    padding: "0px",
                    marginLeft: "-10px",
                  },
                }),

                // Helper text styles
                "&+ .MuiFormHelperText-root": {
                  color: disabled ? "#EAEAEA" : error ? "#FF0000" : "#707070",
                  marginTop: "4px",
                  lineHeight: "143%",
                },
              },
            }}
            PopperProps={{
              placement: "bottom",
            }}
            PaperProps={{
              sx: {
                ".Mui-selected": {
                  color: "white !important",
                  background: "#B7F235 !important",
                },

                // Controls container styles
                "div:first-child": {
                  // Month and year controls styles
                  ".PrivatePickersFadeTransitionGroup-root": {
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "150%",
                    letterSpacing: "0.15px",
                    color: "rgba(0, 0, 0, 0.87)",
                  },

                  // Arrow styles
                  ".MuiButtonBase-root": {
                    width: "32px",
                    height: "32px",
                    padding: "4px",
                  },
                  ".MuiButtonBase-root:hover": {
                    background: "#EAEAEA",
                  },
                  ".MuiTouchRipple-root": {
                    display: "none",
                  },
                },

                // Picker container styles
                "div:last-child": {
                  "div[role=cell]": {
                    width: "36px",
                    height: "36px",
                  },

                  // Weekdays container styles
                  "> .MuiCalendarPicker-viewTransitionContainer": {
                    overflowY: "hidden",
                    padding: "0px 28px",
                    "> div:first-child": {
                      "> div:first-child": {
                        marginBottom: "12px",
                        justifyContent: "space-between",
                        // Weekdays styles
                        "> span": {
                          fontFamily: "Roboto",
                          fontSize: "12px",
                          lineHeight: "166%",
                          letterSpacing: "0.4px",
                          color: "rgba(0, 0, 0, 0.38)",
                          margin: 0,
                        },
                      },
                    },
                  },

                  // Scrolls on years picker
                  "> .PrivatePickersFadeTransitionGroup-root:has(.MuiYearPicker-root)":
                    {
                      overflowY: "scroll",
                    },

                  // Yeas container styles
                  "> .PrivatePickersFadeTransitionGroup-root": {
                    ".PrivatePickersYear-yearButton, .PrivatePickersMonth-root":
                      {
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "143%",
                        letterSpacing: "0.17px",
                      },
                    ".MuiMonthPicker-root": {
                      width: "100%",
                      margin: 0,
                    },
                    ".MuiYearPicker-root": {
                      width: "100%",
                      margin: 0,
                    },
                  },

                  // Dates styles
                  ".PrivatePickersSlideTransition-root": {
                    "div[role=row]": {
                      justifyContent: "space-between",
                    },
                    ".MuiButtonBase-root": {
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "143%",
                      letterSpacing: "0.17px",
                      border: "none",

                      ".MuiButtonBase-root:hover": {
                        color: "#FFFFFF",
                        background: "#EAEAEA",
                      },
                    },
                  },
                },
              },
            }}
            showToolbar={false}
            mask="__.__.____"
            value={value}
            disabled={disabled}
            OpenPickerButtonProps={{
              disableRipple: true,
            }}
            inputFormat="dd.MM.yyyy"
            minDate={minDate ? minDate : new Date("1970-01-01")}
            onChange={onChange}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={helperText}
                inputProps={{
                  ...params.inputProps,
                  placeholder: placeholder ? placeholder : "dd.MM.yyyy",
                }}
                fullWidth
              />
            )}
            components={{
              OpenPickerIcon: TodayOutlinedIcon,
            }}
          />
        </Box>
      </Stack>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
