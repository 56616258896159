import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../types";

const initialState: IUser | {} = {};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser(state, action) {
      return action.payload;
    },
  },
});

export const { updateUser } = user.actions;

export default user.reducer;
