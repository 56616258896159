import ClearIcon from "@mui/icons-material/Clear";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Button, Stack, Typography } from "@mui/material";
import { theme } from "../../../assets/css/theme";
import { ReactComponent as PlusIcon } from "../../../assets/svg/circlePlus.svg";
import { IDocument } from "../../../types";

interface IProps {
  groupName?: string;
  open: any;
  existingFiles: any;
  handleDeleteFile: any;
  handleRemoveFile: any;
  files: any;
  toggleDialog: any;
}

const FileDialog = (props: IProps) => {
  const {
    groupName,
    open,
    existingFiles,
    handleDeleteFile,
    files,
    toggleDialog,
    handleRemoveFile,
  } = props;
  return (
    <Stack direction="column" p={2}>
      <Stack direction="row" justifyContent="space-between" gap={1} mb={4}>
        <Stack direction="row" gap={0.5} alignItems="center">
          <Typography
            variant="h6"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {groupName ? groupName : "Dokumente"}
          </Typography>
          <InfoIcon
            htmlColor={theme.palette.grey[400]}
            sx={{ width: "16px", height: "16px" }}
          />
        </Stack>
        <Box
          sx={{
            height: "32px",
            cursor: "pointer",
            "& svg": {
              filter: "drop-shadow(1px 4px 3px rgb(0 0 0 / 0.4))",
            },
          }}
          onClick={open}
        >
          <PlusIcon />
        </Box>
      </Stack>
      <Stack direction="column" gap={2}>
        {existingFiles.map((file: IDocument) => (
          <Stack direction="row" gap={1} alignItems="center" key={file.id}>
            <Stack
              alignItems="center"
              onClick={() => handleDeleteFile(file.id)}
            >
              <ClearIcon sx={{ cursor: "pointer" }} />
            </Stack>
            <Typography
              key={file.id}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {file.name}
            </Typography>
          </Stack>
        ))}
        {files.map((file: any, index: number) => (
          <Stack direction="row" gap={1} alignItems="center" key={file}>
            <Stack alignItems="center" onClick={() => handleRemoveFile(file)}>
              <ClearIcon sx={{ cursor: "pointer" }} />
            </Stack>
            <Typography
              key={`${file.path}+${index}`}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {file.name}
            </Typography>
          </Stack>
        ))}
      </Stack>
      <Box mt={3}>
        <Button onClick={toggleDialog}>Schliessen</Button>
      </Box>
    </Stack>
  );
};

export default FileDialog;
