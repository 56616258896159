import { Box, Grid, TextField } from "@mui/material";

const Steuerdaten = () => {
  return (
    <Box>
      <Grid container spacing={{ xs: 0 }} columnSpacing={{ md: 3 }}>
        <Grid item xs={12} md={6} marginBottom="10px">
          <TextField
            fullWidth
            label="Geschäftsführer"
            variant="standard"
            value="Tilman Walch"
          />
        </Grid>
        <Grid item xs={12} md={6} marginBottom="10px">
          <TextField
            fullWidth
            label="Geburtsdatum"
            variant="standard"
            value="21.03.2012"
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6} marginBottom="10px">
          <TextField
            fullWidth
            label="Straße und Hausnummer"
            variant="standard"
            value="Gothestrasse 7"
          />
        </Grid>

        <Grid item xs={12} md={6} marginBottom="10px">
          <TextField fullWidth label="PLZ" variant="standard" value="80333" />
        </Grid>

        <Grid item xs={12} md={6} marginBottom="10px">
          <TextField fullWidth label="Ort" variant="standard" value="München" />
        </Grid>

        <Grid item xs={12} md={6} marginBottom="10px">
          <TextField
            fullWidth
            label="USt-Id"
            variant="standard"
            value="DE1234567891"
          />
        </Grid>

        <Grid item xs={12} md={6} marginBottom="10px">
          <TextField
            fullWidth
            label="E-Mail"
            variant="standard"
            value="Tilman@milia.io"
          />
        </Grid>

        <Grid item xs={12} md={6} marginBottom="10px">
          <TextField
            fullWidth
            label="Telefon"
            variant="standard"
            value="017624447799"
          />
        </Grid>

        <Grid item xs={12} md={6} marginBottom="10px">
          <TextField
            fullWidth
            label="Person ist gleichzeitig Geschäftsführer"
            variant="standard"
            value="ja"
          />
        </Grid>

        <Grid item xs={12} md={6} marginBottom="10px">
          <TextField
            fullWidth
            label="Geschäftsanteile in Prozent"
            variant="standard"
            value="30%"
          />
        </Grid>

        <Grid item xs={12} md={6} marginBottom="10px">
          <TextField
            fullWidth
            label="Gesellschaftsverhältnis seit"
            variant="standard"
            value="20.03.2010"
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>

        {/* <Grid item xs={12} md={6} marginBottom="10px">
          <TextField
            fullWidth
            label="Steuer-ID"
            variant="standard"
            value={currentClient?.taxId}
            disabled
          />
        </Grid> */}
        {/* <Grid item xs={12} md={6} marginBottom={isMobile ? 0 : "10px"}>
          <TextField
            fullWidth
            label="Mandant seit"
            variant="standard"
            value={getFullDate(currentClient?.clientSince)}
            disabled
            
          /> */}
        {/* </Grid> */}
      </Grid>
    </Box>
  );
};

export default Steuerdaten;
