import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { theme } from "../../assets/css/theme";
import { IChatMessage } from "../../ressources/interfaces";
import useClientTaskService from "../../services/fetch/useClientTaskService";
import {avatarTilman} from "../../utils";

export const styles: any = {
  CLIENT: {
    borderRadius: "20px 20px 0px 20px",
    marginLeft: "auto",
    backgroundColor: "#1129F530",
  },
  TAX_OFFICE: {
    borderRadius: "20px 20px 20px 0px",
    marginRight: "auto",
    backgroundColor: "#B7F23530",
  },
};

interface IChat {
  toggleDialog?: any;
  messages: Array<IChatMessage>;
  setChat: any;
  taskId?: number;
  taskName?: string;
}

const Chat = (props: IChat) => {
  const { toggleDialog, messages, setChat, taskId, taskName } = props;
  const { createClientTaskMessage } = useClientTaskService();
  const chatRef = useRef<HTMLElement>();
  const [chatMessage, setChatMessage] = useState("");

  useEffect(() => {
    if (chatRef.current)
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [messages]);

  const sendMessage = () => {
    if (chatMessage.trim().length < 0) return;

    const newMessage: IChatMessage = {
      message: chatMessage,
      senderFullName: "", //if empty, fullname and email will be set by the backend
      senderEmail: "",
      creationDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
      sentBy: "CLIENT",
    };
    if (taskId) {
      createClientTaskMessage({ taskId: taskId!, data: newMessage }).then(
        (createdMessage) => {
          setChat((old: Array<IChatMessage>) => {
            return [...old, createdMessage];
          });
        }
      );
    } else {
      setChat((old: Array<IChatMessage>) => {
        return [...old, newMessage];
      });
    }

    setChatMessage("");
  };

  return (
    <Stack p={3} sx={{ height: "100%" }}>
      {toggleDialog && (
        <Typography
          variant="caption"
          color={theme.palette.primary.main}
          onClick={toggleDialog}
          sx={{ cursor: "pointer" }}
        >
          &lt; zurück zur Aufgabe
        </Typography>
      )}
      {taskName && (
        <Typography variant="h6" mt={2}>
          {taskName}
        </Typography>
      )}
      <Stack
        mt={3}
        direction="column"
        gap={1}
        pb={2}
        ref={chatRef}
        minHeight="40vh"
        maxHeight="40vh"
        sx={{ overflowY: "auto" }}
      >
        {messages.map((message, index) => {
          const date = moment(message.creationDate).format("DD.MM.YY HH:mm");
          return (
            <Box key={message.message + index}>
              <Box {...styles[message.sentBy]} p={2} width="70%">
                <Typography
                  sx={{ wordWrap: "break-word" }}
                  variant="body2"
                  whiteSpace="pre-line"
                >
                  {message.message}
                </Typography>
              </Box>
              <Stack
                direction="row"
                alignItems="center"
                gap={0.5}
                mt={0.5}
                width="max-content"
                marginLeft={message.sentBy === "CLIENT" ? "auto" : 0}
                marginRight={message.sentBy === "TAX_OFFICE" ? "auto" : 0}
              >
                <img src={avatarTilman} style={{ borderRadius: '8px' }} width="16px" height="16px" alt="profile" />
                <Typography>
                  {message.senderFullName} {date}
                </Typography>
              </Stack>
            </Box>
          );
        })}
      </Stack>
      <Stack direction="row" gap={2} mt="auto">
        <TextField
          multiline
          maxRows={5}
          onChange={(e: any) => setChatMessage(e.target.value)}
          value={chatMessage}
          inputProps={{ sx: { padding: "10px 16px" } }}
          sx={{
            flex: 1,
            ".MuiInputBase-root": {
              padding: "8px 0",
            },
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter" && !e.shiftKey) e.preventDefault();
            if (e.key === "Enter" && !e.shiftKey && chatMessage !== "")
              sendMessage();
          }}
        />
        <Stack justifyContent="end">
          <Button
            disabled={!chatMessage.trim().length}
            color="primary"
            onClick={sendMessage}
          >
            Senden
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Chat;
