import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BoxMilia from "../components/Atoms/BoxMilia";
import { DateDisplay } from "../components/Atoms/DateDisplay";
import DocumentHeader from "../components/Molecules/DocumentDetail/DocumentHeader";
import DocumentViewer from "../components/Molecules/DocumentDetail/DocumentViewer";
import useClientDocumentService from "../services/fetch/useClientDocumentService";
import { avatarMichel, avatarTilman, avatarVincent } from "../utils";

const DocumentDetail = () => {
  const { documentId } = useParams();
  const { readClientDocument, clientDocument } = useClientDocumentService();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDocument = async () => {
      await readClientDocument({
        documentId: parseInt(documentId!, 10),
      });
    };

    fetchDocument();
  }, []);

  return (
    <Container>
      <BoxMilia sx={{ padding: "24px", overflow: "hidden" }}>
        {clientDocument && <DocumentHeader document={clientDocument} />}
        <Box m={3} />
        {clientDocument && <DocumentViewer document={clientDocument} />}
        <Box mt="24px">
          {clientDocument && (
            <DateDisplay
              label="Erstellungsdatum"
              displayValue={new Date(clientDocument!.creationDate)}
              disabled
            />
          )}
        </Box>
        <Box mt="14px">
          <Typography variant="caption" color="#BCBCBC">
            Bearbeiter
          </Typography>
        </Box>
        <Stack direction="row" gap="3px">
          <img
            src={avatarMichel}
            style={{ borderRadius: "11px" }}
            width="22px"
            height="22px"
            alt="profile"
          />
          <img
            src={avatarTilman}
            style={{ borderRadius: "11px" }}
            width="22px"
            height="22px"
            alt="profile"
          />
          <img
            src={avatarVincent}
            style={{ borderRadius: "11px" }}
            width="22px"
            height="22px"
            alt="profile"
          />
          <img
            src={avatarMichel}
            style={{ borderRadius: "11px" }}
            width="22px"
            height="22px"
            alt="profile"
          />
        </Stack>
        <Stack mt="24px" gap="12px" direction="row" justifyContent="flex-end">
          <Button onClick={() => navigate("/")}>Schliessen</Button>
          {/*<Button
            sx={{ boxShadow: "0px 4px 8px -2px rgba(42, 51, 60, 0.15), 0px 6px 16px -2px rgba(42, 51, 60, 0.06)" }}
            color="primary">
            Aufgabe
          </Button> */}
        </Stack>
      </BoxMilia>
    </Container>
  );
};

export default DocumentDetail;
