import { Badge, Box, Dialog, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { theme } from "../../../assets/css/theme";
import { ReactComponent as PlusIcon } from "../../../assets/svg/circlePlus.svg";
import { ReactComponent as DocIconBlue } from "../../../assets/svg/document-blue.svg";
import { ReactComponent as DocIconGray } from "../../../assets/svg/document-gray.svg";
import { IDocument } from "../../../types";
import FileDialog from "./FileDialog";
import { acceptStyle, baseStyle, filledStyle, rejectStyle } from "./styles";

interface IProps {
  groupId?: number;
  groupName?: string;
  existingDoc?: Array<IDocument>;
  filesToUpload: any;
  filesToDelete?: any;
}

const UploadComponent = (props: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const { groupId, filesToUpload, filesToDelete, groupName, existingDoc } =
    props;
  const [existingFiles, setExistingFiles] = useState<Array<IDocument>>(
    existingDoc || []
  );

  const [files, setFiles] = useState<Array<File>>([]);

  const nameLengthValidator = (files: Array<File>) => {
    return files.filter((file) => {
      if (file.name.length > 50) {
        enqueueSnackbar("Dateinamen dürfen maximal 50 Zeichen lang sein", {
          variant: "error",
        });
        return false;
      } else return true;
    });
  };

  const newFiles = (files: Array<any>) => {
    const filteredFiles = nameLengthValidator(files);
    setFiles((old: any) => [...old, ...filteredFiles]);
    const mappedFiles = filteredFiles.map((file: any) => ({
      file: file,
      groupId: groupId ? groupId : null,
    }));
    filesToUpload((old: any) => [...old, ...mappedFiles]);
  };

  const { open, getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      noClick: true,
      onDrop: (acceptedFiles) => {
        newFiles(acceptedFiles);
      },
      multiple: true,
      accept: {
        "image/jpeg": [],
        "application/pdf": [],
        "text/plain": [],
        "image/png": [],
      },
    });

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const toggleDialog = (e: any) => {
    e.preventDefault();
    setDialogOpen(!dialogOpen);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(existingFiles.length + files.length > 0 ? filledStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragReject, existingFiles.length, files.length]
  );

  const handleRemoveFile = (index: any) => {
    filesToUpload((old: any) => old.filter((file: any) => file.file !== index));
    setFiles((old: any) => old.filter((file: any) => file !== index));
  };

  const handleDeleteFile = (id: number) => {
    setExistingFiles((old: Array<IDocument>) =>
      old.filter((doc: IDocument) => doc.id !== id)
    );
    const fileToDelete = { id: id, groupId: groupId };
    filesToDelete((old: any) => [...old, fileToDelete]);
  };

  return (
    <>
      <div {...getRootProps({ style })}>
        <input id="fileInput" {...getInputProps()} />
        <Typography
          sx={{ flex: 1 }}
          mr={1}
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {isDragReject ? "Datei nicht ünterstützt" : groupName}
        </Typography>
        <Box mr={1}>
          {existingFiles.length > 0 || files.length > 0 ? (
            <Badge variant="dot" overlap="circular" color="secondary">
              <Stack
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={toggleDialog}
              >
                <DocIconBlue />
              </Stack>
            </Badge>
          ) : (
            <Stack alignItems="center">
              <DocIconGray />
            </Stack>
          )}
        </Box>
        <Typography fontWeight="bold" fontSize="16px" mr={1} width="2ch">
          {existingFiles.length + files.length > 0
            ? existingFiles.length + files.length
            : ""}
        </Typography>
        <Box
          sx={{
            height: "32px",
            cursor: "pointer",
            "& svg": { filter: "drop-shadow(1px 4px 3px rgb(0 0 0 / 0.4))" },
          }}
          onClick={open}
        >
          <PlusIcon />
        </Box>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={toggleDialog}
        PaperProps={{
          sx: {
            border: `2px solid ${theme.palette.secondary.main}`,
            width: "375px",
          },
        }}
      >
        <FileDialog
          existingFiles={existingFiles}
          files={files}
          handleDeleteFile={handleDeleteFile}
          handleRemoveFile={handleRemoveFile}
          open={open}
          toggleDialog={toggleDialog}
          groupName={groupName}
        />
      </Dialog>
    </>
  );
};

export default UploadComponent;
