import React from "react";
import FormMultiStepWrapper, {
  FormStep,
} from "../../../Organisms/MultiStep/FormMultiStepWrapper";
import OnboardingPageWrapper from "../../../Templates/OnboardingPageWrapper";
import Step9 from "../CompanyOnboarding/Step9";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import WelcomeScreen from "./WelcomeScreen";

const NaturalPersonOnboarding = () => {
  const [completed, setCompleted] = React.useState<boolean>(false);
  const [married, setMarried] = React.useState<boolean>(false);
  const [children, setChildren] = React.useState<boolean>(false);

  return (
    <OnboardingPageWrapper>
      <FormMultiStepWrapper
        initialValues={{}}
        onSubmit={(values: any) => {
          setCompleted(true);
          console.log("value");
        }}
        completed={completed}
      >
        <FormStep
          // validationSchema={yup.object({
          //   firstName: yup.string().required(t("form.errors.missingFields")),
          //   lastName: yup.string().required(t("form.errors.missingFields")),
          // })}
          withBackground
          hideProgress
          MultiStepNavigationProps={{
            buttonLabelNext: "los geht's",
          }}
        >
          <WelcomeScreen />
        </FormStep>
        <FormStep
          withBackground={false}
          MultiStepNavigationProps={{
            showSkip: true,
          }}
        >
          <Step1 />
        </FormStep>
        <FormStep
          withBackground={false}
          onSubmit={async (values: any) => {
            setMarried(values.married);
            setChildren(values.children);
          }}
        >
          <Step2 />
        </FormStep>
        {married && (
          <FormStep withBackground={false}>
            <Step3 />
          </FormStep>
        )}
        {children && (
          <FormStep>
            <Step4 />
          </FormStep>
        )}
        <FormStep>
          <Step5 />
        </FormStep>
        <FormStep>
          <Step6 />
        </FormStep>
        <FormStep>
          <Step9 />
        </FormStep>
      </FormMultiStepWrapper>
    </OnboardingPageWrapper>
  );
};

export default NaturalPersonOnboarding;
