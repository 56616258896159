import { useEnv } from "../providers";
import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosRequestConfig } from "axios";

export const useFetchData = () => {
  const { apiEndpoint } = useEnv();
  const { getAccessTokenSilently } = useAuth0();

  const fetchData = async (url: string, options?: AxiosRequestConfig) => {
    const token = await getAccessTokenSilently();

    const defaultOptions: AxiosRequestConfig = {
      url: `${apiEndpoint}/${url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      ...options,
    };

    return axios(defaultOptions);
  };

  const postDocument = async (url: string, document: any) => {
    const token = await getAccessTokenSilently();
    const defaultOptions: AxiosRequestConfig = {
      url: `${apiEndpoint}/${url}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
      data: document,
    };

    return axios(defaultOptions);
  };

  return { fetchData, postDocument };
};
