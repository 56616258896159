import { Box, Button, Grid, Stack, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FingerImage from "../../../assets/images/finger.png";
import SigningBackgroundImage from "../../../assets/images/signingBg.png";
import { ITask } from "../../../types";
import { DateDisplay } from "../../Atoms/DateDisplay";
import TaskInfo from "./TaskInfo";
import TaskInfoHeader from "./TaskInfoHeader";

interface IProps {
  taskData: ITask;
  handleClose: any;
}

const ClickWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  width: 300,
  maxWidth: "100%",
  height: 148,
  backgroundImage: `url(${SigningBackgroundImage})`,
  cursor: "pointer",

  "& .pulse": {
    position: "absolute",
    top: "34%",
    left: "47%",
    transform: `translate(-50%,-50%)`,
    width: 40,
    height: 40,
    border: `3px solid ${theme.palette.common.white}`,
    borderRadius: "50%",
    animation: `pulse 2s infinite`,
    animationDelay: "1s",
    filter: `blur(2px)`,
    "@keyframes pulse": {
      "0%": {
        width: 10,
        height: 10,
      },
      "50%": {
        width: 40,
        height: 40,
      },
      "100%": {
        opacity: 0,
      },
    },
  },

  "& .finger": {
    animation: "click 1s infinite alternate",
    "@keyframes click": {
      "0%": {
        transform: `translate(-50%, -50%) rotateX(0deg)`,
      },
      "100%": {
        transform: `translate(-50%, -50%) rotateX(-20deg)`,
      },
    },
  },
}));

const TaskModalSigning = (props: IProps) => {
  const navigate = useNavigate();
  const { taskData } = props;
  return (
    <>
      <Box padding={3}>
        <TaskInfoHeader data={taskData} />
        <Grid container columnSpacing={3} rowSpacing={3}>
          <Grid item xs={12} md={6}>
            <Stack direction={"column"} gap="24px" mt="24px">
              <TaskInfo data={taskData} />
              <DateDisplay
                label="Erstellungsdatum"
                displayValue={new Date(taskData.creationDate)}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack height={"100%"} justifyContent={"center"}>
              <ClickWrapper
                onClick={() => navigate(`/task/${taskData.id}`)}
                data-testid="clickWrapper"
              >
                <div className="pulse"></div>
                <img
                  src={FingerImage}
                  alt="finger"
                  className="finger"
                  style={{
                    width: 80,
                    height: 80,
                    opacity: 0.8,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: `translate(-50%,-50%)`,
                  }}
                />
              </ClickWrapper>
            </Stack>
          </Grid>
        </Grid>
        <Stack direction={"row"} gap={1.5} mt="24px">
          <Button onClick={props.handleClose}>Schließen</Button>
          <Button
            color="primary"
            sx={{ boxShadow: "0px 4px 8px -2px rgba(42, 51, 60, 0.15), 0px 6px 16px -2px rgba(42, 51, 60, 0.06)" }}
            onClick={() => navigate(`/task/${taskData.id}`)}
          >
            Starten
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default TaskModalSigning;
