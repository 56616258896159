import {Avatar, Divider, ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import { useDispatch } from "react-redux";
import { updateCurrentClient } from "../../../store/slices/currentClient";
import { IClient } from "../../../types";
import { avatarMichel, avatarTilman } from "../../../utils";

// TODO: move this to types
export interface IMandantClient extends IClient {
  permission: string;
  permissionGroup: string;
}

interface IProps {
  test: IMandantClient[];
}

const CollapseMandantChange = (props: IProps) => {
  const dispatch = useDispatch();
  const changeClient = (client: IClient) => {
    dispatch(updateCurrentClient(client));
  };
  return (
    <>
      <MenuItem disableRipple disableTouchRipple>
        <Divider component="div" sx={{ width: "100%" }} />
      </MenuItem>

      {props?.test?.map((client: IMandantClient) => {
        const link = client.id === 1 ? avatarMichel : avatarTilman;
        return (
          <MenuItem key={client.id} onClick={() => changeClient(client)}>
            <ListItemIcon>
              <Avatar
                sx={{
                  height: 24,
                  width: 24,
                  backgroundColor: "primary.main",
                }}
              >
                <img src={link} width="24px" height="24px" alt="profile" />
              </Avatar>
            </ListItemIcon>
            <ListItemText>{client.name}</ListItemText>
          </MenuItem>
        );
      })}

      <MenuItem disableRipple disableTouchRipple>
        <Divider component="div" sx={{ width: "100%" }} />
      </MenuItem>
    </>
  );
};

export default CollapseMandantChange;
