import { Container as MuiContainer, Grid as MuiGrid } from "@mui/material";
import { useSelector } from "react-redux";
import PersonDetail from "../components/Molecules/RoleDetail/PersonDetail";
import WidgetWrapper from "../components/Molecules/WidgetWrapper";
import { IStore } from "../store/types";
import { EClientType } from "../types";

const RoleDetail = () => {
  const currentClient = useSelector((state: IStore) => state.currentClient);

  return (
    <MuiContainer disableGutters={true} sx={{ padding: "0 16px" }}>
      <MuiGrid container>
        <WidgetWrapper
          title={{ caption: `${currentClient?.name}` }}
          subTitle={`${(EClientType as any)[currentClient?.type]}`}
          img={{ src: "/img/profile-photo.svg" }}
        />
        <WidgetWrapper
          title={{ caption: "Geschäftsführer" }}
          img={{ src: "/img/person.svg", width: 16, height: 16 }}
        >
          <PersonDetail />
        </WidgetWrapper>
      </MuiGrid>
    </MuiContainer>
  );
};

export default RoleDetail;
