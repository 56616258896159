import {
  Box,
  Grid as MuiGrid,
  Typography as MuiTypography,
} from "@mui/material";
import Daumen from "../../../../assets/images/daumen.png";
import ImageContainer from "../ImageContainer";

const WelcomeScreen = () => {
  return (
    <>
      <Box
        style={{
          flex: 1,
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ImageContainer src={Daumen} />
        <MuiGrid
          item
          alignItems="center"
          justifyContent="center"
          sx={{ marginBottom: "40px" }}
        >
          <MuiTypography align="center" variant="h6">
            Hi, ich bin milia!
          </MuiTypography>
          <MuiTypography align="center" paragraph variant="body2">
            Sie können nun mit dem Onboarding in <br />
            Ihrer Kanzlei der Zukunft starten. <br />
            Folgen Sie einfach den Schritten, <br />
            die ich Ihnen aufzeige.
          </MuiTypography>
        </MuiGrid>
      </Box>
    </>
  );
};

export default WelcomeScreen;
