import { Container, useMediaQuery, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";

import Breadcrumb from "./Breadcrumb";
import TopNavBar from "./TopNavBar";

const AppBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Container
        maxWidth={false}
        disableGutters={true}
        sx={{
          top: 0,
          padding: 0,
          zIndex: 9,
          position: isMobile ? "static" : "sticky",
          marginBottom: isMobile ? "16px" : "20px",
          boxShadow: isMobile ? "none" : theme.shadows[6],
          backgroundColor: isMobile ? "transparent" : "white",
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
        }}
      >
        <TopNavBar />
      </Container>
      <Breadcrumb />
      <Outlet />
    </>
  );
};

export default AppBar;
