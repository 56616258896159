import {
  Badge,
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ReactComponent as ChatIcon } from "../../../assets/svg/chat.svg";
import { IDocument } from "../../../types";
import { DocumentsTableContent } from "./DocumentsTableContent";

interface IDocumentTable {
  data: Array<IDocument>;
}

export const DocumentTable = ({ data }: IDocumentTable) => {
  const smallDevice = useMediaQuery("(min-width: 375px)");
  return (
    <Table
      sx={{
        tableLayout: "fixed",
        maxWidth: "100%",
        marginTop: "24px",
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell
            align="left"
            sx={{ width: { xs: "25%", md: "30%", xl: "35%" } }}
          >
            <Typography variant="body2">Name</Typography>
          </TableCell>
          <TableCell
            align="center"
            sx={{ width: { xs: "20%", md: "10%", xl: "10%" } }}
          >
            <Typography
              variant="body2"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              Kategorie
            </Typography>
          </TableCell>
          {smallDevice && (
            <TableCell
              align="center"
              sx={{ width: { xs: "20%", md: "10%", xl: "10%" } }}
            >
              <Typography
                variant="body2"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                Typ
              </Typography>
            </TableCell>
          )}
          <TableCell
            align="center"
            sx={{ width: { xs: "15%", md: "10%", xl: "10%" } }}
          >
            <Typography
              variant="body2"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              Datum
            </Typography>
          </TableCell>
          <TableCell
            align="center"
            sx={{ width: { xs: "7.5%", md: "4%", xl: "8%" } }}
          >
            <Box sx={{ width: "fit-content", margin: "0 auto" }}>
              <Badge
                variant="dot"
                overlap="circular"
                color="secondary"
                sx={{
                  top: "20%",
                  right: "20%",
                  display: { xs: "inline-flex", xl: "none" },
                }}
              >
                <ChatIcon />
              </Badge>
            </Box>
            <Typography
              variant="body2"
              sx={{ display: { xs: "none", xl: "inline" } }}
            >
              Kommentare
            </Typography>
          </TableCell>
          <TableCell
            align="center"
            sx={{
              width: { xl: "5%" },
              display: { xs: "none", xl: "table-cell" },
            }}
          >
            <Typography variant="body2">Verantw.</Typography>
          </TableCell>
          <TableCell
            align="right"
            sx={{ width: { xs: "7.5%", md: "2.5%" } }}
          ></TableCell>
        </TableRow>
      </TableHead>
      <DocumentsTableContent data={data} smallDevice={smallDevice} />
    </Table>
  );
};
