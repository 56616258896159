import { Info as InfoIcon } from "@mui/icons-material";
import {
  Button as MuiButton,
  Grid as MuiGrid,
  InputAdornment as MuiInputAdornment,
  MenuItem,
  TextField as MuiTextField,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Smile from "../../../../assets/svg/user.svg";
import { updateStep } from "../../../../store/slices/onboarding";
import { IStore } from "../../../../store/types";
import { DateDisplay } from "../../../Atoms/DateDisplay";
import Container from "../Container";
import ImageContainer from "../ImageContainer";

const endIcon = (
  <MuiInputAdornment position="end">
    <InfoIcon />
  </MuiInputAdornment>
);

const Step3 = () => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState("0");
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const width = isSmallDevice ? "327px" : "440px";
  const isChild = useSelector((state: IStore) => state.onboarding.isChild);
  const goToNextStep = useCallback(() => {
    if (isChild) {
      dispatch(updateStep(4));
    } else {
      dispatch(updateStep(5));
    }
  }, [isChild]);

  return (
    <Container totalSteps={9} withBackground={false} activeStep={3}>
      <ImageContainer
        src={Smile}
        marginTopMd="86px"
        marginTopSm="122px"
        marginBottomMd="6px"
        marginBottomSm="6px"
      />
      <MuiGrid item alignItems="center" justifyContent="center">
        <MuiTypography
          align="center"
          variant="body1"
          color="rgba(61, 65, 77, 0.5)"
        >
          Herr Michael Müller
        </MuiTypography>
        <MuiTypography style={{ maxWidth: width }} align="center" variant="h6">
          Ich benötige weitere Informationen zu Deinem Partner:in.
        </MuiTypography>
      </MuiGrid>
      <MuiGrid
        sx={{ marginTop: "40px" }}
        display="flex"
        flexDirection="column"
        spacing="12px"
      >
        <MuiTextField value={address} select sx={{ width, marginTop: "12px" }}>
          <MenuItem
            onClick={() => setAddress("0")}
            value="0"
            className="SelectMenuItem"
          >
            Anrede
          </MenuItem>
          <MenuItem
            onClick={() => setAddress("1")}
            value="1"
            className="SelectMenuItem"
          >
            Anrede Anrede
          </MenuItem>
        </MuiTextField>
        <MuiGrid item>
          <MuiTextField
            style={{ width, marginTop: "12px" }}
            placeholder="Vorname "
          />
        </MuiGrid>
        <MuiGrid item>
          <MuiTextField
            style={{ width, marginTop: "12px" }}
            placeholder="Nachname"
          />
        </MuiGrid>
        <MuiTextField
          sx={{ width, marginTop: "12px" }}
          placeholder="Geburtsdatum"
          InputProps={{
            endAdornment: endIcon,
          }}
        />
        <MuiGrid item>
          <MuiTypography
            style={{ marginTop: "40px" }}
            align="center"
            variant="h6"
          >
            Wie lautet die Steuer-ID?
          </MuiTypography>
          <MuiTextField
            sx={{ width, marginTop: "12px" }}
            placeholder="DE1234567891"
            InputProps={{
              endAdornment: endIcon,
            }}
          />
          <MuiTypography
            style={{ marginTop: "40px", maxWidth: width }}
            align="center"
            variant="h6"
          >
            Seit wann sind Sie verheiratet?
          </MuiTypography>
        </MuiGrid>
        <MuiGrid style={{ marginTop: "12px" }} item>
          <DateDisplay placeholder="Geburtsdatum" />
        </MuiGrid>
        <MuiGrid style={{ paddingBottom: "80px" }} item>
          <MuiButton
            sx={{ width, marginTop: "36px" }}
            color="primary"
            onClick={goToNextStep}
          >
            weiter
          </MuiButton>
        </MuiGrid>
      </MuiGrid>
    </Container>
  );
};

export default Step3;
