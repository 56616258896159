import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as yup from "yup";
import useUpdateClient from "../../../services/fetch/useUpdateClient";
import { IStore } from "../../../store/types";
import { IClient } from "../../../types";
import StammDatenErrorMsgs from "./StammDatenErrorMsgs";

export const schema = yup
  .object({
    street: yup.string().required(),
    zipcode: yup.number().positive().integer().required(),
    place: yup.string().required(),
    email: yup.string().required(),
    website: yup.string().required(),
    phone: yup.number().required(),
  })
  .required();

const StammdatenList = () => {
  const currentClient: IClient = useSelector(
    (state: IStore) => state.currentClient
  );
  const [updateClient] = useUpdateClient();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { enqueueSnackbar } = useSnackbar();
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    reset(currentClient);
  }, [currentClient]);

  const getUpdatedClientData = (data: IClient) => {
    return {
      street: data.street,
      zipcode: data.zipcode.toString(),
      place: data.place,
      website: data.website,
      email: data.email,
      branch: data.branch,
      phone: data.phone.toString(),
    };
  };

  const onSubmit = async (data: any) => {
    try {
      const res = await updateClient({ data: getUpdatedClientData(data) });
      enqueueSnackbar(res.data.message, { variant: "success" });
    } catch (err: any) {
      enqueueSnackbar(err.response.data.message, { variant: "error" });
    }

    return null;
  };

  const onCancelForm = () => {
    reset(currentClient);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box style={{ marginBottom: 24 }}>
        <Grid container spacing={{ xs: 0 }} columnSpacing={{ md: 3 }}>
          <Grid item xs={12} md={6}>
            <Grid marginBottom="10px">
              <TextField
                fullWidth
                label="Name"
                color="secondary"
                variant="standard"
                inputProps={register("name")}
                InputProps={{ disabled: true }}
              />
            </Grid>
            <Grid marginBottom="10px">
              <TextField
                fullWidth
                label="Strasse"
                color={errors.street?.message ? "error" : "secondary"}
                variant="standard"
                inputProps={register("street")}
                InputProps={{
                  endAdornment: errors.street?.message && (
                    <InputAdornment position="end">
                      <StammDatenErrorMsgs error="street" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid marginBottom="10px">
              <TextField
                fullWidth
                label="PLZ"
                color={errors.zipcode?.message ? "error" : "secondary"}
                variant="standard"
                inputProps={register("zipcode")}
                InputProps={{
                  endAdornment: errors.zipcode?.message && (
                    <InputAdornment position="end">
                      <StammDatenErrorMsgs error="zipcode" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid marginBottom="10px">
              <TextField
                fullWidth
                label="Ort"
                color={errors.place?.message ? "error" : "secondary"}
                variant="standard"
                inputProps={register("place")}
                InputProps={{
                  disableUnderline: !isMobile,
                  endAdornment: errors.place?.message && (
                    <InputAdornment position="end">
                      <StammDatenErrorMsgs error="place" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid marginBottom="10px">
              <TextField
                fullWidth
                label="Webseite"
                color={errors.website?.message ? "error" : "secondary"}
                variant="standard"
                inputProps={register("website")}
                InputProps={{
                  endAdornment: errors.website?.message && (
                    <InputAdornment position="end">
                      <StammDatenErrorMsgs error="website" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid marginBottom="10px">
              <TextField
                fullWidth
                label="E-Mail"
                color={errors.email?.message ? "error" : "secondary"}
                variant="standard"
                disabled
                inputProps={register("email")}
                InputProps={{
                  endAdornment: errors.email?.message && (
                    <InputAdornment position="end">
                      <StammDatenErrorMsgs error="email" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid marginBottom="10px">
              <TextField
                fullWidth
                label="Telefon"
                color={errors.phone?.message ? "error" : "secondary"}
                variant="standard"
                inputProps={register("phone")}
                InputProps={{
                  disableUnderline: isMobile,
                  endAdornment: errors.phone?.message && (
                    <InputAdornment position="end">
                      <StammDatenErrorMsgs error="phone" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={onCancelForm}>Abbrechen</Button>
        <Button sx={{ marginLeft: "12px" }} type="submit" color="primary">
          Speichern
        </Button>
      </Box>
    </form>
  );
};

export default StammdatenList;
