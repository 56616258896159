import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateCurrentClient } from "../../../store/slices/currentClient";
import { IStore } from "../../../store/types";
import { IClient } from "../../../types";
import { avatarTilman } from "../../../utils";
import IconButton from "../../Atoms/IconButton";

const ProfileContainer: React.FunctionComponent<{ sx: any }> = ({ sx }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();
  const changeClient = (client: IClient) => {
    dispatch(updateCurrentClient(client));
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentClient: IClient = useSelector(
    (state: IStore) => state.currentClient
  );
  const clients: IClient[] = useSelector((state: IStore) => state.clients);
  const theme = useTheme();
  const navigate = useNavigate();

  const navigateToClientProfile = () => {
    navigate(`/clients/${currentClient?.id}`);
  };

  return (
    <Paper
      sx={{
        cursor: "pointer",
        height: "100%",
        padding: "24px",
        boxShadow: theme.shadows[6],
        ...sx,
      }}
    >
      <Stack direction={"row"} gap="10px" mb="16px">
        <img src={avatarTilman} width="24" height="24" alt="profile" />

        <Typography onClick={navigateToClientProfile} variant="h6">
          <>{currentClient?.name}</>
        </Typography>
        <IconButton
          color="primary"
          size="small"
          icon={<KeyboardArrowDownIcon />}
          onClick={handleClick}
          sx={{
            marginLeft: "auto !important",
            filter: "drop-shadow(1px 4px 3px rgb(0 0 0 / 0.4))",
            "&:disabled": {
              filter: "none",
            },
          }}
        />

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {clients.map((client) => (
            <MenuItem value={client.id} onClick={() => changeClient(client)}>
              {client.name}
            </MenuItem>
          ))}
        </Menu>
      </Stack>
      {/* <Stack onClick={navigateToClientProfile} direction={"column"}>
        <Typography variant="caption" color={"#CFCFCF"}>
          Steuer-ID
        </Typography>
        <Typography variant="body1" color={"#CFCFCF"}>
          {currentClient?.taxId ?? "Keine Angaben"}
        </Typography>
      </Stack> */}
    </Paper>
  );
};

export default ProfileContainer;
