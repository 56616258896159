import TaskIcon from "@mui/icons-material/Task";
import { Dialog, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../assets/css/theme";
import useClientTaskService from "../../../services/fetch/useClientTaskService";
import { ITask } from "../../../types";
import WidgetWrapper from "../WidgetWrapper";
import AdhocTask from "./AdhocTask";
import Task from "./Task";

const Tasks: React.FunctionComponent<{ sx: any }> = ({ sx }) => {
  const { clientTasks, readClientTasks } = useClientTaskService();
  const [adhocDialogOpen, setAdhocDialogOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const toggleAdhocTaskDialog = () => {
    setAdhocDialogOpen(!adhocDialogOpen);
  };

  const fetchTasks = async () => {
    await readClientTasks({
      filter: {
        days: 120,
        status: "ACTIVE",
        type: "",
        page: 0,
        sort: "creationDate,desc",
        size: 5,
      },
    });
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  return (
    <>
      <WidgetWrapper
        title={{
          caption: " Aktuelle Aufgaben",
          onClick: () => navigate("/taskoverview"),
        }}
        icon={<TaskIcon color="primary" />}
        highlightOnFocus={true}
        add={{ display: true, onClick: toggleAdhocTaskDialog }}
        sx={sx}
      >
        {clientTasks?.items?.length && (
          <Stack direction={"column"} spacing={2}>
            {clientTasks?.items?.map((data: ITask) => (
              <Task data={data} key={data.id}></Task>
            ))}
          </Stack>
        )}
      </WidgetWrapper>

      <Dialog
        open={adhocDialogOpen}
        onClose={toggleAdhocTaskDialog}
        PaperProps={{
          sx: {
            maxWidth: "430px",
            padding: "24px",
            border: `2px solid ${theme.palette.secondary.main}`,
          },
        }}
      >
        <AdhocTask
          toggleDialog={toggleAdhocTaskDialog}
          onTaskCreatedCompleted={fetchTasks}
        />
      </Dialog>
    </>
  );
};

export default Tasks;
