import {updateStep} from "../../../../store/slices/onboarding";
import {useDispatch} from "react-redux";
import {
	Button as MuiButton,
	Grid as MuiGrid, InputAdornment as MuiInputAdornment,
	TextField as MuiTextField,
	Typography as MuiTypography, useMediaQuery, useTheme,
} from "@mui/material";
import Smile from "../../../../assets/svg/user.svg"
import Container from "../Container";
import ImageContainer from "../ImageContainer";
import {Info as InfoIcon} from "@mui/icons-material";

const endIcon = <MuiInputAdornment position="end">
	<InfoIcon/>
</MuiInputAdornment>;

const Step5 = () => {
	const dispatch = useDispatch();

	const goToStep6 = () => {
		dispatch(updateStep(6));
	};

	const theme = useTheme();
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
	const width = isSmallDevice ? '327px' : '440px';

	return <Container totalSteps={9} withBackground={false} activeStep={5}>
		<ImageContainer
			src={Smile}
			marginTopMd="86px"
			marginTopSm="122px"
			marginBottomMd="6px"
			marginBottomSm="6px"
		/>
		<MuiGrid
			item
			alignItems="center"
			justifyContent="center"
			sx={{ marginBottom: "40px" }}
		>
			<MuiTypography align="center" variant="body1" color="rgba(61, 65, 77, 0.5)">
				Natürliche Personsname default
			</MuiTypography>
			<MuiTypography style={{maxWidth: '327px'}} align="center" variant="h6">
				Bitte tragen Sie hier noch Ihre Bankverbindung ein"
			</MuiTypography>
		</MuiGrid>
		<MuiGrid
			sx={{marginTop: '40px'}}
			display="flex"
			flexDirection="column"
			spacing="12px"
		>
			<MuiGrid item>
				<MuiTextField
					sx={{width, marginTop: '12px'}}
					placeholder="IBAN"
					InputProps={{
						endAdornment: endIcon
					}}
				/>
			</MuiGrid>
			<MuiGrid style={{ marginBottom: "80px"}} item>
				<MuiButton
					sx={{width, marginTop: "36px"}}
					color="primary"
					onClick={goToStep6}
				>
					weiter
				</MuiButton>
			</MuiGrid>
		</MuiGrid>
	</Container>
};

export default Step5;
