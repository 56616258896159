import { styled } from "@mui/material";

const Logo = styled("img")(() => ({
  top: "17px",
  left: "19px",
  width: "111px",
  zIndex: 1,
  height: "21px",
  position: "absolute",
}));

const Wrapper = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  backgroundColor: theme.palette.grey[400],
  [theme.breakpoints.up("md")]: {
    paddingTop: "8vh",
    paddingLeft: "8vw",
    paddingRight: "8vw",
    paddingBottom: "43px",
  },
}));

export interface OnboardingPageWrapperProps {
  children?: any;
}

const OnboardingPageWrapper = ({ children }: OnboardingPageWrapperProps) => {
  return (
    <Wrapper>
      <Logo src="/img/logo-milia-sm.svg" />
      {children}
    </Wrapper>
  );
};

export default OnboardingPageWrapper;
