import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    cmaxWidth: true; // adding additional Breakpoint for default container maxwidth
  }
  interface Palette {
    //Add of new Colors in the palette
    green: Palette["primary"];
    purple: Palette["primary"];
    magenta: Palette["primary"];
    blue: Palette["primary"];
    yellow: Palette["primary"];
  }
  interface PaletteOptions {
    green?: PaletteOptions["primary"];
    purple?: PaletteOptions["primary"];
    magenta?: PaletteOptions["primary"];
    blue?: PaletteOptions["primary"];
    yellow?: PaletteOptions["primary"];
  }
  interface PaletteColor {
    50?: string;
    30?: string;
    10?: string;
  }
  interface SimplePaletteColorOptions {
    50?: string;
    30?: string;
    10?: string;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    blue: true;
    yellow: true;
    green: true;
    magenta: true;
    purple: true;
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: ["Sen"].join(","),
    htmlFontSize: 16,
    fontSize: 14,
    h6: {
      fontSize: 18,
      letterSpacing: 0,
      fontWeight: "bold",
    },
    subtitle1: {
      fontSize: 18,
      letterSpacing: "0.17px",
    },
    body1: {
      fontSize: 14,
      letterSpacing: "0.17px",
    },
    body2: {
      fontSize: 12,
      letterSpacing: "0.17px",
    },
    caption: {
      fontSize: 10,
      letterSpacing: "0.17px",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 320,
      md: 744,
      lg: 1024,
      xl: 1440,
      cmaxWidth: 1248, // custom breakpoint for container maxwidth
    },
  },
  palette: {
    common: { black: "#000", white: "#FFF" },
    primary: {
      main: "#0B29F9",
      50: "#0B29F950",
      30: "#0B29F930",
      10: "#0B29F910",
    },
    secondary: {
      main: "#B7F235",
      50: "#B7F23550",
      30: "#B7F23530",
      10: "#B7F23510",
    },
    green: {
      main: "#B7F235",
      50: "#B7F23550",
      30: "#B7F23530",
      10: "#B7F23510",
      contrastText: "#fff",
    },
    purple: {
      main: "#6C0BC3",
      50: "#6C0BC350",
      30: "#6C0BC330",
      10: "#6C0BC310",
      contrastText: "#fff",
    },
    magenta: {
      main: "#FF006E",
      50: "#FF006E50",
      30: "#FF006E30",
      10: "#FF006E10",
      contrastText: "#fff",
    },
    blue: {
      main: "#2BF9E5",
      50: "#2BF9E550",
      30: "#2BF9E530",
      10: "#2BF9E510",
      contrastText: "#fff",
    },
    yellow: {
      main: "#FFD60A",
      50: "#FFD60A50",
      30: "#FFD60A30",
      10: "#FFD60A10",
      contrastText: "#fff",
    },

    error: {
      main: "#FF0000",
    },
    success: {
      main: "#B7F235",
    },
    grey: {
      50: "#FAFCFE",
      400: "#EAEAEA", // main background
      500: "#CFCFCF", // inactive text
    },
  },
  shape: {
    borderRadius: 20, // Change of default border radius for components to 20px
  },
  components: {
    // NEW

    // NEW
    MuiContainer: {
      defaultProps: {
        maxWidth: "cmaxWidth", // override maxwidth default value to custom breakpoint
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          // Common styles
          fontWeight: 400,
          lineHeight: "143%",
          letterSpacing: "0.17px",
          textTransform: "none",
          boxShadow: "0px 2px 4px rgba(42, 51, 60, 0.04)",
          // Common icon styles
          "> .MuiButton-startIcon": {
            marginLeft: "0px",
            marginRight: "4px",
          },
          "> .MuiButton-endIcon": {
            marginLeft: "4px",
            marginRight: "0px",
          },
          // Large size
          "&.MuiButton-sizeLarge": {
            // Without icons
            height: "42px",
            padding: "8px 16px",
            fontSize: "18px",
            borderRadius: "25px",
            // With icons
            "> .MuiButton-iconSizeLarge > .MuiSvgIcon-root": {
              width: "24px",
              height: "24px",
            },
            // With start icon
            "&:has(.MuiButton-startIcon)": {
              padding: "0px 16px 0px 8px",
            },
            // With end icon
            "&:has(.MuiButton-endIcon)": {
              padding: "0px 8px 0px 16px",
            },
            // With both icons
            "&:has(.MuiButton-startIcon):has(.MuiButton-endIcon)": {
              padding: "0px 14px",
            },
          },
          // Medium size
          "&.MuiButton-sizeMedium": {
            // Without icons
            height: "36px",
            padding: "8px 14px",
            fontSize: "14px",
            borderRadius: "20px",
            // With icons
            "> .MuiButton-iconSizeMedium > .MuiSvgIcon-root": {
              width: "20px",
              height: "20px",
            },
            // With start icon
            "&:has(.MuiButton-startIcon)": {
              padding: "0px 14px 0px 8px",
            },
            // With end icon
            "&:has(.MuiButton-endIcon)": {
              padding: "0px 8px 0px 14px",
            },
            // with both icons
            "&:has(.MuiButton-startIcon):has(.MuiButton-endIcon)": {
              padding: "8px",
            },
          },
          // Small size
          "&.MuiButton-sizeSmall": {
            // Without icons
            height: "32px",
            padding: "8px 12px",
            fontSize: "12px",
            borderRadius: "20px",
            // With icons
            "> .MuiButton-iconSizeSmall > .MuiSvgIcon-root": {
              width: "16px",
              height: "16px",
            },
            // With start icon
            "&:has(.MuiButton-startIcon)": {
              padding: "0px 12px 0px 6px",
            },
            // With end icon
            "&:has(.MuiButton-endIcon)": {
              padding: "0px 6px 0px 12px",
            },
            // with both icons
            "&:has(.MuiButton-startIcon):has(.MuiButton-endIcon)": {
              padding: "8px",
            },
          },
          // Hover color
          "&:hover": {
            color: "#FFFFFF",
            background: "#B7F235  ",
            boxShadow: "0px 2px 4px rgba(42, 51, 60, 0.04)",
          },
          // Disabled color
          "&:disabled": {
            boxShadow: "none",
            color: "#FFFFFF",
            background: "#EAEAEA",
          },
          // Active color
          "&:active": {
            color: "#3D414D",
            background: "#B7F235",
          },
        },
        containedSecondary: {
          color: "#3D414D",
          background: "#EAEAEA",
        },
        containedPrimary: {
          color: "#FFFFFF",
          background: "#1129F5",
        },
      },
      defaultProps: {
        size: "medium",
        type: "button",
        color: "secondary",
        variant: "contained",
        disableRipple: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: "#fff",
          textTransform: "uppercase",
          "& span": {
            padding: 0,
          },
          ".MuiChip-label": {
            fontFamily: "Sen",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "143%",
            display: "flex",
            alignItems: "center",
            letterSpacing: "0.17px",
            textTransform: "uppercase",
            color: "#3D414D",
          },
        },
        sizeSmall: {
          height: 16,
          padding: "0px 10px",
        },
        sizeMedium: {
          height: 25,
          padding: "3px 10px",
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        classes: {
          paper: "CustomSelectMenu",
        },
      },
      styleOverrides: {
        paper: {
          padding: 4,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          "&:hover": {
            backgroundColor: "#EAEAEA",
          },
        },
      },
    },
    MuiTouchRipple: {
      defaultProps: {
        style: {
          color: "#B7F235",
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        // per defautl we want to scrollthe whole page and not the dialog
        scroll: "body",
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          // Remove the padding for table cells in body first and last cell
          "& .MuiTableCell-root:first-of-type": {
            paddingLeft: 0,
          },
          "& .MuiTableCell-root:last-of-type": {
            paddingRight: 0,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          //Standard padding for tablecells down to 4px and rm of border
          padding: "8px 4px",
          borderBottom: "none",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.MuiFormLabel-colorError": {
            color: "#FF0000 !important",
            opacity: "1",
          },
        },
        standard: {
          color: "rgba(0, 0, 0, 0.87) !important",
          opacity: "0.3",
          fontSize: "10px",
          paddingTop: "4px",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "#3D414D",
            fontWeight: "700",
          },
          "> .MuiInputAdornment-root > .MuiSvgIcon-root": {
            padding: "3px",
            width: "20px",
            height: "20px",
          },
        },
        input: {
          "&::selection": {
            background: "rgba(93, 248, 151, 0.3)",
          },
        },
        //@ts-ignore
        colorError: {
          color: "#FF0000",
          "> .MuiInputAdornment-root > .MuiSvgIcon-root": {
            color: "#FF0000",
          },
        },
        colorPrimary: {
          color: "#1129F5",
        },
        colorSecondary: {
          color: "#3D414D",
          "> .MuiInputAdornment-root > .MuiSvgIcon-root": {
            color: "#3D414D",
          },
        },
        "&:disabled": {
          color: "#CFCFCF",
        },
        underline: {
          "&:before": {
            borderBottom: "1px solid #EAEAEA !important",
          },
          "&:after": {
            borderBottom: "1px solid #EAEAEA !important",
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: "1px solid #B7F235",
          },
          "&.Mui-disabled:before": {
            borderBottomStyle: "solid",
          },
          "&.MuiInputBase-colorError": {
            "&:before": {
              borderBottom: "1px solid #FF0000 !important",
            },
            "&:after": {
              borderBottom: "1px solid #FF0000 !important",
            },
            "&:hover:not(.Mui-disabled):not(.Mui-focused):before": {
              borderBottom: "1px solid #FF0000 !important",
            },
            "&.Mui-focused:after": {
              borderBottom: "1px solid #B7F235",
            },
            "&.Mui-focused:before": {
              borderBottom: "1px solid #B7F235",
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return {
            padding: "8px 16px",
            borderRadius: "20px",
            "> fieldset": {
              border: "none",
            },
            "> .MuiInputBase-inputSizeSmall": {
              fontSize: "12px",
            },
            "> .MuiInputBase-inputSizeMedium": {
              fontSize: "14px",
            },
            "> .MuiInputBase-inputSizeLarge": {
              fontSize: "14px",
            },
            "&.Mui-disabled": {
              opacity: 0.5,
              color: "#3D414D",
              border: "1px solid #EAEAEA",
              background: "rgba(234, 234, 234, 0.2)",
              "> div.MuiSelect-select": {
                color: "#EAEAEA",
                background: "rgba(234, 234, 234, 0.2)",
                "> .MuiSvgIcon-root": {
                  color: "rgba(61, 65, 77, 0.2)",
                },
              },
            },
            "> .MuiMenu-root": {
              background: "#EAEAEA",
            },
            ...(ownerState?.readOnly && {
              color: "#3D414D",
              border: "1px solid #EAEAEA",
              background: "#FFFFFF !important",
              "> .MuiInputAdornment-root > .MuiSvgIcon-root": {
                color: "#3D414D !important",
              },
            }),
          };
        },
        input: {
          padding: 0,
        },
        //@ts-ignore
        colorError: {
          color: "#FF0000",
          border: "1px solid #FF0000",
          background: "rgba(255, 0, 0, 0.2)",
          "> .MuiInputAdornment-root > .MuiSvgIcon-root": {
            color: "#FF0000",
          },
        },
        colorSuccess: {
          color: "#B7F235",
          border: "1px solid #B7F235",
          background: "rgba(234, 234, 234, 0.5)",
          "> .MuiSvgIcon-root": {
            color: "#B7F235",
          },
        },
        colorPrimary: {
          color: "#1129F5",
          border: "1px solid #1129F5",
          background: "rgba(17, 41, 245, 0.2)",
          "> .MuiInputAdornment-root > .MuiSvgIcon-root": {
            color: "#1129F5",
          },
        },
        colorSecondary: {
          color: "#3D414D",
          border: "1px solid #EAEAEA",
          background: "rgba(234, 234, 234, 0.5)",
          ".MuiSvgIcon-root": {
            color: "#1129F5",
          },
          "> div.MuiSelect-select": {
            color: "#1129F5",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "> .MuiFormHelperText-root": {
            color: "rgba(61, 65, 77, 0.2)",
            marginLeft: "0",
          },
          "> .MuiInputBase-colorSuccess": {
            color: "#B7F235",
          },
        },
      },
      defaultProps: {
        size: "medium",
        color: "secondary",
        variant: "outlined",
        InputLabelProps: {
          shrink: true,
        },
        SelectProps: {
          native: false,
          displayEmpty: true,
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            classes: {
              paper: "CustomSelectMenu",
            },
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        .CustomSelectMenu {
          box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12) !important;
          border-radius: 20px !important;
          margin-top: 17px !important;
          padding: 24px !important;
        }
        .CustomSelectMenu .MuiList-root {
          padding: 0;
        }
        .CustomSelectMenu .Mui-selected {
          background: rgba(93, 248, 151, 0.33) !important;
        }
        .CustomSelectMenu .MuiMenuItem-root:not(:last-child) {
          margin-bottom: 12px;
        }
        .CustomSelectMenu .MuiMenuItem-root {
          color: #3D414D;
          border-radius: 20px;
        }
        .CustomSelectMenu .MuiMenuItem-root:hover {
          color: #3D414D;
          background: #B7F23530;
        }
        .CustomSelectMenu .MuiTouchRipple-root {
          color: #EAEAEA !important;
        }
      `,
    },
    MuiMobileStepper: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          border: "none",
          background: "none",
          ".MuiMobileStepper-dot": {
            width: "12px",
            height: "12px",
            border: "2px solid #B7F235",
            background: "#B7F235",
            "&:not(:last-child)": {
              marginRight: "34px",
            },
          },
          ".MuiMobileStepper-dotActive ~ *": {
            border: "2px solid #CFCFCF",
            background: "none",
          },
          ...(ownerState.activeStep !== undefined &&
            ownerState.activeStep < 0 && {
              ".MuiMobileStepper-dot, .MuiMobileStepper-dotActive": {
                border: "2px solid #CFCFCF",
                background: "none",
              },
            }),
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          boxShadow:
            "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
          background: "#F5F5F5",
        },
        track: ({ ownerState }) => ({
          opacity: "0.5",
          background: "#3D414D",
          "&:before, &:after": {
            top: "42%",
            height: 6,
            content: '""',
            position: "absolute",
            backgroundRepeat: "no-repeat",
          },
          "&:after": {
            width: 6,
            right: "32%",
            backgroundImage: `url('data:image/svg+xml;utf8,<svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="3" cy="3" r="2.5" stroke="white"/></svg>')`,
          },
          "&:before": {
            width: 1,
            left: "37%",
            backgroundImage: `url('data:image/svg+xml;utf8,<svg width="1" height="6" viewBox="0 0 1 6" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="0.5" x2="0.5" y2="6" stroke="black"/></svg>')`,
          },
          ".Mui-checked.Mui-checked + &": {
            backgroundColor: "#B7F235",
          },
          //@ts-ignore
          ...((ownerState.disabled || !ownerState.withIcon) && {
            "&:before, &:after": {
              backgroundImage: "none",
            },
          }),
          ...(ownerState.disabled && {
            ".Mui-checked.Mui-checked + &": {
              backgroundColor: "#3D414D",
            },
          }),
        }),
      },
    },
    MuiRadio: {
      defaultProps: {
        size: "medium",
        color: "secondary",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: "medium",
        color: "secondary",
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: "#FFFFFF",
          backgroundColor: "#62656E",
          borderRadius: "10px",
          fontFamily: "Sen",
          fontWeight: 400,
          fontSize: "10px",
          lineHeight: "143%",
          letterSpacing: "0.17px",
          padding: "7px 12px",
        },
      },
    },
  },
});
