import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
} from "@mui/material";

export interface IFormControlLabelProps
  extends Omit<MuiFormControlLabelProps, "control"> {
  control?: any;
}

export interface ICheckboxProps extends MuiCheckboxProps {
  error?: boolean;
  control?: any;
}

const StyledMuiCheckbox = (props: ICheckboxProps) => (
  <MuiCheckbox
    disableRipple={true}
    sx={{
      background: "red",
      color: props.error
        ? "#FF0000"
        : props.size === "small"
        ? "#3D414D"
        : "rgba(0, 0, 0, 0.6)",
      width: props.size === "small" ? "15px" : "18px",
      height: props.size === "small" ? "15px" : "18px",
      padding: "4px",
      "&:hover:not(.Mui-checked)": {
        color: "#EAEAEA !important",
        background: "transparent",
      },
      "&.Mui-disabled": {
        color: "rgba(61, 65, 77, 0.2) !important",
      },
      "&.Mui-checked": {
        color: "#B7F235",
      },
      "> span > .MuiSvgIcon-fontSizeSmall": {
        width: "16px",
        height: "16px",
      },
      "> span > .MuiSvgIcon-fontSizeMedium": {
        width: "20px",
        height: "20px",
      },
    }}
    {...props}
  />
);

const Checkbox = (props: ICheckboxProps & IFormControlLabelProps) => {
  return props.label ? (
    <MuiFormControlLabel
      sx={{
        "&:has(.Mui-checked)": {
          "> .MuiTypography-root": {
            color: "#3D414D",
          },
        },
        ":has(.Mui-disabled)": {
          "> .MuiTypography-root": {
            color: "rgba(61, 65, 77, 0.2) !important",
          },
        },
        "> .MuiTypography-root": {
          fontSize: "10px",
          marginLeft: "11.5px",
          color: props.error ? "#FF0000" : "#3D414D",
          lineHeight: "14.3px",
          letterSpacing: "0.17px",
        },
        ...(!props.disabled && {
          "&:hover > .MuiTypography-root, &:hover > .MuiCheckbox-root:not(.Mui-checked)":
            {
              color: "#EAEAEA !important",
            },
        }),
      }}
      {...props}
      control={<StyledMuiCheckbox {...props} />}
    />
  ) : (
    <StyledMuiCheckbox {...props} />
  );
};

export default Checkbox;
