import { withAuthenticationRequired } from "@auth0/auth0-react";
import { CssBaseline } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { theme } from "./assets/css/theme";
import { AppBar } from "./components/Atoms/AppBar";
import { LoadingAnimation } from "./components/Atoms/LoadingAnimation";
import ClientProfile from "./pages/ClientProfile";
import Dashboard from "./pages/Dashboard";
import DocumentDetail from "./pages/DocumentDetail";
import DocumentOverview from "./pages/DocumentOverview";
import Onboarding from "./pages/Onboarding";
import RoleDetail from "./pages/RoleDetail";
import SignedSucess from "./pages/SignedSucess";
import StartTask from "./pages/StartTask";
import TaskOverview from "./pages/TaskOverview";
import TaskOverviewInsert from "./pages/TaskOverviewInsert";
import UserProfile from "./pages/UserProfile";
import useReadUser from "./services/fetch/useReadUser";
import useReadUsersClients from "./services/fetch/useReadUserClients";
import { updateCurrentClient } from "./store/slices/currentClient";
import { IStore } from "./store/types";
import { IClient } from "./types";

const App = (props: any) => {
  const isOnboard = useSelector((state: IStore) => state.onboarding.isOnboard);
  const currentClient: IClient = useSelector(
    (state: IStore) => state.currentClient
  );
  const dispatch = useDispatch();
  const [readUsersClients] = useReadUsersClients();
  const [readUser] = useReadUser();

  const hydrateUserData = async () => {
    await readUser();
    const userClients = await readUsersClients();

    // get current selected client
    const currentClientId = localStorage.getItem("currentClientId");
    if (currentClientId) {
      const currentClient = userClients.find(
        (client) => client.id === parseInt(currentClientId, 10)
      );
      if (currentClient) {
        dispatch(updateCurrentClient(currentClient));
        return;
      }
    }

    localStorage.setItem("currentClientId", `${userClients[0].id}`);
    dispatch(updateCurrentClient(userClients[0]));
  };

  React.useEffect(() => {
    hydrateUserData();
  }, []);

  if (isOnboard) {
    return (
      <>
        <Onboarding />
      </>
    );
  }

  if (!currentClient.id) {
    return (
      <>
        <LoadingAnimation />
      </>
    );
  }

  return (
    <div
      style={{
        backgroundColor: theme.palette.grey[400],
        minHeight: "100vh",
        paddingBottom: "0.5rem",
      }}
    >
      <CssBaseline />
      <Routes>
        <Route path="/signed-sucess" element={<SignedSucess />} />
        <Route path="/" element={<AppBar />}>
          <Route index element={<Dashboard />} />
          <Route path="/clients/:clientId" element={<ClientProfile />} />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/role-detail" element={<RoleDetail />} />
          <Route path="/task/:taskId" element={<StartTask />} />
          <Route path="/documentoverview" element={<DocumentOverview />} />
          <Route path="/document/:documentId" element={<DocumentDetail />} />
          <Route path="/taskoverview" element={<TaskOverview />} />
          <Route path="/taskoverviewinsert" element={<TaskOverviewInsert />} />
          <Route path="*" element={<div>Some problems</div>} />
        </Route>
      </Routes>
    </div>
  );
};

export default withAuthenticationRequired(App, {
  onRedirecting: () => <LoadingAnimation />,
});
