import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { updateClients } from "../../store/slices/clients";
import { IClient } from "../../types";
import { useFetchData } from "../fetchData";

const useReadUserClients = () => {
  const dispatch = useDispatch();
  const { fetchData } = useFetchData();
  const { user } = useAuth0();
  const readUsersClients = async (): Promise<IClient[]> => {
    const userId = user!.sub!.split("|")[1];
    const res = await fetchData(`users/${userId}/clients`);
    // get all client data
    const clients: Promise<IClient[]> = Promise.all(
      res.data.map(async (client: any) => {
        const clientData = await fetchData(
          `users/${userId}/clients/${client.id}`
        );
        return clientData.data;
      })
    );
    const clientData = await clients;
    console.log(clientData);
    dispatch(updateClients(clientData));
    return res.data;
  };

  return [readUsersClients];
};

export default useReadUserClients;
