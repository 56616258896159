import {Box, Modal, Paper, Typography as MuiTypography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ImageContainer from "../ImageContainer";
import HelperImage from "../../../../assets/images/card.png";
import BankImage from "../../../../assets/images/bank.png";
import React from "react";

export interface Props {
	open?: boolean,
	setOpen?: Function,
}

const Bank = ({
								open = false, setOpen = () => {
	}
							}: Props) => {
	return <Modal
		open={open}
		onClose={() => setOpen(false)}
		aria-labelledby="parent-modal-title"
		aria-describedby="parent-modal-description"
		style={styles.containerModal}
	>
		<Paper
			style={{
				width: '327px',
				backgroundColor: 'white',
				borderRadius: '20px',
				padding: '24px',
				overflow: 'auto',
			}}>
			<Box style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-between'}}>
				<MuiTypography id="modal-modal-title" variant="h6"
											 style={{fontSize: '18px'}}>
					Bitte geben Sie Ihre Bankverbindung an.
				</MuiTypography>
				<CloseIcon color="primary" onClick={() => setOpen(false)}/>
			</Box>
			<Box>
				<ImageContainer
					src={HelperImage}
					marginTopMd="20px"
					marginTopSm="20px"
				/>
				<MuiTypography>
					Ihre IBAN finden Sie auf Ihrer EC-Karte oder in Ihrem Online-Banking Portal. Im Zweifel
					kontaktieren Sie direkt Ihre Bank, um die IBAN zu erhalten.
				</MuiTypography>
				<ImageContainer
					src={BankImage}
					marginTopMd="20px"
					marginTopSm="20px"
				/>
				<MuiTypography>
					Eine Registernummer ist eine einzigartige Nummer für ein im Handelsregister / Firmenbuch
					eingetragenes Unternehmen. Diese Nummer wird bei Unternehmensformen GmbH & UG benötigt und
					kann dem Handelsregisterauszug / Firmenbuchauszug entnommen werden.
				</MuiTypography>
			</Box>
		</Paper>
	</Modal>
};

const styles = {
	containerModal: {
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		flex: 1,
		overflow: 'scroll',
		height: '100%',
	},
}

export default Bank
