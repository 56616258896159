import { InfoTag } from "./components";
import { ReactNode } from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import { SnackbarOrigin, SnackbarProvider as BaseSnackbarProvider } from "notistack";

export const MAX_SNACKBAR = 3;
export const SNACKBAR_DURATION = 3000;
export const SNACKBAR_ORIGIN_MOBILE: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
}
export const SNACKBAR_ORIGIN_TABLET: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}

const SnackbarProvider = ({ children }: { children: ReactNode | ReactNode[]}) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <BaseSnackbarProvider
      maxSnack={MAX_SNACKBAR}
      anchorOrigin={isMobile ? SNACKBAR_ORIGIN_MOBILE : SNACKBAR_ORIGIN_TABLET}
      autoHideDuration={SNACKBAR_DURATION}
      Components={{
        info: InfoTag,
        error: InfoTag,
        success: InfoTag,
        warning: InfoTag,
        default: InfoTag,
      }}
    >
      {children}
    </BaseSnackbarProvider>
  )
}

export default SnackbarProvider;
