import { Box, BoxProps, styled } from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({
  boxShadow: theme.shadows[6],
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  backgroundColor: theme.palette.common.white,
}));

const BoxMilia = (props: BoxProps) => {
  return <StyledBox {...props}>{props.children}</StyledBox>;
};

export default BoxMilia;
