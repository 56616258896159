import { useEnv } from "../EnvProvider";
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

interface IChild {
  children: any;
}

const Auth0ReactProvider = ({ children }: IChild) => {
  const navigate = useNavigate();
  const { domain, clientId, audience } = useEnv();

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname, { replace: true });
  };

  if (!(domain && clientId && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ReactProvider;
