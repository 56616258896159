import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box as MuiBox,
  Button as MuiButton,
  Grid as MuiGrid,
  InputAdornment as MuiInputAdornment,
  TextField as MuiTextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { useFetchData } from "../../../services/fetchData";
import { IStore } from "../../../store/types";
import { IUser } from "../../../types";
import { getFullDate } from "../../../utils";
import UserErrorMsgs from "./UserErrorMsgs";

export const schema = yup
  .object({
    avatarURL: yup.string(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    jobTitle: yup.string().required(),
    phone: yup.number().required(),
  })
  .required();

const UserList = () => {
  const user: IUser = useSelector((state: IStore) => state.user);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { fetchData } = useFetchData();
  const { enqueueSnackbar } = useSnackbar();
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    reset(user);
  }, [user]);

  const getUpdatedUserData = (data: IUser) => {
    return {
      avatarURL: "testData",
      firstName: data.firstName,
      lastName: data.lastName,
      jobTitle: data.jobTitle,
      phone: data.phone.toString(),
    };
  };

  const onSubmit = async (data: any) => {
    const options = {
      method: "PUT",
      data: JSON.stringify(getUpdatedUserData(data)),
    };

    try {
      const res = await fetchData("users/1", options);

      enqueueSnackbar(res.data.message, { variant: "success" });
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: "error" });
    }

    return null;
  };

  const onCancelForm = () => {
    reset(user);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MuiBox style={{ marginBottom: 24 }}>
        <MuiGrid container spacing={{ xs: 0 }} columnSpacing={{ md: 3 }}>
          <MuiGrid item xs={12} md={6}>
            <MuiGrid marginBottom="10px">
              <MuiTextField
                fullWidth
                label="Vorname"
                color={errors.firstName?.message ? "error" : "secondary"}
                variant="standard"
                inputProps={register("firstName")}
                InputProps={{
                  endAdornment: errors.firstName?.message && (
                    <MuiInputAdornment position="end">
                      <UserErrorMsgs error="firstName" />
                    </MuiInputAdornment>
                  ),
                }}
              />
            </MuiGrid>
            <MuiGrid marginBottom="10px">
              <MuiTextField
                fullWidth
                label="Nachname"
                color={errors.lastName?.message ? "error" : "secondary"}
                variant="standard"
                inputProps={register("lastName")}
                InputProps={{
                  endAdornment: errors.lastName?.message && (
                    <MuiInputAdornment position="end">
                      <UserErrorMsgs error="lastName" />
                    </MuiInputAdornment>
                  ),
                }}
              />
            </MuiGrid>
            <MuiGrid marginBottom={isMobile ? "10px" : 0}>
              <MuiTextField
                fullWidth
                label="Position"
                color={errors.jobTitle?.message ? "error" : "secondary"}
                variant="standard"
                inputProps={register("jobTitle")}
                InputProps={{
                  disableUnderline: !isMobile,
                  endAdornment: errors.jobTitle?.message && (
                    <MuiInputAdornment position="end">
                      <UserErrorMsgs error="jobTitle" />
                    </MuiInputAdornment>
                  ),
                }}
              />
            </MuiGrid>
          </MuiGrid>
          <MuiGrid item xs={12} md={6}>
            <MuiGrid marginBottom="10px">
              <MuiTextField
                fullWidth
                label="E-Mail"
                color="secondary"
                variant="standard"
                value={user?.email}
                InputProps={{ disabled: true }}
              />
            </MuiGrid>
            <MuiGrid marginBottom="10px">
              <MuiTextField
                fullWidth
                label="Telefon"
                color={errors.phone?.message ? "error" : "secondary"}
                variant="standard"
                inputProps={register("phone")}
                InputProps={{
                  endAdornment: errors.phone?.message && (
                    <MuiInputAdornment position="end">
                      <UserErrorMsgs error="phone" />
                    </MuiInputAdornment>
                  ),
                }}
              />
            </MuiGrid>
            <MuiGrid>
              <MuiTextField
                fullWidth
                label="milia Nutzer seit"
                color="secondary"
                variant="standard"
                value={getFullDate(user?.clientSince)}
                InputProps={{
                  disabled: true,
                  disableUnderline: true,
                }}
              />
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      </MuiBox>
      <MuiBox
        sx={{
          display: 'flex',
          justifyContent: "flex-end",
        }}>
        <MuiButton onClick={onCancelForm}>Abbrechen</MuiButton>
        <MuiButton sx={{ marginLeft: "12px" }} type="submit" color="primary">
          Speichern
        </MuiButton>
      </MuiBox>
    </form>
  );
};

export default UserList;
