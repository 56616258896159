import { MenuItem, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useClientDocumentService from "../../../services/fetch/useClientDocumentService";
import ChipSlider from "../../Atoms/ChipSlider";
import { CategoryTranslation, TypeTranslation } from "./translations";

interface IDocumentFilter {
  setFilter: any;
  filter: any;
}

export const DocumentFilter = (props: IDocumentFilter) => {
  const { readClientDocumentsFilter, clientDocumentsFilter } =
    useClientDocumentService();
  const { setFilter, filter } = props;
  const [days, setDays] = useState<string>("10");
  const [category, setCategory] = useState<string>("Alle");
  const [chipData, setChipData] = useState<Array<string>>([]);
  // const [documentsFilter, setDocumentFilter] = useState<any>();

  useEffect(() => {
    const fetchDocumentsFilter = async () => {
      await readClientDocumentsFilter();
    };
    fetchDocumentsFilter();
  }, []);

  const handleDaysChange = (e: any) => {
    setDays(e.target.value);

    setFilter((old: any) => ({ ...old, days: e.target.value }));
  };

  const handleCategorieChange = (e: any) => {
    let category = e.target.value;
    setCategory(category);
    setChipData(
      clientDocumentsFilter.filters[1].subFilters.type.values[category]
    );
    if (category === "Alle") category = "";
    setFilter((old: any) => ({
      ...old,
      category: category,
      type: "",
      page: 0,
    }));
  };
  const handleChipSelectionChange = (e: any) => {
    const key: any = Object.keys(TypeTranslation)
      .find(
        (key: any) =>
          TypeTranslation[key].toLowerCase() ===
          e.target.outerText.toLowerCase()
      )
      ?.toString();
    const type = filter.type === key ? "" : key;
    setFilter((old: any) => ({ ...old, type: type, page: 0 }));
  };

  return (
    <Stack direction="column" gap="16px">
      <Stack direction="row" gap="8px" flexWrap="wrap">
        <Stack direction="column" gap="4px">
          <Typography variant="body2">Kategorie</Typography>
          <TextField select value={category} onChange={handleCategorieChange}>
            <MenuItem value={"Alle"}>Alle</MenuItem>
            {clientDocumentsFilter?.filters[1].values?.map((item: any) => (
              <MenuItem value={item} key={item}>
                {CategoryTranslation.hasOwnProperty(item)
                  ? CategoryTranslation[item]
                  : item}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Stack direction="column" gap="4px">
          <Typography variant="body2">Zeitraum</Typography>
          {
            clientDocumentsFilter?.filters.length > 0 &&
              <TextField select value={days} onChange={handleDaysChange}>
                {clientDocumentsFilter?.filters[0].values?.map((item: any) => (
                  <MenuItem value={item} key={item}>
                    {`${item} Tage`}
                  </MenuItem>
                ))}
              </TextField>
          }
        </Stack>
      </Stack>
      <ChipSlider
        data={chipData}
        onChange={handleChipSelectionChange}
        translation={TypeTranslation}
      ></ChipSlider>
    </Stack>
  );
};
