import {
  Grid as MuiGrid,
  MobileStepper as MuiMobileStepper,
  Paper as MuiPaper,
  styled,
  Theme,
  useMediaQuery,
} from "@mui/material";
import OnboardingBgMd from "../../../assets/images/onboardingBgMd.png";
import OnboardingBgSm from "../../../assets/images/onboardingBgSm.png";

const Logo = styled("img")(() => ({
  top: "17px",
  left: "19px",
  width: "111px",
  zIndex: 1,
  height: "21px",
  position: "absolute",
}));

interface PaperProps {
  withBackground?: boolean;
}

const Paper = styled(MuiPaper)<PaperProps>(({ theme, withBackground }) => ({
  position: "relative",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top right",
  [theme.breakpoints.down("md")]: {
    minHeight: "inherit",
    width: "100%",
    borderRadius: 0,
    backgroundImage: withBackground ? `url(${OnboardingBgSm})` : "none",
  },
  [theme.breakpoints.up("md")]: {
    minWidth: "85vw",
    backgroundImage: withBackground ? `url(${OnboardingBgMd})` : "none",
  },
}));

const Wrapper = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  backgroundColor: theme.palette.grey[400],
  [theme.breakpoints.up("md")]: {
    paddingTop: "8vh",
    paddingLeft: "8vw",
    paddingRight: "8vw",
    paddingBottom: "43px",
  },
}));

interface ProgressBarProps {
  activeStep?: number;
  totalSteps?: number;
}

const BottomProgressBar = ({
  activeStep = -1,
  totalSteps = 6,
}: ProgressBarProps) => (
  <MuiMobileStepper
    steps={totalSteps}
    variant="dots"
    position="static"
    activeStep={activeStep - 1}
    backButton={null}
    nextButton={null}
    sx={{
      bottom: "34px",
      padding: 0,
      position: "absolute",
    }}
  />
);

const TopProgressBar = ({
  activeStep = -1,
  totalSteps = 6,
}: ProgressBarProps) => (
  <MuiMobileStepper
    steps={totalSteps}
    variant="dots"
    position="static"
    activeStep={activeStep - 1}
    backButton={null}
    nextButton={null}
    sx={{
      top: "70px",
      padding: 0,
      position: "absolute",
    }}
  />
);

export interface ContainerProps {
  children?: any;
  activeStep?: number;
  withBackground?: boolean;
  totalSteps?: number;
}

const Container = ({
  children,
  activeStep,
  withBackground,
  totalSteps,
}: ContainerProps) => {
  const isMdScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );

  return (
    <Wrapper>
      <Logo src="/img/logo-milia-sm.svg" />
      <Paper withBackground={withBackground} sx={{ height: "fit-content" }}>
        <MuiGrid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ position: "relative" }}
        >
          {!isMdScreen && activeStep && (
            <TopProgressBar totalSteps={totalSteps} activeStep={activeStep} />
          )}
          {children}
          {isMdScreen && activeStep && (
            <BottomProgressBar
              totalSteps={totalSteps}
              activeStep={activeStep}
            />
          )}
        </MuiGrid>
      </Paper>
    </Wrapper>
  );
};

export default Container;
