import { Box, Button, Dialog, Stack, Typography } from "@mui/material";

interface IProps {
  isOpen: boolean;
  toggleOpen: any;
  onSubmit: () => void;
  onCancel: () => void;
  title: string;
  submitText: string;
  cancelText: string;
}

const ConfirmDialog = ({
  isOpen,
  onSubmit,
  onCancel,
  title,
  submitText,
  cancelText,
  toggleOpen,
}: IProps) => {
  return (
    <Dialog open={isOpen} onClose={toggleOpen} maxWidth="sm">
      <Box p={3}>
        <Typography textAlign="center">{title}</Typography>
        <Stack direction="row" gap={1.5} justifyContent="center" mt={3}>
          <Button onClick={onCancel}>{cancelText}</Button>
          <Button
            color="primary"
            sx={{
            boxShadow: "0px 4px 8px -2px rgba(42, 51, 60, 0.15), 0px 6px 16px -2px rgba(42, 51, 60, 0.06)",
          }} onClick={onSubmit}>
            {submitText}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default ConfirmDialog;
