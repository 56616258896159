import {
  Box as MuiBox,
  Button as MuiButton,
  Grid as MuiGrid,
  MenuItem,
  Modal as MuiModal,
  Slider as MuiSlider,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Company from "../../../../assets/images/company.png";
import { updateStep } from "../../../../store/slices/onboarding";
import { IStore } from "../../../../store/types";
import Checkbox from "../../../Atoms/Checkbox";
import { DateDisplay } from "../../../Atoms/DateDisplay";
import Container from "../Container";
import ImageContainer from "../ImageContainer";
import { arrayButton } from "./const";

const Step7 = () => {
  const dispatch = useDispatch();
  const nameType = useSelector((state: IStore) => state.onboarding.nameType);

  const goToStep8 = () => {
    dispatch(updateStep(8));
  };

  const goBackStep6 = () => {
    dispatch(updateStep(6));
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [address, setAddress] = useState("0");
  const title = arrayButton[nameType - 1].title;

  return (
    <Container withBackground={false} activeStep={6}>
      <ImageContainer
        src={Company}
        marginTopMd="86px"
        marginTopSm="122px"
        marginBottomMd="6px"
        marginBottomSm="6px"
      />
      <MuiGrid item alignItems="center" justifyContent="center">
        <MuiTypography
          align="center"
          variant="body1"
          color="rgba(61, 65, 77, 0.5)"
        >
          Unternehmensname default
        </MuiTypography>
        <MuiTypography align="center" variant="h6" sx={{ marginTop: "10px" }}>
          {title}
        </MuiTypography>
      </MuiGrid>
      <MuiGrid sx={{ marginTop: "40px" }}>
        <MuiTextField value={address} select sx={{ width: "327px" }}>
          <MenuItem
            onClick={() => setAddress("0")}
            value="0"
            className="SelectMenuItem"
          >
            Anrede
          </MenuItem>
          <MenuItem
            onClick={() => setAddress("1")}
            value="1"
            className="SelectMenuItem"
          >
            Anrede Anrede
          </MenuItem>
        </MuiTextField>
      </MuiGrid>
      <MuiGrid>
        <MuiTextField
          sx={{ width: "327px", marginTop: "12px" }}
          placeholder="Vorname"
        />
      </MuiGrid>
      <MuiGrid>
        <MuiTextField
          sx={{ width: "327px", marginTop: "12px" }}
          placeholder="Nachname"
        />
      </MuiGrid>
      <MuiGrid sx={{ width: "327px", marginTop: "12px" }}>
        <DateDisplay placeholder="Geburtsdatum" />
      </MuiGrid>
      <MuiGrid>
        <MuiTextField
          sx={{ width: "327px", marginTop: "60px" }}
          placeholder="Straße & Hausnummer"
        />
      </MuiGrid>
      <MuiGrid
        style={{ margin: "0px" }}
        display="flex"
        flexDirection="column"
        spacing="12px"
      >
        <MuiGrid marginTop="12px">
          <MuiTextField
            sx={{ width: "226px", marginRight: "12px" }}
            placeholder="Ort"
          />
          <MuiTextField sx={{ width: "88px" }} placeholder="PLZ" />
        </MuiGrid>
      </MuiGrid>
      <MuiGrid>
        <MuiTextField
          sx={{ width: "327px", marginTop: "12px" }}
          placeholder="E-mail"
        />
      </MuiGrid>
      <MuiGrid>
        <MuiTextField
          sx={{ width: "327px", marginTop: "12px" }}
          placeholder="Telefon"
        />
      </MuiGrid>
      <MuiGrid style={{ width: "327px" }} marginTop="12px" marginLeft="45px">
        <Checkbox label="Person ist gleichzeitig Geschäftsführer" />
      </MuiGrid>
      <MuiGrid style={{ width: "327px" }} marginTop="40px">
        <MuiTypography color="black">
          Gesellschafteranteil in Prozent
        </MuiTypography>
      </MuiGrid>
      <MuiGrid style={{ width: "327px" }} marginTop="10px">
        <MuiSlider
          size="small"
          defaultValue={15}
          aria-label="Small"
          valueLabelDisplay="auto"
        />
      </MuiGrid>
      <MuiGrid sx={{ width: "327px", marginTop: "40px" }}>
        <DateDisplay placeholder="Gesellschaftsverhältnis seit: " />
      </MuiGrid>
      <MuiGrid item>
        <MuiButton
          sx={{ width: "327px", marginTop: "40px", marginBottom: "86px" }}
          color="primary"
          onClick={() => setOpen(true)}
        >
          weiter
        </MuiButton>
      </MuiGrid>
      <MuiModal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={styles.containerModal}
      >
        <MuiBox
          style={{
            width: "340px",
            backgroundColor: "white",
            borderRadius: "20px",
            padding: "24px",
          }}
        >
          <MuiTypography
            id="modal-modal-title"
            variant="subtitle1"
            style={{ textAlign: "center", fontSize: "18px" }}
          >
            Möchten Sie weitere Personen hinzufügen?
          </MuiTypography>
          <MuiBox
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 24,
            }}
          >
            <MuiButton
              sx={{ width: "95px" }}
              color="primary"
              onClick={goBackStep6}
            >
              ja
            </MuiButton>
            <MuiButton
              sx={{ width: "95px", marginLeft: "10px" }}
              color="secondary"
              onClick={goToStep8}
            >
              nein
            </MuiButton>
          </MuiBox>
        </MuiBox>
      </MuiModal>
    </Container>
  );
};

const styles = {
  containerModal: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flex: 1,
  },
};

export default Step7;
