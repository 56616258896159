import { faker } from "@faker-js/faker";
import { useEffect } from "react";
import { useFetchData } from "../services/fetchData";

const TaskOverviewInsert = () => {
  const { fetchData } = useFetchData();

  useEffect(() => {
    const insertTasks = () => {
      const createTasks = () => {
        return {
          bpmId: "12312341",
          status: "ACTIVE",
          name: faker.lorem.words(5),
          description: faker.lorem.sentence(6),
          creationDate: "2022-12-01 12:12:12",
          nameOfMatter: "VORGANG",
          type: "UPLOAD_TASK",
          deadlineDate: "2023-12-01 12:12:12",
          documentGroupCount: 5,
          userId: 1,
          payload: {
            docusignUrl: "url",
            otherStuff: "othen",
          },
        };
      };

      const options = {
        method: "POST",
        data: JSON.stringify(createTasks()),
      };

      fetchData("clients/1/tasks", options).then((res) => console.log(res));
    };

    insertTasks();
  }, []);

  return <div>Inserted</div>;
};

export default TaskOverviewInsert;
