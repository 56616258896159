import {updateIsOnboard, updateStep} from "../../../../store/slices/onboarding";
import {useDispatch} from "react-redux";
import {
	Grid as MuiGrid,
	Button as MuiButton,
	Typography as MuiTypography, useTheme, useMediaQuery,
} from "@mui/material";
import Container from "../Container";
import ImageContainer from "../ImageContainer";
import Daumen from "../../../../assets/images/daumen.png";

const Step11 = () => {
	const dispatch = useDispatch();

	const goToStep12 = () => {
		dispatch(updateStep(12));
	};

	const goToOnBoarding = () => {
		dispatch(updateIsOnboard(false));
	}

	const theme = useTheme();
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
	const width = isSmallDevice ? '327px' : '440px';

	return <Container withBackground>
		<ImageContainer src={Daumen}/>
		<MuiGrid
			item
			alignItems="center"
			justifyContent="center"
			sx={{ marginBottom: "40px" }}
		>
			<MuiTypography sx={{maxWidth: '388px'}} align="center" variant="h6">
				Ihre Stammdatenerfassung ist fertig!
			</MuiTypography>
			<MuiTypography sx={{maxWidth: '388px', marginTop: '24px'}} align="center" paragraph
										 variant="h6">
				Wilkommen zu Ihrem Dashboard und genießen Sie die neue Art der Steuerberatung.
			</MuiTypography>
		</MuiGrid>
		<MuiGrid>
			<MuiButton
				sx={{width, marginTop: '64px'}}
				color="primary"
				onClick={goToOnBoarding}
			>
				fertig
			</MuiButton>
			<MuiTypography onClick={goToStep12}
										 sx={{color: '#1129F5', marginTop: '8px', paddingBottom: '32px'}} align="center"
										 variant="body1">
				Ich habe noch Fragen.
			</MuiTypography>
		</MuiGrid>
	</Container>
};

export default Step11;
