import {
  Button as MuiButton,
  Grid as MuiGrid,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import Daumen from "../../../../assets/images/daumen.png";
import { updateStep } from "../../../../store/slices/onboarding";

import Container from "../Container";
import ImageContainer from "../ImageContainer";

const WelcomeScreen = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));

  const goToStep1 = () => {
    dispatch(updateStep(1));
  };

  return (
    <Container withBackground>
      <ImageContainer src={Daumen} />
      <MuiTypography align="center" variant="h6">
        Hi, ich bin milia!
      </MuiTypography>
      <MuiTypography
        align="center"
        variant="body2"
        paragraph
        marginBottom="20px"
      >
        Sie können nun mit dem Onboarding in <br />
        Ihrer Kanzlei der Zukunft starten. <br />
        Folgen Sie einfach den Schritten, <br />
        die ich Ihnen aufzeige.
      </MuiTypography>
      <MuiGrid
        sx={{
          width: isSmallDevice ? "327px" : "440px",
          marginBottom: isSmallDevice ? "0px" : "57px",
        }}
      >
        <MuiButton sx={{ width: "100%" }} color="primary" onClick={goToStep1}>
          Los gehts
        </MuiButton>
      </MuiGrid>
    </Container>
  );
};

export default WelcomeScreen;
