import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  clients,
  currentClient,
  group,
  onboarding,
  task,
  user,
} from "./slices";

const reducer = combineReducers({
  user,
  task,
  group,
  currentClient,
  clients,
  onboarding,
});

const store = configureStore({ reducer });

export default store;
