import { Typography } from "@mui/material";
import { ITask } from "../../../types";

interface IProps {
  data: ITask;
}

const TaskInfo = (props: IProps) => {
  const { name, description } = props.data;
  return (
    <>
      <Typography variant="h6">{name}</Typography>
      <Typography variant="body1">{description}</Typography>
    </>
  );
};

export default TaskInfo;
