import { updateStep } from "../../../../store/slices/onboarding";
import { useDispatch } from "react-redux";
import {
	Button as MuiButton,
	Grid as MuiGrid, MenuItem,
	TextField as MuiTextField,
	Typography as MuiTypography, useMediaQuery, useTheme,
} from "@mui/material";
import Company from "../../../../assets/images/company.png";
import Container from "../Container";
import ImageContainer from "../ImageContainer";
import {useState} from "react";

const Step7 = () => {
	const dispatch = useDispatch();

	const goToStep8 = () => {
		dispatch(updateStep(8));
	};

	const theme = useTheme();
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
	const width = isSmallDevice ? '327px' : '440px';
	const [country, setCountry] = useState('0');

	return <Container totalSteps={9} withBackground={false} activeStep={6}>
		<ImageContainer
			src={Company}
			marginTopMd="86px"
			marginTopSm="122px"
			marginBottomMd="6px"
			marginBottomSm="6px"
		/>
		<MuiGrid
			item
			alignItems="center"
			justifyContent="center"
			sx={{ marginBottom: "40px" }}
		>
			<MuiTypography align="center" variant="body1" color="rgba(61, 65, 77, 0.5)">
				Unternehmensname default
			</MuiTypography>
			<MuiTypography align="center" variant="h6">
				Bitte geben Sie <br/>
				Ihre Firmenanschrift ein.
			</MuiTypography>
		</MuiGrid>
		<MuiGrid
			style={{ margin: '0px' }}
			display="flex"
			flexDirection="column"
			spacing="12px"
		>
			<MuiGrid item>
				<MuiTextField style={{ width: isSmallDevice ? '221px' : "334px" }} placeholder="Straße"/>
				<MuiTextField sx={{ width: "98px", marginLeft: '8px' }} placeholder="Hausnr"/>
			</MuiGrid>
			<MuiGrid item marginTop="12px">
				<MuiTextField sx={{ width: "98px" }} placeholder="PLZ"/>
				<MuiTextField sx={{ width: isSmallDevice ? '221px' : "334px", marginLeft: '8px' }} placeholder="Ort"/>
			</MuiGrid>
			<MuiGrid item marginTop="12px" marginBottom="40px">
				<MuiTextField value={country} select sx={{ width }}>
					<MenuItem onClick={() => setCountry('0')} value="0" className="SelectMenuItem">Deutschland</MenuItem>
					<MenuItem onClick={() => setCountry('1')} value="1" className="SelectMenuItem">Poland</MenuItem>
				</MuiTextField>
			</MuiGrid>
			<MuiGrid
				item
				sx={{ width, marginBottom: "86px" }}
			>
				<MuiButton
					sx={{ width: "100%" }}
					color="primary"
					onClick={goToStep8}
				>
					weiter
				</MuiButton>
			</MuiGrid>
		</MuiGrid>
	</Container>
};

export default Step7;
