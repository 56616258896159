import { createSlice } from "@reduxjs/toolkit";
import { IClient } from "../../types";

const initialState: IClient[] | {} = {};

const clients = createSlice({
  name: "clients",
  initialState,
  reducers: {
    updateClients(state, action) {
      return action.payload;
    },
  },
});

export const { updateClients } = clients.actions;

export default clients.reducer;
