import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useClientDocumentService, {
  IDocumentFilter,
} from "../../../services/fetch/useClientDocumentService";
import { IDocument } from "../../../types";
import { DocumentFilter } from "../DocumentOverview/DocumentFilter";
import WidgetWrapper from "../WidgetWrapper";
import { DocumentTable } from "./DocumentTable";

export const DashboardDocumentOverview = ({ sx, onFocus }: any) => {
  const [filter, setFilter] = useState<IDocumentFilter>({
    size: 6,
    page: 0,
    days: 10,
    category: "",
    type: "",
    sort: "creationDate,desc",
  });
  const { readClientDocuments, clientDocuments } = useClientDocumentService();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<Array<IDocument>>([]);
  const [parentWidth, setParentWidth] = useState<any>(0);

  const CheckWidthOfParent = () => {
    const handleResize = () => {
      const documentBox = document.querySelector("#document-box");
      setParentWidth(documentBox?.clientWidth);
    };
    useEffect(() => {
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  };
  CheckWidthOfParent();
  useEffect(() => {
    const fetchDocuments = async () => {
      await readClientDocuments({ filter });
    };
    fetchDocuments();
  }, [filter]);

  // TODO: move this into one
  useEffect(() => {
    if (clientDocuments?.items) {
      if (filter.page === 0 || !tableData?.length) {
        setTableData(clientDocuments.items);
      } else {
        setTableData((values: any) => [...values, ...clientDocuments.items]);
      }
    } else {
      setTableData([]);
    }
  }, [clientDocuments]);

  return (
    <WidgetWrapper
      sx={sx}
      title={{
        caption: "Dokumente",
        onClick: () => navigate("/documentoverview"),
      }}
      icon={<InsertDriveFileRoundedIcon color="primary" />}
      highlightOnFocus={true}
      add={{ display: true, disabled: true }}
    >
      <Stack direction="column" height="100%">
        <DocumentFilter filter={filter} setFilter={setFilter} />
        <DocumentTable data={tableData} parentWidth={parentWidth} />
      </Stack>
    </WidgetWrapper>
  );
};
