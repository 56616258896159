import { updateStep } from "../../../../store/slices/onboarding";
import { useDispatch } from "react-redux";
import {
  useTheme,
  useMediaQuery,
  Grid as MuiGrid,
  Button as MuiButton,
  Typography as MuiTypography,
} from "@mui/material";
import Daumen from "../../../../assets/images/daumen.png";

import Container from "../Container";
import ImageContainer from "../ImageContainer";

const Step5 = () => {
  const dispatch = useDispatch();

  const goToStep6 = () => {
    dispatch(updateStep(6));
  };

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container withBackground>
      <ImageContainer src={Daumen} />
      <MuiGrid
        item
        alignItems="center"
        justifyContent="center"
        marginBottom="40px"
      >
        <MuiTypography align="center" variant="h6">
          Die Firmendaten haben wir<br/>
          schon einmal geschafft.
        </MuiTypography>
      </MuiGrid>
      <MuiGrid>
        <MuiButton
          sx={{ width: "327px", marginBottom: isSmallDevice ? "0px" : "60px"  }}
          color="primary"
          onClick={goToStep6}
        >
          weiter
        </MuiButton>
      </MuiGrid>
    </Container>
  );
}

export default Step5;
