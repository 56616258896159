import { MobileStepper } from "@mui/material";

interface ProgressBarProps {
  activeStep?: number;
  totalSteps?: number;
}

const ProgressBarTop = ({
  activeStep = -1,
  totalSteps = 6,
}: ProgressBarProps) => (
  <MobileStepper
    steps={totalSteps}
    variant="dots"
    position="static"
    activeStep={activeStep - 1}
    backButton={null}
    nextButton={null}
    sx={{
      top: "70px",
      padding: 0,
      position: "absolute",
    }}
  />
);

export default ProgressBarTop;
