import { styled } from "@mui/material";

export interface ImageContainerProps {
  marginTopMd?: string;
  marginBottomMd?: string;
  marginTopSm?: string;
  marginBottomSm?: string;
}

const ImageContainer = styled("img")<ImageContainerProps>(({ theme, marginTopMd, marginBottomMd, marginTopSm, marginBottomSm }) => ({
  [theme.breakpoints.up('md')]: {
    marginTop: marginTopMd ?? "111px",
    marginBottom: marginBottomMd ?? "33px",
  },
  [theme.breakpoints.down('md')]: {
    marginTop: marginTopSm ?? "213px",
    marginBottom: marginBottomSm ?? "30px",
  },
}))

export default ImageContainer;
