import { theme } from "../../../assets/css/theme";

export const baseStyle = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  borderWidth: "2px",
  borderStyle: "dashed",
  borderColor: theme.palette.primary.main,
  padding: "12px 16px",
  borderRadius: "20px",
  outline: 0,
  backgroundColor: "white",
  transition: "borderColor .25s ease-in-out",
};

export const acceptStyle = {
  borderColor: theme.palette.secondary.main,
  borderStyle: "solid",
};
export const rejectStyle = {
  borderColor: theme.palette.error.main,
  borderStyle: "solid",
};

export const filledStyle = {
  backgroundColor: theme.palette.secondary[30],
  borderColor: "transparent",
};
