import { Info as InfoIcon } from "@mui/icons-material";
import {
  Button as MuiButton,
  Grid as MuiGrid,
  InputAdornment,
  MenuItem,
  TextField as MuiTextField,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Company from "../../../../assets/images/company.png";
import { updateStep } from "../../../../store/slices/onboarding";
import { DateDisplay } from "../../../Atoms/DateDisplay";
import Container from "../Container";
import ImageContainer from "../ImageContainer";

const endIcon = (
  <InputAdornment position="end">
    <InfoIcon />
  </InputAdornment>
);

const Step3 = () => {
  const dispatch = useDispatch();
  const [country, setCountry] = useState("0");
  const goToStep4 = () => {
    dispatch(updateStep(4));
  };

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));

  const jobDescriptionPlaceholder =
    "Inputfiel Multiline Zeile 01\n" +
    "Inputfiel Multiline Zeile 01\n" +
    "Inputfiel Multiline Zeile 01 \n" +
    "Inputfiel Multiline Zeile 01 \n" +
    "Inputfiel Multiline Zeile 01 \n";

  const width = isSmallDevice ? "327px" : "440px";

  return (
    <Container withBackground={false} activeStep={3}>
      <ImageContainer
        src={Company}
        marginTopMd="86px"
        marginTopSm="122px"
        marginBottomMd="6px"
        marginBottomSm="6px"
      />
      <MuiGrid
        item
        alignItems="center"
        justifyContent="center"
        paddingLeft="24px"
        paddingRight="24px"
      >
        <MuiTypography
          align="center"
          variant="body1"
          color="rgba(61, 65, 77, 0.5)"
        >
          Unternehmensname default
        </MuiTypography>
        <MuiTypography sx={{ marginTop: "18px" }} align="center" variant="h6">
          Ich benötige noch mehr <br />
          Informationen zu Ihrem Unternehmen
        </MuiTypography>
      </MuiGrid>
      <MuiGrid sx={{ marginTop: "16px" }} item>
        <MuiTextField
          value={country}
          select
          sx={{ width }}
          defaultValue="Straße & Hausnummer"
        >
          <MenuItem
            onClick={() => setCountry("0")}
            value="0"
            className="SelectMenuItem"
          >
            Deutschland
          </MenuItem>
          <MenuItem
            onClick={() => setCountry("1")}
            value="1"
            className="SelectMenuItem"
          >
            Poland
          </MenuItem>
        </MuiTextField>
      </MuiGrid>
      <MuiGrid item sx={{ marginTop: "40px" }}>
        <MuiTypography align="center" variant="h6" color="rgba(0, 0, 0, 0.87)">
          Bitte beschreiben Sie Ihre Tätigkeit
        </MuiTypography>
      </MuiGrid>
      <MuiGrid item sx={{ marginTop: "16px" }}>
        <MuiTextField
          style={{ color: "#3D414D" }}
          multiline
          minRows={6}
          sx={{ width }}
          placeholder={jobDescriptionPlaceholder}
        />
      </MuiGrid>
      <MuiGrid item sx={{ marginTop: "40px" }}>
        <MuiTypography align="center" variant="h6" color="rgba(0, 0, 0, 0.87)">
          Wann haben Sie gegründet?
        </MuiTypography>
      </MuiGrid>
      <MuiGrid sx={{ width }} item marginTop="16px">
        <DateDisplay placeholder="Gründungsdatum" />
      </MuiGrid>
      <MuiGrid item sx={{ marginTop: "40px" }}>
        <MuiTypography align="center" variant="h6" color="rgba(0, 0, 0, 0.87)">
          Wann wurde Ihre Firma eingetragen?
        </MuiTypography>
      </MuiGrid>
      <MuiGrid sx={{ width }} item marginTop="16px">
        <DateDisplay placeholder="Tag der Eintragung" />
      </MuiGrid>
      <MuiGrid
        item
        sx={{ marginTop: "40px", flexDirection: "row", display: "flex" }}
      >
        <MuiTypography
          style={{ maxWidth: "327px" }}
          align="center"
          variant="h6"
          color="rgba(0, 0, 0, 0.87)"
        >
          Wann wurde der Gesellschaftsvertrag unterschrieben?{" "}
          <MuiTypography
            display="inline"
            variant="body1"
            color="rgba(0, 0, 0, 0.87)"
          >
            (Optional)
          </MuiTypography>
        </MuiTypography>
      </MuiGrid>
      <MuiGrid sx={{ width }} item marginTop="16px">
        <DateDisplay />
      </MuiGrid>
      <MuiGrid
        item
        sx={{ marginTop: "40px", flexDirection: "row", display: "flex" }}
      >
        <MuiTypography align="center" variant="h6" color="rgba(0, 0, 0, 0.87)">
          Bitte geben Sie Ihre Bankverbindung an.
        </MuiTypography>
      </MuiGrid>
      <MuiGrid color="rgba(0, 0, 0, 0.87)" marginTop="16px" item>
        <MuiTextField
          sx={{ width, color: "rgba(0, 0, 0, 0.87)" }}
          placeholder="IBAN"
          InputProps={{
            endAdornment: endIcon,
          }}
        />
      </MuiGrid>
      <MuiGrid paddingBottom="99px" item>
        <MuiButton
          onClick={goToStep4}
          color="primary"
          sx={{ width, marginTop: "40px" }}
        >
          weiter
        </MuiButton>
      </MuiGrid>
    </Container>
  );
};

export default Step3;
