import App from "./App";
import React from "react";
import store from "./store";
import ReactDOM from "react-dom/client";
import { theme } from "./assets/css/theme";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { EnvProvider, Auth0Provider, SnackbarProvider } from "./providers";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLDivElement
);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <React.StrictMode>
          <BrowserRouter>
            <EnvProvider>
              <Auth0Provider>
                <SnackbarProvider>
                  <App />
                </SnackbarProvider>
              </Auth0Provider>
            </EnvProvider>
          </BrowserRouter>
        </React.StrictMode>
      </StyledEngineProvider>
    </ThemeProvider>
  </Provider>
);
