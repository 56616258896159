import { Grid } from "@mui/material";
import { ITaskGroup } from "../../../types";
import { TypeTranslation } from "../../Molecules/DocumentOverview/translations";
import UploadComponent from "./UploadComponent";

interface IProps {
  filesToUpload: any; //Array<{ file: File; groupId: number }>
  filesToDelete?: any; //Array<{ id: number; groupId: number }>
  groups?: Array<ITaskGroup>;
  nameOfUpload?: string;
  grid?: boolean;
}

const FileUpload = (props: IProps) => {
  const { filesToUpload, filesToDelete, groups, nameOfUpload, grid } = props;
  return (
    <>
      {groups ? (
        <Grid container spacing={1} columnSpacing={6}>
          {groups.map((group: ITaskGroup) => (
            <Grid item xs={12} lg={grid ? 6 : 12} key={group.id}>
              <UploadComponent
                groupName={TypeTranslation.hasOwnProperty(group.name)? TypeTranslation[group.name]: group.name}
                groupId={group.id}
                existingDoc={group.documents}
                filesToUpload={filesToUpload}
                filesToDelete={filesToDelete}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <UploadComponent
          groupName={nameOfUpload}
          filesToUpload={filesToUpload}
        />
      )}
    </>
  );
};

export default FileUpload;
