import { useDispatch } from "react-redux";
import { updateIsOnboard } from "../../../../store/slices/onboarding";
import {
	Grid as MuiGrid,
	Button as MuiButton,
	Typography as MuiTypography, TextField as MuiTextField, useTheme, useMediaQuery,
} from "@mui/material";
import Daumen from "../../../../assets/images/fragend.png";
import Container from "../Container";
import ImageContainer from "../ImageContainer";

const Step9 = () => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
	const width = isSmallDevice ? '327px' : '440px';

	const completeOnboarding = () => {
		dispatch(updateIsOnboard(false));
	};

	return <Container withBackground>
		<ImageContainer src={Daumen}/>
		<MuiGrid
			item
			alignItems="center"
			justifyContent="center"
		>
			<MuiTypography sx={{maxWidth: '388px'}} align="center" variant="h6">
				Haben Sie noch Fragen zu uns?
			</MuiTypography>
			<MuiTextField
				sx={{width, marginTop: '12px'}}
				placeholder="Ich habe noch Fragen über...."
				multiline
				minRows={10}
			/>
		</MuiGrid>
		<MuiGrid>
			<MuiButton
				sx={{width, marginTop: '64px', marginBottom: "57px"}}
				color="primary"
				onClick={completeOnboarding}
			>
				abschicken
			</MuiButton>
		</MuiGrid>
	</Container>
};

export default Step9;
