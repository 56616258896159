import { Box, Grid, TextField, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ClickableTextField = ({ children, ...props }: any) => {
  return (
    <TextField
      {...props}
      sx={{
        // if button is disabled
        ".MuiInputBase-input": {
          WebkitTextFillColor: "#000",
          opacity: 1,
          "&:hover": {
            cursor: "pointer",
          },
        },
      }}
    >
      {children}
    </TextField>
  );
};

const BaseDataCompanyRole = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  return (
    <Box>
      <Grid container spacing={{ xs: 0 }} columnSpacing={{ md: 3 }}>
        <Grid item xs={12} md={6} marginBottom={isMobile ? "10px" : 0}>
          <ClickableTextField
            fullWidth
            label="Geschäftsführer"
            variant="standard"
            value="Tilman Walch"
            InputProps={{ disableUnderline: !isMobile }}
            disabled
            onClick={() => navigate(`/role-detail`)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <ClickableTextField
            fullWidth
            label="CFO"
            variant="standard"
            value="Michel Menk"
            InputProps={{ disableUnderline: true }}
            onClick={() => console.log("click")}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BaseDataCompanyRole;
