import { CssBaseline } from "@mui/material";
import { useSelector } from "react-redux";
import { CompanyOnboarding } from "../components/Molecules/Onboarding/CompanyOnboarding";
import NaturalPersonOnboarding from "../components/Molecules/Onboarding/NaturalPersonOnboarding/NaturalPersonOnboarding";
import { SelfEmployeeOnboarding } from "../components/Molecules/Onboarding/SelfEmployeeOnboarding";
import { IStore } from "../store/types";

const Onboarding = () => {
  const type = useSelector((state: IStore) => state.onboarding.ACTOR_TYPE);

  const onboardings = {
    COMPANY: <CompanyOnboarding />,
    SELF_EMPLOYEE: <SelfEmployeeOnboarding />,
    NATURAL_PERSON: <NaturalPersonOnboarding />,
  };

  return (
    <>
      <CssBaseline />
      {onboardings[type]}
    </>
  );
};

export default Onboarding;
