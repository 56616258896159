import { useAuth0 } from "@auth0/auth0-react";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Logout as LogoutIcon,
  People as PeopleIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Collapse,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MouseEvent, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IStore } from "../../../store/types";
import { avatarTilman } from "../../../utils";
import IconButton from "../IconButton";
import CollapseMandantChange from "./CollapseMandantChange";
import CollapseStatus from "./CollapseStatus";
import StatusIcon from "./StatusIcon";

const ProfileMenu = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setChangeCollapseOpen(false);
  };

  const { logout } = useAuth0();

  const [changeCollapseOpen, setChangeCollapseOpen] = useState(false);

  const toggleChangeCollapse = () => {
    setChangeCollapseOpen(!changeCollapseOpen);
    setStatusCollapseOpen(false);
  };

  const [statusCollapseOpen, setStatusCollapseOpen] = useState(false);

  const toggleStatusCollapse = () => {
    setStatusCollapseOpen(!statusCollapseOpen);
    setChangeCollapseOpen(false);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const clients = useSelector((state: IStore) => state.clients);

  return (
    <>
      <Tooltip title="Profil menu">
        <IconButton
          id="nav-menu"
          size="small"
          onClick={handleClick}
          data-testid="userMenu"
          icon={
            isMobile ? (
              <Avatar
                sx={{
                  height: 32,
                  width: 32,
                  borderRadius: 16,
                  backgroundColor: "primary.main",
                  objectFit: "cover",
                }}
              >
                <img
                  src={avatarTilman}
                  width="32px"
                  height="32px"
                  alt="profile"
                />
              </Avatar>
            ) : (
              <Avatar
                sx={{
                  height: 32,
                  width: 32,
                  borderRadius: 16,
                  backgroundColor: "primary.main",
                  objectFit: "contain",
                }}
              >
                <img
                  src={avatarTilman}
                  width="32px"
                  height="32px"
                  alt="profile"
                />
              </Avatar>
            )
          }
          sx={{
            maxWidth: 32,
            maxHeight: 32,
            boxShadow: isMobile ? theme.shadows[4] : "none",
            backgroundColor: isMobile ? theme.palette.common.white : "#1129F5",
            "&.Mui-disabled > .MuiButton-startIcon > .MuiSvgIcon-root": {
              color: theme.palette.common.white,
            },
          }}
        />
      </Tooltip>
      {/*<NavImgAvatar
        data-testid="userMenu"
        onClick={handleClick}
        sx={{ boxShadow: { xl: "none" }, overflow: "visible" }}
        variant={isLargeScreen ? "square" : "circular"}
      >
        <Badge
          color="error"
          variant="dot"
          overlap="circular"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <AccountCircleIcon
            color={open ? "secondary" : "primary"}
            fontSize="inherit"
          ></AccountCircleIcon>
        </Badge>
      </NavImgAvatar>*/}

      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            navigate("/user-profile");
            handleClose();
          }}
        >
          <ListItemIcon>
            <Avatar
              sx={{
                height: 24,
                width: 24,
                backgroundColor: "primary.main",
              }}
            >
              <img
                src={avatarTilman}
                width="24px"
                height="24px"
                alt="profile"
              />
            </Avatar>
          </ListItemIcon>
          <ListItemText>Mein Profil</ListItemText>
        </MenuItem>
        <MenuItem
          disabled
          onClick={toggleStatusCollapse}
          sx={{ backgroundColor: statusCollapseOpen ? "#B7F23530" : "" }}
        >
          <ListItemIcon>
            <StatusIcon disabled color="#B7F235" />
          </ListItemIcon>
          <ListItemText>Status</ListItemText>
          {statusCollapseOpen ? (
            <ExpandLessIcon color="disabled" />
          ) : (
            <ExpandMoreIcon color="disabled" />
          )}
        </MenuItem>

        <Collapse in={statusCollapseOpen} unmountOnExit>
          <CollapseStatus />
        </Collapse>

        {clients?.length > 0 && (
          <MenuItem
            onClick={toggleChangeCollapse}
            sx={{ backgroundColor: changeCollapseOpen ? "#B7F23530" : "" }}
          >
            <ListItemIcon>
              <PeopleIcon color="primary" />
            </ListItemIcon>
            <ListItemText>Mandant wechseln</ListItemText>{" "}
            {changeCollapseOpen ? (
              <ExpandLessIcon color="primary" />
            ) : (
              <ExpandMoreIcon color="primary" />
            )}
          </MenuItem>
        )}

        {clients?.length > 0 && (
          <Collapse in={changeCollapseOpen} unmountOnExit>
            <CollapseMandantChange test={clients} />
          </Collapse>
        )}

        <MenuItem disabled onClick={handleClose}>
          <ListItemIcon>
            <SettingsIcon color="disabled" />
          </ListItemIcon>
          <ListItemText>Einstellungen</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            logout({
              returnTo: window.location.origin,
            });
          }}
        >
          <ListItemIcon>
            <LogoutIcon color="primary" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
