import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { IChatMessage } from "../../ressources/interfaces";
import { IStore } from "../../store/types";
import { IClient, ITask } from "../../types";
import { useFetchData } from "../fetchData";

export interface IClientTaskFilter {
  days: number;
  status: string;
  type: string;
  page: number;
  sort: string;
  size: number;
}

const useClientTaskService = () => {
  const { fetchData } = useFetchData();
  const currentClient: IClient = useSelector(
    (state: IStore) => state.currentClient
  );

  const [clientTask, setClientTask] = React.useState<ITask>();
  const [clientTaskMessages, setClientTaskMessages] = React.useState<
    IChatMessage[]
  >([]);
  const [clientTasks, setClientTasks] = React.useState<any>();
  const [clientTasksFilter, setClientTasksFilter] = React.useState<any>();
  const [clientTaskSignUrl, setClientTaskSignUrl] = React.useState({
    url: null,
  });

  // for mandate change
  const [selectedCurrentClient, setSelectedCurrentClient] =
    React.useState<any>();
  const [selectedCurrentFilter, setSelectedCurrentFilter] =
    React.useState<any>();

  useEffect(() => {
    if (currentClient && currentClient.id !== selectedCurrentClient?.id) {
      setSelectedCurrentClient(currentClient);
      // switch the tasks
      if (selectedCurrentFilter) {
        readClientTasks({ filter: selectedCurrentFilter });
      }
    }
  }, [currentClient]);

  const readClientTask = async ({ taskId }: { taskId: number }) => {
    const res = await fetchData(`clients/${currentClient.id}/tasks/${taskId}`);
    setClientTask(res.data);
    return res.data;
  };

  const createClientTaskMessage = async ({
    taskId,
    data,
  }: {
    taskId: number;
    data: any;
  }): Promise<IChatMessage> => {
    const res = await fetchData(
      `clients/${currentClient.id}/tasks/${taskId}/messages`,
      {
        method: "POST",
        data: data,
      }
    );
    setClientTaskMessages(res.data);
    return res.data;
  };

  const readClientTaskMessages = async ({ taskId }: { taskId: number }) => {
    const res = await fetchData(
      `clients/${currentClient.id}/tasks/${taskId}/messages`
    );
    setClientTaskMessages(res.data);
    return res.data;
  };

  const readClientTasks = async ({ filter }: { filter: IClientTaskFilter }) => {
    const res = await fetchData(
      `clients/${currentClient.id}/tasks?days=${filter.days}&status=${filter.status}&type=${filter.type}&page=${filter.page}&sort=${filter.sort}&size=${filter.size}`
    );
    // save to use this on mandate change
    setSelectedCurrentFilter(filter);
    setClientTasks(res.data);
    return res.data;
  };

  const readClientTasksFilter = async () => {
    const res = await fetchData(`clients/${currentClient.id}/tasks-filter`);
    setClientTasksFilter(res.data);
    return res.data;
  };

  const readClientTaskSignUrl = async ({ taskId }: { taskId: number }) => {
    const res = await fetchData(
      `clients/${currentClient.id}/tasks/${taskId}/sign/url?redirect=${window.location.origin}/signed-sucess`
    );
    setClientTaskSignUrl(res.data);
    return res.data;
  };

  const updateClientTask = async ({
    taskId,
    data,
  }: {
    taskId: number;
    data: any;
  }) => {
    const res = await fetchData(`clients/${currentClient.id}/tasks/${taskId}`, {
      method: "PATCH",
      data,
    });
    return res.data;
  };

  return {
    readClientTasks,
    readClientTasksFilter,
    readClientTask,
    updateClientTask,
    readClientTaskSignUrl,
    createClientTaskMessage,
    readClientTaskMessages,
    // state
    clientTask,
    clientTasks,
    clientTaskMessages,
    clientTasksFilter,
    clientTaskSignUrl,
  };
};

export default useClientTaskService;
