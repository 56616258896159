import { Info as InfoIcon } from "@mui/icons-material";
import {
  Grid as MuiGrid,
  InputAdornment as MuiInputAdornment,
  TextField as MuiTextField,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useField } from "formik";
import Smile from "../../../../assets/svg/user.svg";
import Toggle from "../../../Atoms/Toggle";
import ImageContainer from "../ImageContainer";

const endIcon = (
  <MuiInputAdornment position="end">
    <InfoIcon />
  </MuiInputAdornment>
);

const Step2: React.FunctionComponent = () => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const width = isSmallDevice ? "327px" : "440px";
  const [married, , marriedHelpers] = useField("married");
  const [children, , childrenHelpers] = useField("children");

  const handleChangeMarried = (event: any) => {
    marriedHelpers.setValue(event.target.checked);
  };

  const handleChangeChildren = (event: any) => {
    childrenHelpers.setValue(event.target.checked);
  };

  return (
    <>
      <ImageContainer
        src={Smile}
        marginTopMd="86px"
        marginTopSm="122px"
        marginBottomMd="6px"
        marginBottomSm="6px"
      />
      <MuiGrid
        item
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px" }}
      >
        <MuiTypography
          align="center"
          variant="body1"
          color="rgba(61, 65, 77, 0.5)"
        >
          Herr Michael Müller
        </MuiTypography>
        <MuiTypography align="center" variant="h6">
          Ich benötige ein paar
          <br />
          Informationen von Ihnen.
        </MuiTypography>
      </MuiGrid>
      <MuiGrid
        sx={{ marginTop: "40px" }}
        display="flex"
        flexDirection="column"
        spacing="12px"
      >
        <MuiGrid item>
          <MuiTextField
            style={{ maxWidth: width, width: "100%" }}
            placeholder="Straße & Hausnummer"
          />
        </MuiGrid>
        <MuiGrid item marginTop="12px">
          <MuiTextField sx={{ width: "98px" }} placeholder="PLZ" />
          <MuiTextField
            sx={{ width: isSmallDevice ? "221px" : "334px", marginLeft: "8px" }}
            placeholder="Ort"
          />
        </MuiGrid>
        <MuiGrid item>
          <MuiTextField
            style={{ maxWidth: width, width: "100%", marginTop: "12px" }}
            placeholder="E-Mail"
          />
        </MuiGrid>
        <MuiGrid item>
          <MuiTextField
            style={{ maxWidth: width, width: "100%", marginTop: "12px" }}
            placeholder="Telefon"
          />
        </MuiGrid>
        <MuiGrid item>
          <MuiTypography
            style={{ marginTop: "40px" }}
            align="center"
            variant="h6"
          >
            Wann sind Sie geboren worden?
          </MuiTypography>
          <MuiTextField
            sx={{ width, marginTop: "12px" }}
            placeholder="Geburtsdatum"
            InputProps={{
              endAdornment: endIcon,
            }}
          />
          <MuiTypography
            style={{ marginTop: "40px", maxWidth: width }}
            align="center"
            variant="h6"
          >
            Sind sie verheiratet oder haben Sie Kinder?
          </MuiTypography>
        </MuiGrid>
        <MuiGrid
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "14px",
          }}
        >
          <Toggle
            value={married}
            onClick={handleChangeMarried}
            label="verheiratet"
          />
          <Toggle
            value={children}
            onClick={handleChangeChildren}
            label="Kinder"
          />
        </MuiGrid>
      </MuiGrid>
    </>
  );
};

export default Step2;
