import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { Dialog, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ITask } from "../../../types";
import TaskModal from "./TaskModal";
import TaskModalSigning from "./TaskModalSigning";

interface IProps {
  iconColor?: string;
  setSelected?: any;
  taskData: ITask;
}

const renderModalDialog = (type: string, taskData: any, toggleDialog: any) => {
  if (type === "SIGNING_TASK")
    return <TaskModalSigning taskData={taskData} handleClose={toggleDialog} />;
  else return <TaskModal taskData={taskData} handleClose={toggleDialog} />;
};

const TaskMenu = (props: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const { taskData } = props;
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (props.setSelected) props.setSelected(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    if (props.setSelected) props.setSelected(false);
  };
  const toggleDialog = () => {
    handleClose();
    setOpenDialog(!openDialog);
  };

  const { iconColor } = props;

  return (
    <>
      <Stack
        alignItems="center"
        onClick={handleClick}
        color={iconColor ? iconColor : ""}
        style={{ zIndex: 2 }}
      >
        <MoreHorizIcon style={{ cursor: "pointer" }} />
      </Stack>
      <Menu
        autoFocus={false}
        id="task-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuItem value={1} onClick={toggleDialog}>
          <Typography variant="body1">Details anzeigen</Typography>
        </MenuItem>
        <MenuItem value={2} onClick={() => navigate(`/task/${taskData.id}`)}>
          <Typography variant="body1">Aufgabe starten</Typography>
        </MenuItem>
      </Menu>

      <Dialog
        open={openDialog}
        onClose={toggleDialog}
        fullWidth={true}
        maxWidth={"md"}
      >
        {taskData && renderModalDialog(taskData.type || '', taskData, toggleDialog)}
      </Dialog>
    </>
  );
};

export default TaskMenu;
