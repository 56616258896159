import {
  Button as MuiButton,
  Grid as MuiGrid,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import Daumen from "../../../../assets/images/daumen.png";
import {
  updateIsOnboard,
  updateStep,
} from "../../../../store/slices/onboarding";
import Container from "../Container";
import ImageContainer from "../ImageContainer";

const Step6 = () => {
  const dispatch = useDispatch();

  const goToStep7 = () => {
    dispatch(updateStep(7));
  };

  const goToOnBoarding = () => {
    dispatch(updateIsOnboard(false));
  };

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const width = isSmallDevice ? "327px" : "440px";

  return (
    <Container withBackground>
      <ImageContainer src={Daumen} />
      <MuiGrid
        item
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px" }}
      >
        <MuiTypography sx={{ maxWidth: "388px" }} align="center" variant="h6">
          Ihre Stammdatenerfassung ist fertig!
        </MuiTypography>
        <MuiTypography
          sx={{ maxWidth: "388px", marginTop: "24px" }}
          align="center"
          paragraph
          variant="h6"
        >
          Wilkommen zu Ihrem Dashboard und genießen Sie die neue Art der
          Steuerberatung.
        </MuiTypography>
      </MuiGrid>
      <MuiGrid>
        <MuiButton
          sx={{ width, marginTop: "64px" }}
          color="primary"
          onClick={goToOnBoarding}
        >
          fertig
        </MuiButton>
        <MuiTypography
          onClick={goToStep7}
          sx={{ color: "#1129F5", marginTop: "8px", paddingBottom: "32px" }}
          align="center"
          variant="body1"
        >
          Ich habe noch Fragen.
        </MuiTypography>
      </MuiGrid>
    </Container>
  );
};

export default Step6;
