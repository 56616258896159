import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { updateUser } from "../../store/slices/user";
import { IClient } from "../../types";
import { useFetchData } from "../fetchData";

const useReadUser = () => {
  const dispatch = useDispatch();
  const { fetchData } = useFetchData();
  const { user } = useAuth0();
  const readUser = async (): Promise<IClient[]> => {
    const userId = user!.sub!.split("|")[1];
    const res = await fetchData(`users/${userId}`);
    dispatch(updateUser(res.data));
    return res.data;
  };

  return [readUser];
};

export default useReadUser;
