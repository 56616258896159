import { Close as CloseIcon } from "@mui/icons-material";
import { Paper, Stack, Typography } from "@mui/material";
import {
  CustomContentProps,
  SnackbarContent,
  useSnackbar,
  VariantType,
} from "notistack";
import { forwardRef } from "react";

const InfoTag = forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
  const { closeSnackbar } = useSnackbar();

  const titles: { [key in VariantType]: string } = {
    info: "Information",
    error: "Fehler",
    success: "Erfolg",
    warning: "Warnung",
    default: "Default",
  };

  // get all the dates inbetween two dates

  const colors: { [key in VariantType]: string } = {
    info: "#1129F5",
    error: "#FF0000",
    success: "#B7F235",
    warning: "#FFD60A",
    default: "#000000",
  };

  return (
    <SnackbarContent
      ref={ref}
      role="alert"
      style={{ justifyContent: "center" }}
    >
      <Paper
        sx={{
          color: colors[props.variant],
          border: `2px solid ${colors[props.variant]}`,
          padding: "24px",
          minWidth: "298px",
          boxShadow:
            "0px 4px 8px -2px rgba(42, 51, 60, 0.15), 0px 6px 16px -2px rgba(42, 51, 60, 0.06)",
          borderRadius: "20px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          mb="8px"
        >
          <Typography variant="h6" sx={{ lineHeight: "133.4%" }}>
            {titles[props.variant]}
          </Typography>
          <CloseIcon
            sx={{ marginLeft: "auto", cursor: "pointer" }}
            onClick={() => closeSnackbar(props.id)}
          />
        </Stack>
        <Typography
          variant="body2"
          sx={{ fontSize: "10px", lineHeight: "143%", letterSpacing: "0.17px" }}
        >
          {props.message}
        </Typography>
      </Paper>
    </SnackbarContent>
  );
});

export default InfoTag;
