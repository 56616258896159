import { Error as ErrorIcon } from "@mui/icons-material";
import { Tooltip as MuiTooltip } from "@mui/material";

export interface IMessageInfos {
  [k: string]: any;
}

export const MSG_INFOS: IMessageInfos = {
  email: "Email eingeben",
  avatarURL: "Avatar eingeben",
  firstName: "First Name eingeben",
  lastName: "Last Name eingeben",
  jobTitle: "Webseit eingeben",
  phone: "Telefon eingeben",
  clientSince: "Client ingeben",
};

const UserErrorMsgs = ({ error }: { error: keyof IMessageInfos }) => (
  <MuiTooltip title={MSG_INFOS[error]} sx={{ whiteSpace: "pre-line" }}>
    <ErrorIcon />
  </MuiTooltip>
);

export default UserErrorMsgs;
