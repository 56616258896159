import { Grid, Paper, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import IconButton from "../Atoms/IconButton";
// import { ReactComponent as PlusIcon } from "../../assets/svg/circlePlus.svg";
import AddIcon from "@mui/icons-material/Add";

interface WidgetWrapperProps {
  highlightOnFocus?: boolean;
  children?: React.ReactNode;
  title: {
    caption: string;
    onClick?: () => void;
  };
  subTitle?: string;
  marginBottom?: string;
  img?: { src: string; alt?: string; width?: number; height?: number };
  icon?: React.ReactNode;
  add?: {
    display?: boolean;
    disabled?: boolean;
    onClick?: () => void;
  };
  sx?: any;
}

const WidgetWrapper: React.FunctionComponent<WidgetWrapperProps> = ({
  highlightOnFocus,
  children,
  title,
  subTitle,
  marginBottom,
  img,
  icon,
  add,
  sx,
}) => {
  const [showHightLight, setShowHightLight] = React.useState(false);
  const widgetRef = React.useRef(null);

  React.useEffect(() => {
    if (highlightOnFocus) {
      const handleClickOutside = (event: any) => {
        // @ts-ignore
        if (widgetRef.current && !widgetRef.current!.contains(event.target)) {
          setShowHightLight(false);
        } else {
          setShowHightLight(true);
        }
      };
      // Bind
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // dispose
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, []);

  const theme = useTheme();

  return (
    <Grid
      item
      xs={12}
      marginBottom={marginBottom ? marginBottom : "15px"}
      ref={widgetRef}
      sx={{
        ...(showHightLight && {
          border: `1px solid ${theme.palette.secondary.main}`,
          borderRadius: "20px",
        }),
        ...sx,
      }}
    >
      <Paper
        sx={{
          padding: "24px",
          boxShadow:
            "0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)",
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing="10px"
          sx={{
            ...(children && {
              marginBottom: "16px",
            }),
          }}
        >
          {img && (
            <img
              src={img.src}
              width={img.width ?? "24px"}
              height={img.height ?? "24px"}
              alt={title.caption}
            />
          )}
          {icon && icon}
          <Stack sx={{ height: "25px" }}>
            <Typography
              variant="h6"
              sx={{
                lineHeight: "133.4%",
                ...(title.onClick && {
                  cursor: "pointer",
                }),
              }}
              onClick={title.onClick}
            >
              {title.caption}
            </Typography>
            {subTitle && (
              <Typography
                variant="body2"
                style={{
                  color: "#CFCFCF",
                  fontSize: "10px",
                  marginTop: "-3px",
                }}
              >
                {subTitle}
              </Typography>
            )}
          </Stack>

          {add?.display && (
            <IconButton
              disabled={add.disabled}
              color="primary"
              size="medium"
              icon={<AddIcon />}
              onClick={add.onClick}
              sx={{
                marginLeft: "auto !important",
                filter: "drop-shadow(1px 4px 3px rgb(0 0 0 / 0.4))",
                "&:disabled": {
                  filter: "none",
                },
              }}
            />
          )}
        </Stack>
        {children}
      </Paper>
    </Grid>
  );
};

export default WidgetWrapper;
