import { Badge, styled, TableCell, TableRow, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ChatIcon } from "../../../assets/svg/chat.svg";
import { IDocument } from "../../../types";
import { DocumentMenu } from "../DocumentOverview/DocumentMenu";
import { DocumentTableChip } from "../DocumentOverview/DocumentTableChip";
import {
  CategoryTranslation,
  TypeTranslation,
} from "../DocumentOverview/translations";

interface IDocumentTableContent {
  // TODO: we have two IDocument type
  data: Array<IDocument>;
  parentWidth: any;
}

const reduceChipToFourLetter = (label: string) => {
  if (label) return label.split("").slice(0, 4).join("");
  else return "";
};

const DocumentRow = styled(TableRow)(({ theme }) => ({
  position: "relative",
  "&:hover": {
    background: "#B7F23530",
  },
  "> td:first-of-type": {
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
  },
  "> td:last-of-type": {
    borderTopRightRadius: "12px",
    borderBottomRightRadius: "12px",
  },
  "&::after": {
    borderRadius: "12px",
    content: '""',
    height: "100%",
    width: "0",
    position: "absolute",
    top: 0,
    left: 0,
    transition: "width 150ms",
    backgroundColor: `${theme.palette.secondary.main}30`,
  },
  "&.highlight::after": {
    width: "100%",
  },
}));

export const DocumentTableContent = ({
  data,
  parentWidth,
}: IDocumentTableContent) => {
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState<number>(-1);

  return (
    <>
      {data?.map((document) => {
        const { name, category, type, creationDate, id } = document;
        const documentCategory: any = reduceChipToFourLetter(
          CategoryTranslation[category]
        );
        const documentType = reduceChipToFourLetter(TypeTranslation[type]);
        const date = moment(creationDate).format("DD.MM");
        return (
          <DocumentRow
            key={id}
            className={selectedId === id ? "highlight" : ""}
          >
            <TableCell
              onClick={() => navigate(`document/${id}`)}
              sx={{ cursor: "pointer" }}
            >
              <Typography
                variant="body2"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {name}
              </Typography>
            </TableCell>
            <TableCell>
              {category && (
                <DocumentTableChip
                  width={44}
                  color="white"
                  bgcolor="#7A86EC"
                  label={documentCategory}
                />
              )}
            </TableCell>
            {(parentWidth > 375 || parentWidth === 0) && (
              <TableCell>
                {type && (
                  <DocumentTableChip
                    width={58}
                    color="white"
                    bgcolor="#EAEAEA"
                    label={documentType}
                  />
                )}
              </TableCell>
            )}
            <TableCell align="center">
              <Typography
                variant="body2"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {date}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Badge
                variant="dot"
                overlap="circular"
                color="secondary"
                sx={{
                  top: "20%",
                  right: "20%",
                  maxWidth: "24px",
                }}
              >
                <ChatIcon />
              </Badge>
            </TableCell>
            <TableCell>
              <DocumentMenu id={id} name={name} setSelectedId={setSelectedId} />
            </TableCell>
          </DocumentRow>
        );
      })}
    </>
  );
};
