import { Info as InfoIcon } from "@mui/icons-material";
import {
  Button as MuiButton,
  Grid as MuiGrid,
  InputAdornment as MuiInputAdornment,
  MenuItem as MuiMenuItem,
  TextField as MuiTextField,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Company from "../../../../assets/images/company.png";
import { updateStep } from "../../../../store/slices/onboarding";
import Checkbox from "../../../Atoms/Checkbox";
import Container from "../Container";
import Bank from "../HelperScreens/Bank";
import Tax from "../HelperScreens/Tax";
import WhatVAT from "../HelperScreens/WhatVAT";
import ImageContainer from "../ImageContainer";

const Step9 = () => {
  const dispatch = useDispatch();
  const [service, setService] = useState("0");

  const goToStep10 = () => {
    dispatch(updateStep(10));
  };
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const width = isSmallDevice ? "327px" : "440px";
  const widthCheckBox = isSmallDevice ? "310px" : "420px";
  const [openVAT, setOpenVAT] = useState<boolean>(false);
  const [openTax, setOpenTax] = useState<boolean>(false);
  const [openBank, setOpenBank] = useState<boolean>(false);

  const endIconFirst = (
    <MuiInputAdornment
      style={{ cursor: "pointer" }}
      onClick={() => setOpenVAT(true)}
      position="end"
    >
      <InfoIcon />
    </MuiInputAdornment>
  );
  const endIconSecond = (
    <MuiInputAdornment
      style={{ cursor: "pointer" }}
      onClick={() => setOpenTax(true)}
      position="end"
    >
      <InfoIcon />
    </MuiInputAdornment>
  );
  const endIconThree = (
    <MuiInputAdornment
      style={{ cursor: "pointer" }}
      onClick={() => setOpenBank(true)}
      position="end"
    >
      <InfoIcon />
    </MuiInputAdornment>
  );

  return (
    <Container totalSteps={9} withBackground={false} activeStep={8}>
      <ImageContainer
        src={Company}
        marginTopMd="86px"
        marginTopSm="122px"
        marginBottomMd="6px"
        marginBottomSm="6px"
      />
      <MuiGrid
        item
        alignItems="center"
        justifyContent="center"
        paddingLeft="24px"
        paddingRight="24px"
      >
        <MuiTypography
          align="center"
          variant="body1"
          color="rgba(61, 65, 77, 0.5)"
        >
          Unternehmensname default
        </MuiTypography>
        <MuiTypography sx={{ marginTop: "18px" }} align="center" variant="h6">
          Wie lautet ihre Steuernummer?
        </MuiTypography>
      </MuiGrid>
      <MuiGrid marginTop="26px" item>
        <MuiTextField
          sx={{ width }}
          placeholder="12342345675567"
          InputProps={{
            endAdornment: endIconFirst,
          }}
        />
      </MuiGrid>
      <MuiGrid
        item
        sx={{ width: widthCheckBox }}
        marginTop={isSmallDevice ? "16px" : "12px"}
        marginLeft="11px"
      >
        <Checkbox label="Noch keine Steuernummern" />
      </MuiGrid>
      <MuiGrid item sx={{ marginTop: "40px" }}>
        <MuiTypography align="center" variant="h6" color="rgba(0, 0, 0, 0.87)">
          Wie lautet Ihre USt-Id Nr.?
        </MuiTypography>
      </MuiGrid>
      <MuiGrid marginTop="26px" item>
        <MuiTextField
          sx={{ width, color: "rgba(0, 0, 0, 0.87)" }}
          placeholder="DE1234567891"
          InputProps={{
            endAdornment: endIconSecond,
          }}
        />
      </MuiGrid>
      <MuiGrid
        item
        sx={{ width: widthCheckBox }}
        marginTop={isSmallDevice ? "16px" : "12px"}
        marginLeft="11px"
      >
        <Checkbox label="Noch keine USt-Id Nr." />
      </MuiGrid>
      <MuiGrid
        item
        sx={{ marginTop: "40px", flexDirection: "row", display: "flex" }}
      >
        <MuiTypography align="center" variant="h6" color="rgba(0, 0, 0, 0.87)">
          Wie lautet ihre Registernummer?
        </MuiTypography>
      </MuiGrid>
      <MuiGrid marginTop="26px" item>
        <MuiTextField
          sx={{ width, color: "rgba(0, 0, 0, 0.87)" }}
          placeholder="DE1234567891"
          InputProps={{
            endAdornment: endIconThree,
          }}
        />
      </MuiGrid>
      <MuiGrid
        item
        sx={{ width: widthCheckBox }}
        marginTop={isSmallDevice ? "16px" : "12px"}
        marginLeft="11px"
      >
        <Checkbox label="Noch keine Registernummer." />
      </MuiGrid>
      <MuiGrid item sx={{ marginTop: "40px" }}>
        <MuiTypography align="center" variant="h6" color="rgba(0, 0, 0, 0.87)">
          Welches Finanzamt ist für Sie zuständig?
        </MuiTypography>
      </MuiGrid>
      <MuiGrid item marginTop="26px">
        <MuiTextField sx={{ width }} select value={service}>
          <MuiMenuItem
            onClick={() => setService("0")}
            value="0"
            className="SelectMenuItem"
          >
            Finanzamt München
          </MuiMenuItem>
          <MuiMenuItem
            onClick={() => setService("1")}
            value="1"
            className="SelectMenuItem"
          >
            Servicezentrum
          </MuiMenuItem>
        </MuiTextField>
      </MuiGrid>
      <MuiGrid paddingBottom="99px" item>
        <MuiButton
          sx={{ width, marginTop: "40px" }}
          color="primary"
          onClick={goToStep10}
        >
          weiter
        </MuiButton>
      </MuiGrid>
      <WhatVAT open={openVAT} setOpen={setOpenVAT} />
      <Tax open={openTax} setOpen={setOpenTax} />
      <Bank open={openBank} setOpen={setOpenBank} />
    </Container>
  );
};

export default Step9;
