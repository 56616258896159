import { styled } from "@mui/material";

const Status = styled("div")(() => ({
  width: "4px",
  minWidth: "4px",
  height: "16px",
  marginRight: "8px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  backgroundColor: "red",
}));

const TaskStatus = (props: any) => {
  const { status } = props;

  return (
    <Status
      className="status"
      style={{
        backgroundColor:
          status === "ACTIVE"
            ? "#1129F5"
            : "" || status === "inProgress"
            ? "#B7F235"
            : "",
      }}
    ></Status>
  );
};

export default TaskStatus;
