import { useSnackbar } from "notistack";
import { useFetchData } from "../fetchData";

const useReadDocumentPath = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { fetchData } = useFetchData();

  const readDocumentPath = async ({
    downloadPath,
  }: {
    downloadPath: string;
  }) => {
    try {
      const res = await fetchData(`${downloadPath.slice(1)}`, {
        responseType: "blob",
      });
      return URL.createObjectURL(res.data);
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  return [readDocumentPath];
};

export default useReadDocumentPath;
