import { useSelector } from "react-redux";
import { IStore } from "../../../../store/types";
import {
  Step1,
  Step10,
  Step11,
  Step12,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
  Step9,
  WelcomeScreen,
} from "./index";

const SelfEmployeeOnboarding = () => {
  const step = useSelector((state: IStore) => state.onboarding.step);

  const steps = [
    <WelcomeScreen />,
    <Step1 />,
    <Step2 />,
    <Step3 />,
    <Step4 />,
    <Step5 />,
    <Step6 />,
    <Step7 />,
    <Step8 />,
    <Step9 />,
    <Step10 />,
    <Step11 />,
    <Step12 />,
  ];

  return steps[step];
};

export default SelfEmployeeOnboarding;
