import { Divider, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import StatusIcon from "./StatusIcon";

const CollapseStatus = () => {
  return (
    <>
      <MenuItem disableRipple disableTouchRipple>
        <Divider component="div" sx={{ width: "100%" }} />
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <StatusIcon color="#B7F235" />
        </ListItemIcon>
        <ListItemText>Active</ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <StatusIcon color="#FF0000" />
        </ListItemIcon>
        <ListItemText>Beschäftigt</ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <StatusIcon color="white" />
        </ListItemIcon>
        <ListItemText>Abwesend</ListItemText>
      </MenuItem>
      <MenuItem disableRipple disableTouchRipple>
        <Divider component="div" sx={{ width: "100%" }} />
      </MenuItem>
    </>
  );
};

export default CollapseStatus;
