import {
  Container as MuiContainer,
  Grid as MuiGrid,
  styled,
} from "@mui/material";
import BoxMilia from "../components/Atoms/BoxMilia";
import { DashboardDocumentOverview } from "../components/Molecules/Dashboard/DashboardDocumentOverview";
import ProfileContainer from "../components/Molecules/Dashboard/ProfileContainer";
import Tasks from "../components/Molecules/Dashboard/Tasks";
import WelcomeDashboard from "../components/Molecules/Dashboard/WelcomeDashboard";

const DashBoardGrid = styled("div")(({ theme }) => ({
  display: "grid",
  marginTop: "16px",

  gridAutoRows: `160px`,
  gap: "16px",
  gridTemplateColumns: "1fr",
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "repeat(12, 1fr)",
  },
  [theme.breakpoints.up("lg")]: {
    gap: "24px",
    marginTop: "24px",
  },
}));

const Dashboard = () => {
  return (
    <MuiContainer
      disableGutters={true}
      sx={{ marginBottom: "24px", padding: "0 16px" }}
    >
      <MuiGrid container>
        <MuiGrid item xs={12}>
          <BoxMilia>
            <WelcomeDashboard />
          </BoxMilia>
          <DashBoardGrid>
            <ProfileContainer
              sx={{
                gridColumn: { xs: "1", md: "1 / span 8", xl: "1 / span 5" },
                gridRow: "1",
              }}
            />

            <Tasks
              sx={{
                gridRow: "2 span / span 2",
                gridColumn: { xs: "1", md: "9 / span 12", xl: "6 / span 12" },
              }}
            />
            <DashboardDocumentOverview
              sx={{
                gridRow: "3 span / span 3",
                gridColumn: { xs: "1", md: "1 / span 8", xl: "1 / span 5" },
              }}
            />
          </DashBoardGrid>
        </MuiGrid>
      </MuiGrid>
    </MuiContainer>
  );
};

export default Dashboard;
