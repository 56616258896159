import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useClientTaskService from "../../services/fetch/useClientTaskService";
import { useFetchData } from "../../services/fetchData";
import { IStore } from "../../store/types";
import { IClient, ITask } from "../../types";
import ConfirmDialog from "../Atoms/ConfirmDialog";
import { DateDisplay } from "../Atoms/DateDisplay";
import { FileUpload } from "../Atoms/Upload";
import DurationTranslation from "../Molecules/DocumentOverview/translations/DurationTranslation";
import TaskInfoHeader from "../Molecules/TaskOverview/TaskInfoHeader";

interface IProps {
  taskData: ITask;
}

interface UploadTaskPayload {
  comment: string;
  year: number;
  duration: string;
}

export const UploadTask = ({ taskData }: IProps) => {
  const { updateClientTask } = useClientTaskService();
  const isTaskFinished = taskData.status === "FINISHED";
  const currentClient: IClient = useSelector(
    (state: IStore) => state.currentClient
  );
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { fetchData, postDocument } = useFetchData();
  const [filesToUpload, setFilesToUpload] = useState<
    Array<{ file: File; groupId: number }>
  >([]);
  const [filesToDelete, setFilesToDelete] = useState<
    Array<{ id: number; groupId: number }>
  >([]);
  const [submitConfirmDialogOpen, setSubmitConfirmDialogOpen] =
    useState<boolean>(false);
  const [cancelConfirmDialogOpen, setCancelConfirmDialogOpen] =
    useState<boolean>(false);

  const toggleSubmitDialog = () => {
    setSubmitConfirmDialogOpen(!submitConfirmDialogOpen);
  };
  const toggleCancelDialog = () => {
    setCancelConfirmDialogOpen(!cancelConfirmDialogOpen);
  };
  const saveDocuments = (submit?: boolean) => {
    const filesToDeletePromises = filesToDelete.map(
      (doc: { id: number; groupId: number }) => {
        const url = `clients/${currentClient.id}/tasks/${taskData.id}/groups/${doc.groupId}/documents/${doc.id}`;
        return fetchData(url, { method: "DELETE" });
      }
    );
    Promise.all([...filesToDeletePromises])
      .then(() => {
        const filesToUploadPromises = filesToUpload.map(
          (doc: { file: File; groupId: number }) => {
            const url = `clients/${currentClient.id}/tasks/${taskData.id}/groups/${doc.groupId}/documents`;
            const formData = new FormData();
            formData.append("file", doc.file);
            return postDocument(url, formData);
          }
        );
        Promise.all([...filesToUploadPromises])
          .then(() => {
            if (submit) {
              updateClientTask({
                taskId: taskData.id,
                data: { status: "FINISHED" },
              }).then(() => {
                enqueueSnackbar("Dokumente erfolgreich hochgeladen", {
                  variant: "success",
                });
              });
            } else
              enqueueSnackbar("Dokumente erfolgreich zwischengespeichert", {
                variant: "success",
              });
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: "error" });
          });
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      })
      .finally(() => navigateToDashboard());
  };
  const navigateToDashboard = () => {
    navigate("/");
  };

  const uploadTaskPayload: UploadTaskPayload =
    taskData?.payload as UploadTaskPayload;
  return (
    <>
      <TaskInfoHeader data={taskData} />
      <Typography variant="h6" mt={3}>
        {taskData.name}
      </Typography>
      {uploadTaskPayload?.comment && (
        <Typography variant="body2" mt={3}>
          {uploadTaskPayload?.comment}
        </Typography>
      )}
      {uploadTaskPayload?.year && (
        <Typography variant="body2" mt={3} fontWeight="bold">
          Zeitraum:{" "}
          {uploadTaskPayload?.duration
            ? DurationTranslation[uploadTaskPayload?.duration]
            : ""}{" "}
          {uploadTaskPayload.year}
        </Typography>
      )}
      {taskData.deadlineDate && (
        <Grid sx={{ maxWidth: "126px", marginTop: "24px" }}>
          <DateDisplay
            disabled
            label="Frist"
            displayValue={new Date(taskData.deadlineDate)}
          />
        </Grid>
      )}
      {taskData.groups.length > 0 && (
        <Box mt={3}>
          <FileUpload
            groups={taskData.groups}
            filesToUpload={setFilesToUpload}
            filesToDelete={setFilesToDelete}
            grid
          />
        </Box>
      )}
      <Stack
        style={{ justifyContent: "flex-end" }}
        direction="row"
        gap={1.5}
        mt={3}
      >
        <Button disabled={isTaskFinished} onClick={toggleCancelDialog}>
          Schließen
        </Button>
        <Button
          disabled={isTaskFinished}
          color="primary"
          onClick={toggleSubmitDialog}
          sx={{
            boxShadow:
              "0px 4px 8px -2px rgba(42, 51, 60, 0.15), 0px 6px 16px -2px rgba(42, 51, 60, 0.06)",
          }}
        >
          Abschicken
        </Button>
      </Stack>
      <ConfirmDialog
        isOpen={submitConfirmDialogOpen}
        cancelText="Schliessen"
        submitText="Speichern"
        title="Alle Dokumente hochgeladen? Nach Absenden der Unterlagen können keine Änderungen mehr gemacht werden!"
        toggleOpen={toggleSubmitDialog}
        onSubmit={() => saveDocuments(true)}
        onCancel={toggleSubmitDialog}
      />
      <ConfirmDialog
        isOpen={cancelConfirmDialogOpen}
        cancelText="Verwerfen"
        submitText="Speichern"
        title="Möchten Sie die hochgeladenen Dokumente speichern oder alles verwerfen?"
        toggleOpen={toggleCancelDialog}
        onSubmit={() => saveDocuments(false)}
        onCancel={navigateToDashboard}
      />
    </>
  );
};

export default UploadTask;
