import { EmojiEmotions as EmojiEmotionsIcon } from "@mui/icons-material";
import { Badge } from "@mui/material";
import IconButton from "../IconButton";

export interface IStatusIconProps {
  color: string;
  disabled?: boolean;
}

const StatusIcon = ({ color, disabled }: IStatusIconProps) => {
  return (
    <IconButton
      disabled
      size="small"
      color="primary"
      sx={{
        backgroundColor: disabled ? "rgba(0, 0, 0, 0.26) !important" : "blue !important",
        padding: "0 !important",
        minWidth: "26px !important",
        height: "26px !important",
        width: "26px",
      }}
      icon={
        <Badge
          variant="dot"
          overlap="circular"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          sx={{
            "> .BaseBadge-badge": {
              top: "3px",
              right: "4px",
              height: "7px",
              minWidth: "7px",
              border: "1.5px solid blue",
              backgroundColor: color,
              display: disabled ? "none" : "",
            },
          }}
        >
          <EmojiEmotionsIcon
            sx={{
              width: "20px !important",
              height: "20px !important",
            }}
          />
        </Badge>
      }
    />
  );
};

export default StatusIcon;
