import { BorderColorSharp as BorderColorSharpIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { IDocument } from "../../../types";
import Chip from "../../Atoms/Chip";
import { CategoryTranslation, TypeTranslation } from "../DocumentOverview/translations";

interface IProps {
  document: IDocument;
}

const DocumentHeader = ({ document }: IProps) => {
  const translatedCategory = CategoryTranslation.hasOwnProperty(document?.category)
    ? CategoryTranslation[document?.category]
    : document?.category;

    const translatedType = TypeTranslation.hasOwnProperty(document?.type)
    ? TypeTranslation[document?.type]
    : document?.type;
  return (
    <Stack direction="column">
      <Typography variant="caption">Dateiname: {document?.name}</Typography>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column" gap="4px">
          {document?.category && (
            <Chip
              label={translatedCategory}
              bgcolor="#7A86EC"
              color="white"
              padding="1.5px 5.5px"
            />
          )}
          {document?.type && (
            <Chip
              label={translatedType}
              bgcolor="#CFCFCF"
              color="white"
              padding="1.5px 5.5px"
            />
          )}
        </Stack>
        <div
          style={{
            backgroundColor: "#FFD60A",
            width: "22px",
            height: "22px",
            padding: "4px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BorderColorSharpIcon
            sx={{ width: "12px", height: "12px", color: "white" }}
          />
        </div>
      </Stack>
    </Stack>
  );
};

export default DocumentHeader;
