import {Box, Modal, Paper, Typography as MuiTypography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ImageContainer from "../ImageContainer";
import HelperImage from "../../../../assets/images/tax.png";
import React from "react";

export interface Props {
	open?: boolean,
	setOpen?: Function,
}

const Tax = ({
							 open = false, setOpen = () => {
	}
						 }: Props) => {
	return <Modal
		open={open}
		onClose={() => setOpen(false)}
		aria-labelledby="parent-modal-title"
		aria-describedby="parent-modal-description"
		style={styles.containerModal}
	>
		<Paper
			style={{
				width: '327px',
				backgroundColor: 'white',
				borderRadius: '20px',
				padding: '24px',
				overflow: 'auto',
			}}>
			<Box style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-between'}}>
				<MuiTypography id="modal-modal-title" variant="h6"
											 style={{fontSize: '18px'}}>
					Wo finde Sie Ihre Steuernummer?
				</MuiTypography>
				<CloseIcon color="primary" onClick={() => setOpen(false)}/>
			</Box>
			<Box>
				<ImageContainer
					src={HelperImage}
					marginTopMd="20px"
					marginTopSm="20px"
				/>
				<MuiTypography>
					Wohnsitz in Deutschland hat, und ist eine elfstellige Ziffer, bei der genau eine Zahl
					doppelt vorkommt.

					Die Steuernummer ist 13-stellig und sieht vom Aufbau her überall in Deutschland zwar
					ähnlich aus, ist aber je nach Bundesland in einigen kleinen Details verschieden.

					Die Steuer-Identifikationsnummer haben im Jahr 2008 alle rund 82 Millionen Einwohner
					Deutschlands per Post vom Bundeszentralamt für Steuern erhalten. Das Schreiben hat den
					Betreff „Zuteilung der Identifikationsnummer nach § 139b der Abgabenordnung (AO)“ und die
					persönliche Identifikationsnummer steht fett rechts im oberen Drittel. Wenn Sie das
					Schreiben von vor zehn Jahren nicht mehr haben, schauen Sie bitte auf Seite 1 Ihres
					letzten Steuerbescheids: dort steht die Steuer-Identifikationsnummer links oben.

					Nicht mehr auffindbar?
					Die Steuer-Identifikationsnummer können Sie sich erneut vom Bundeszentralamt für Steuern
					zuschicken lassen. Am einfachsten geht das online.
				</MuiTypography>
			</Box>
		</Paper>
	</Modal>
};

const styles = {
	containerModal: {
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		flex: 1,
		overflow: 'scroll',
		height: '100%',
	},
}

export default Tax
