// TODO: move translations to one file

const CategoryTranslation: any = {
  INCOME_TAX: "Einkommensteuererklärung",
  FINANCIAL_ACCOUNTING: "Finanzbuchhaltung",
  TAXES: "Steuern",
  MAILBOX: "Postkorb",
  BASE_RECORD: "Stammakte",
  ANNUAL_ACCOUNTS: "Jahresabschluss ",
  CONSULTING: "Beratung",
  PAYROLL_ACCOUNTING: "Lohnbuchhaltung",
};

export default CategoryTranslation;
