import { Info as InfoIcon } from "@mui/icons-material";
import {
  Grid as MuiGrid,
  InputAdornment as MuiInputAdornment,
  TextField as MuiTextField,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Smile from "../../../../assets/svg/user.svg";
import ImageContainer from "../ImageContainer";

const endIcon = (
  <MuiInputAdornment position="end">
    <InfoIcon />
  </MuiInputAdornment>
);

const Step3 = () => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const width = isSmallDevice ? "327px" : "440px";

  return (
    <>
      <ImageContainer
        src={Smile}
        marginTopMd="86px"
        marginTopSm="122px"
        marginBottomMd="6px"
        marginBottomSm="6px"
      />
      <MuiGrid
        item
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px" }}
      >
        <MuiTypography
          align="center"
          variant="body1"
          color="rgba(61, 65, 77, 0.5)"
        >
          Herr Michael Müller
        </MuiTypography>
        <MuiTypography
          style={{ maxWidth: "327px" }}
          align="center"
          variant="h6"
        >
          Ich benötige weitere Informationen zu Deinem Partner:in.
        </MuiTypography>
      </MuiGrid>
      <MuiGrid
        sx={{ marginTop: "40px" }}
        display="flex"
        flexDirection="column"
        spacing="12px"
      >
        <MuiGrid item>
          <MuiTextField style={{ width }} placeholder="Anrede" />
        </MuiGrid>
        <MuiGrid item>
          <MuiTextField
            style={{ width, marginTop: "12px" }}
            placeholder="Vorname "
          />
        </MuiGrid>
        <MuiGrid item>
          <MuiTextField
            style={{ width, marginTop: "12px" }}
            placeholder="Nachname"
          />
        </MuiGrid>
        <MuiGrid item>
          <MuiTextField
            sx={{ width, marginTop: "12px" }}
            placeholder="Geburtsdatum"
            InputProps={{
              endAdornment: endIcon,
            }}
          />
        </MuiGrid>
        <MuiGrid item>
          <MuiTypography
            style={{ marginTop: "40px" }}
            align="center"
            variant="h6"
          >
            Wie lautet die Steuer-ID?
          </MuiTypography>
          <MuiTextField
            sx={{ width, marginTop: "12px" }}
            placeholder="DE1234567891"
            InputProps={{
              endAdornment: endIcon,
            }}
          />
          <MuiTypography
            style={{ marginTop: "40px", maxWidth: "327px" }}
            align="center"
            variant="h6"
          >
            Seit wann sind Sie verheiratet?
          </MuiTypography>
          <MuiTextField
            sx={{ width, marginTop: "12px" }}
            placeholder="21.03.2012"
            InputProps={{
              endAdornment: endIcon,
            }}
          />
        </MuiGrid>
      </MuiGrid>
    </>
  );
};

export default Step3;
