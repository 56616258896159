import {
  Container as MuiContainer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IStore } from "../../../store/types";
import { IClient } from "../../../types";
import BreadcrumbPath from "./BreadcrumbPath";

const Breadcrumbs = () => {
  const path = useLocation().pathname || "";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { documentId } = useParams();
  const locationPaths = path.split("/").filter((path) => path !== "");

  const currentClient: IClient = useSelector(
    (state: IStore) => state.currentClient
  );

  const task = useSelector((store: IStore) => store.task.task);

  const breadcrumbPathsMapping = {
    "user-profile": [
      {
        text: "User profil",
        hasPrefix: true,
        isHighLight: true,
      },
    ],
    clients: [
      {
        text: "Stammdaten",
        hasPrefix: true,
        isHighLight: true,
      },
      {
        ...(currentClient && {
          text: currentClient.name,
          hasPrefix: true,
          isHighLight: true,
        }),
      },
    ],
    "role-detail": [
      {
        text: "Stammdaten",
        hasPrefix: true,
        isHighLight: true,
      },
      {
        ...(currentClient && {
          text: currentClient.name,
          hasPrefix: true,
          isHighLight: true,
        }),
      },
      {
        text: "Tilman Walch",
        hasPrefix: true,
        isHighLight: true,
      },
    ],
    taskoverview: [
      {
        text: "Aufgaben",
        hasPrefix: true,
        isHighLight: true,
      },
    ],
    task: [
      {
        text: "Aufgaben",
        hasPrefix: true,
        isHighLight: true,
      },
      {
        ...(task && {
          text: task.name,
          hasPrefix: true,
          isHighLight: true,
        }),
      },
    ],
    documentoverview: [
      {
        text: "Dokumente",
        hasPrefix: true,
        isHighLight: true,
      },
    ],
    document: [
      {
        text: "Dokumente",
        hasPrefix: true,
        isHighLight: true,
      },
      {
        text: documentId ?? "Dokumente",
        hasPrefix: true,
        isHighLight: true,
      },
    ],
    taskoverviewinsert: [
      {
        text: "Task overview insert",
        hasPrefix: true,
        isHighLight: true,
      },
    ],
  };

  return (
    <MuiContainer
      disableGutters={true}
      sx={{
        height: "24px",
        padding: "0 16px",
        marginBottom: isMobile ? "12px" : "20px",
        lineHeight: isMobile ? 1.5 : 1.75,
        "> span": {
          fontSize: isMobile ? "10px" : "12px",
          fontFamily: "Sen",
          fontWeight: 400,
          lineHeight: "143%",
          letterSpacing: "0.17px",
        },
      }}
    >
      <BreadcrumbPath
        text="milia"
        onClick={() => navigate("/")}
        hasPrefix={false}
        isHighLight={locationPaths.length === 0}
      />
      {Object.entries(breadcrumbPathsMapping).map(
        ([breadcrumbPathMapping, breadcrumbPaths]) => {
          if (!locationPaths.includes(breadcrumbPathMapping)) return null;

          return breadcrumbPaths.map((breadcrumbPath, index) => {
            return <BreadcrumbPath {...breadcrumbPath} key={index} />;
          });
        }
      )}
    </MuiContainer>
  );
};

export default Breadcrumbs;
