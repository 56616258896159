import { Info as InfoIcon } from "@mui/icons-material";
import {
  Box,
  Button as MuiButton,
  Grid as MuiGrid,
  InputAdornment as MuiInputAdornment,
  MenuItem,
  Modal,
  TextField as MuiTextField,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Smile from "../../../../assets/svg/user.svg";
import { updateStep } from "../../../../store/slices/onboarding";
import Container from "../Container";
import ImageContainer from "../ImageContainer";

const endIcon = (
  <MuiInputAdornment position="end">
    <InfoIcon />
  </MuiInputAdornment>
);

const Step4 = () => {
  const dispatch = useDispatch();

  const goToStep3 = () => {
    dispatch(updateStep(3));
  };

  const goToStep5 = () => {
    dispatch(updateStep(5));
  };

  const [open, setOpen] = useState(false);
  const [relationship, setRelationship] = useState("0");
  const handleClose = () => setOpen(false);
  const openModal = () => setOpen(true);

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const width = isSmallDevice ? "327px" : "440px";

  return (
    <Container totalSteps={9} withBackground={false} activeStep={4}>
      <ImageContainer
        src={Smile}
        marginTopMd="86px"
        marginTopSm="122px"
        marginBottomMd="6px"
        marginBottomSm="6px"
      />
      <MuiGrid
        item
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px" }}
      >
        <MuiTypography
          align="center"
          variant="body1"
          color="rgba(61, 65, 77, 0.5)"
        >
          Herr Michael Müller
        </MuiTypography>
        <MuiTypography
          style={{ maxWidth: "327px" }}
          align="center"
          variant="h6"
        >
          Ich benötige einige Informationen über Ihre Kinder.
        </MuiTypography>
      </MuiGrid>
      <MuiGrid
        sx={{ marginTop: "40px" }}
        display="flex"
        flexDirection="column"
        spacing="12px"
      >
        <MuiGrid item>
          <MuiTextField
            style={{ width, marginTop: "12px" }}
            placeholder="Vorname "
          />
        </MuiGrid>
        <MuiGrid item>
          <MuiTextField
            style={{ width, marginTop: "12px" }}
            placeholder="Nachname"
          />
        </MuiGrid>
        <MuiGrid item>
          <MuiTextField
            sx={{ width, marginTop: "12px" }}
            placeholder="Geburtsdatum"
            InputProps={{
              endAdornment: endIcon,
            }}
          />
        </MuiGrid>
        <MuiGrid item>
          <MuiTypography
            style={{ marginTop: "40px" }}
            align="center"
            variant="h6"
          >
            Wie ist Ihr Verhätnis zum Kind?
          </MuiTypography>
          <MuiTextField
            value={relationship}
            select
            sx={{ width, marginTop: "12px" }}
          >
            <MenuItem
              onClick={() => setRelationship("0")}
              value="0"
              className="SelectMenuItem"
            >
              Leibliches Kinderbeziehung
            </MenuItem>
            <MenuItem
              onClick={() => setRelationship("1")}
              value="1"
              className="SelectMenuItem"
            >
              Leibliches Kinderbeziehung
            </MenuItem>
          </MuiTextField>
        </MuiGrid>
        <MuiGrid style={{ marginBottom: "80px" }} item>
          <MuiButton
            sx={{ width, marginTop: "36px" }}
            color="primary"
            onClick={openModal}
          >
            weiter
          </MuiButton>
        </MuiGrid>
      </MuiGrid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={styles.containerModal}
      >
        <Box
          style={{
            width: "340px",
            backgroundColor: "white",
            borderRadius: "20px",
            padding: "24px",
          }}
        >
          <MuiTypography
            id="modal-modal-title"
            variant="subtitle1"
            style={{ textAlign: "center", fontSize: "18px" }}
          >
            Haben Sie weiteres Kind?
          </MuiTypography>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 24,
            }}
          >
            <MuiButton
              sx={{ width: "95px" }}
              color="primary"
              onClick={goToStep3}
            >
              ja
            </MuiButton>
            <MuiButton
              sx={{ width: "95px", marginLeft: "10px" }}
              color="secondary"
              onClick={goToStep5}
            >
              nein
            </MuiButton>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

const styles = {
  containerModal: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flex: 1,
  },
};

export default Step4;
