const DurationTranslation : any = {
    NONE: "",
    // Monthly
    JANUAR: "Januar",
    FEBRUAR: "Februar",
    MAERZ: "März",
    APRIL: "April",
    MAI: "Mai",
    JUNI: "Juni",
    JULI: "Juli",
    AUGUST: "August",
    SEPTEMBER: "September",
    OKTOBER: "Oktober",
    NOVEMBER: "November",
    DEZEMBER: "Dezember",
  
    // Quarterly
    Q1: "1. Quartal",
    Q2: "2. Quartal",
    Q3: "3. Quartal",
    Q4: "4. Quartal",
    // Half yearly
    H1: "1. Halbjahr",
    H2: "2. Halbjahr"
}

export default DurationTranslation;