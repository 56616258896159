import { Chip, Stack, Typography } from "@mui/material";
import { ITask } from "../../../types";

interface IProps {
  data: ITask;
}

const TaskInfoHeader = (props: IProps) => {
  const { id, status } = props.data;
  const isActive = status === 'ACTIVE';

  return (
    <>
      <Stack direction={"column"} spacing={1}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="caption">Aufgaben Id {id}</Typography>
        </Stack>
        <Stack direction={"row"}>
          <Chip
            sx={{
            ".MuiChip-label": {
              color: isActive ? "#3D414D" : "#FF0000",
            }
          }} label={status} color="secondary" size="small" />
        </Stack>
      </Stack>
    </>
  );
};

export default TaskInfoHeader;
