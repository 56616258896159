import {
  GridViewSharp as GridViewSharpIcon,
  Person as PersonIcon,
  Task as TaskIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Menu,
  Stack,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IStore } from "../../../store/types";
import { IClient } from "../../../types";
import IconButton from "../IconButton";

export const NavigationMenu = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const currentClient: IClient = useSelector(
    (state: IStore) => state.currentClient
  );
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const NavItemAvatar = styled(Avatar)(({ theme }) => ({
    width: 32,
    height: 32,
    backgroundColor: theme.palette.common.white,
    cursor: "pointer",
    boxShadow: theme.shadows[4],
  }));

  return (
    <>
      <IconButton
        disabled
        id="nav-menu"
        data-testid="nav"
        onClick={handleClick}
        icon={
          <GridViewSharpIcon sx={{ color: isMobile ? "#1129F5" : "white" }} />
        }
        size="small"
        sx={{
          boxShadow: isMobile ? theme.shadows[4] : "none",
          backgroundColor: isMobile ? theme.palette.common.white : "#1129F5",
          "&.Mui-disabled > .MuiButton-startIcon > .MuiSvgIcon-root": {
            color: theme.palette.common.white,
          },
        }}
      />

      <Menu
        id="basic-menu"
        data-testid="navMenu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        keepMounted
        PaperProps={{
          sx: { backgroundColor: "transparent", overflow: "visible" },
        }}
        elevation={0}
        sx={{ mt: { xs: 0.5, xl: 2.6 } }}
      >
        <Stack direction={"column"} spacing={1.5}>
          <NavItemAvatar>
            <TaskIcon color="primary" />
          </NavItemAvatar>
          <NavItemAvatar
            onClick={() => navigate(`clients/${currentClient?.id}`)}
          >
            <PersonIcon color="primary" />
          </NavItemAvatar>
        </Stack>
      </Menu>
    </>
  );
};
