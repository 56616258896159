import { Container, Grid, Stack, Typography } from "@mui/material";
import BoxMilia from "../../Atoms/BoxMilia";

const TaskNotFound = () => {
  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack justifyContent={"center"} alignItems="center" mt="24px">
            <BoxMilia
              sx={{
                height: "72px",
                width: "80%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">Task Not found</Typography>
            </BoxMilia>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TaskNotFound;
