import { useSnackbar } from "notistack";
import React from "react";
import { useSelector } from "react-redux";
import { IStore } from "../../store/types";
import { IClient, IDocument } from "../../types";
import { useFetchData } from "../fetchData";

export interface IDocumentFilter {
  size: number | string;
  page: number | string;
  sort: string | string;
  days: number | string;
  category: string;
  type: string;
}

const useClientDocumentService = () => {
  const currentClient: IClient = useSelector(
    (state: IStore) => state.currentClient
  );
  const { fetchData } = useFetchData();
  const { enqueueSnackbar } = useSnackbar();

  const [clientDocument, setClientDocument] = React.useState<IDocument>();
  const [clientDocuments, setClientDocuments] = React.useState<any>();
  const [clientDocumentsFilter, setClientDocumentsFilter] =
    React.useState<any>();

  // for mandate change
  const [selectedCurrentClient, setSelectedCurrentClient] =
    React.useState<any>();
  const [selectedCurrentFilter, setSelectedCurrentFilter] =
    React.useState<any>();

  React.useEffect(() => {
    if (currentClient && currentClient.id !== selectedCurrentClient?.id) {
      setSelectedCurrentClient(currentClient);
      // switch the tasks
      if (selectedCurrentFilter) {
        readClientDocuments({ filter: selectedCurrentFilter });
      }
    }
  }, [currentClient]);

  const readClientDocumentDownload = async ({
    documentId,
  }: {
    documentId: number;
  }) => {
    try {
      const res = await fetchData(
        `clients/${currentClient.id}/documents/${documentId}/download`,
        {
          responseType: "blob",
        }
      );
      return URL.createObjectURL(res.data);
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  const readClientDocument = async ({ documentId }: { documentId: number }) => {
    const res = await fetchData(
      `clients/${currentClient.id}/documents/${documentId}`
    );
    setClientDocument(res.data);
    return res.data;
  };

  const readClientDocuments = async ({
    filter,
  }: {
    filter: IDocumentFilter;
  }) => {
    const res = await fetchData(
      `clients/${currentClient.id}/documents?size=${filter.size}&page=${filter.page}&sort=${filter.sort}&days=${filter.days}&category=${filter.category}&type=${filter.type}`
    );
    setSelectedCurrentFilter(filter);
    setClientDocuments(res.data);
    return res.data;
  };

  const readClientDocumentsFilter = async () => {
    const res = await fetchData(`clients/${currentClient.id}/documents-filter`);

    setClientDocumentsFilter(res.data);
    return res.data;
  };

  return {
    readClientDocument,
    readClientDocumentDownload,
    readClientDocuments,
    readClientDocumentsFilter,
    // state
    clientDocument,
    clientDocuments,
    clientDocumentsFilter,
  };
};

export default useClientDocumentService;
