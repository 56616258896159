import { Stack } from "@mui/material";
import {avatarMichel, avatarTilman, avatarVincent} from "../../../utils";

const TaskAssign = () => {
  return (
    <Stack direction={"row"} spacing={1}>
      <img src={avatarMichel} style={{ borderRadius: '16px' }} width="32px" height="32px" alt="profile" />
      <img src={avatarTilman} style={{ borderRadius: '16px' }} width="32px" height="32px" alt="profile" />
      <img src={avatarVincent} style={{ borderRadius: '16px' }} width="32px" height="32px" alt="profile" />
      <img src={avatarMichel} style={{ borderRadius: '16px' }} width="32px" height="32px" alt="profile" />
      <img src={avatarTilman} style={{ borderRadius: '16px' }} width="32px" height="32px" alt="profile" />
    </Stack>
  );
};

export default TaskAssign;
