import AddCommentRoundedIcon from "@mui/icons-material/AddCommentRounded";
import { Box, Button, Dialog, Grid, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IChatMessage } from "../../ressources/interfaces";
import useClientTaskService from "../../services/fetch/useClientTaskService";
import { useFetchData } from "../../services/fetchData";
import { IStore } from "../../store/types";
import { IClient, ITask } from "../../types";
import { avatarVincent } from "../../utils";
import Chat from "../Atoms/Chat";
import { DateDisplay } from "../Atoms/DateDisplay";
import { errorMessages, FileUpload } from "../Atoms/Upload";
import TaskInfoHeader from "../Molecules/TaskOverview/TaskInfoHeader";

interface IProps {
  taskData: ITask;
}

export const AdhocTask = ({ taskData }: IProps) => {
  const currentClient: IClient = useSelector(
    (state: IStore) => state.currentClient
  );
  const { readClientTaskMessages, clientTaskMessages } = useClientTaskService();
  const { fetchData, postDocument } = useFetchData();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [chatMessages, setChatMessages] = useState<Array<IChatMessage>>([]);
  const [chatDialogOpen, setChatDialogOpen] = useState<boolean>(false);
  const [newMessagesCount, setNewMessagesCount] = useState<number>(0);

  // TODO: Move to useClientTaskService
  const countNewChatMessages = (messages: Array<IChatMessage>) => {
    let count = 0;
    for (let i = messages.length - 1; i >= 0; i--) {
      if (messages[i].sentBy === "TAX_OFFICE") count = count + 1;
      else break;
    }
    setNewMessagesCount(count);
  };

  useEffect(() => {
    const fetchChatMessages = async () => {
      await readClientTaskMessages({
        taskId: taskData.id,
      });
    };
    fetchChatMessages();
  }, []);

  useEffect(() => {
    if (clientTaskMessages.length) {
      setChatMessages(clientTaskMessages);
      countNewChatMessages(clientTaskMessages);
    }
  }, [clientTaskMessages]);

  const [documentToupload, setDocumentToupload] = useState<
    Array<{ file: File; groupId: number }>
  >([]);
  const [documentToDelete, setDocumentToDelete] = useState<
    Array<{ id: number; groupId: number }>
  >([]);

  const toggleChatDialog = () => {
    setChatDialogOpen(!chatDialogOpen);
    if (newMessagesCount > 0) setNewMessagesCount(0);
  };

  const save = () => {
    const promisesDelete = documentToDelete.map(
      (doc: { id: number; groupId: number }) => {
        const url = `clients/${currentClient.id}/tasks/${taskData.id}/groups/${doc.groupId}/documents/${doc.id}`;
        return fetchData(url, { method: "DELETE" });
      }
    );

    const updateUrl = `clients/${currentClient.id}/tasks/${taskData.id}`;
    Promise.all([...promisesDelete])
      .then(() => {
        const promisesUpload = documentToupload.map(
          (doc: { file: File; groupId: number }) => {
            const url = `clients/${currentClient.id}/tasks/${taskData.id}/groups/${doc.groupId}/documents`;
            const formData = new FormData();
            formData.append("file", doc.file);
            return postDocument(url, formData);
          }
        );
        Promise.all([...promisesUpload])
          .then(() =>
            fetchData(updateUrl, {
              method: "PATCH",
              data: { status: "ACTIVE" },
            })
          )
          .then(() => {
            enqueueSnackbar("Task updated", { variant: "success" });
            navigate(`/`);
          })
          .catch((err) => {
            enqueueSnackbar(
              errorMessages[err.response.data.message]
                ? errorMessages[err.response.data.message]
                : err.message,
              {
                variant: "error",
              }
            );
            navigate(`/task/${taskData.id}`);
          });
      })
      .catch((err) => {
        enqueueSnackbar(
          errorMessages[err.response.data.message]
            ? errorMessages[err.response.data.message]
            : err.message,
          {
            variant: "error",
          }
        );
        navigate(`/task/${taskData.id}`);
      });
  };

  return (
    <>
      <TaskInfoHeader data={taskData} />
      <Grid container mt={3}>
        <Grid item md={12} lg={6} xl={5}>
          <Box sx={{ maxWidth: "388px" }}>
            <Typography variant="h6" maxWidth={300} mt={1}>
              {taskData.name}
            </Typography>

            <Typography sx={{ wordWrap: "break-word" }} variant="body2" mt={1}>
              {taskData.description}
            </Typography>
            <Stack direction="row" gap={2} mt={5}>
              <DateDisplay
                label="Erstellungsdatum"
                disabled
                displayValue={new Date(taskData.creationDate)}
              />
              <DateDisplay
                label="Ablaufdatum"
                disabled
                displayValue={
                  taskData.deadlineDate
                    ? new Date(taskData.deadlineDate)
                    : undefined
                }
              />
            </Stack>
            <Stack direction="row" justifyContent="space-between" mt={4}>
              <Stack direction="column">
                <Typography variant="caption">Erstellt von</Typography>
                <img
                  src={avatarVincent}
                  style={{ borderRadius: "16px" }}
                  width="32px"
                  height="32px"
                  alt="profile"
                />
              </Stack>
              <Button
                color="primary"
                onClick={toggleChatDialog}
                startIcon={
                  <AddCommentRoundedIcon
                    sx={{ color: "white", transform: "rotateY(180deg)" }}
                  />
                }
                sx={{ display: { lg: "none" } }}
              >
                {newMessagesCount > 0
                  ? `Dialog +${newMessagesCount}`
                  : "Dialog"}
              </Button>
              <Dialog open={chatDialogOpen} onClose={toggleChatDialog}>
                {chatMessages && (
                  <Chat
                    toggleDialog={toggleChatDialog}
                    taskName={taskData.name}
                    messages={chatMessages}
                    setChat={setChatMessages}
                    taskId={taskData.id}
                  />
                )}
              </Dialog>
            </Stack>
            <Box mt={6}>
              {taskData && (
                <FileUpload
                  filesToUpload={setDocumentToupload}
                  filesToDelete={setDocumentToDelete}
                  groups={taskData.groups}
                />
              )}
            </Box>
            <Stack
              style={{ justifyContent: "flex-end" }}
              direction="row"
              mt={9}
            >
              <Button onClick={() => navigate("/")}>Schliessen</Button>
              <Button
                color="primary"
                sx={{
                  marginLeft: "12px",
                  boxShadow:
                    "0px 4px 8px -2px rgba(42, 51, 60, 0.15), 0px 6px 16px -2px rgba(42, 51, 60, 0.06)",
                }}
                onClick={save}
              >
                Speichern
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          lg={6}
          xl={5}
          sx={{
            display: { xs: "none", lg: "block" },
          }}
        >
          <Typography variant="h6">Dialog Mandant & Kanzlei</Typography>
          {chatMessages && (
            <Chat
              messages={chatMessages}
              setChat={setChatMessages}
              taskId={taskData.id}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AdhocTask;
