import {
  Badge,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ReactComponent as ChatIcon } from "../../../assets/svg/chat.svg";
import { IDocument } from "../../../types";
import { DocumentTableContent } from "./DocumentTableContent";

interface IProps {
  data: Array<IDocument>;
  parentWidth: number;
}

export const DocumentTable = ({ data, parentWidth }: IProps) => {
  return (
    <Table sx={{ tableLayout: "fixed", marginTop: "24px", borderSpacing: "0 5px", borderCollapse: "separate" }}>
      <TableHead>
        <TableRow>
          <TableCell align="left" width="30%">
            <Typography
              variant="body2"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              Name
            </Typography>
          </TableCell>
          <TableCell align="center" width="20%">
            <Typography
              variant="body2"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              Kategorie
            </Typography>
          </TableCell>
          {(parentWidth > 375 || parentWidth === 0) && (
            <TableCell align="center" width="20%">
              <Typography
                variant="body2"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                Typ
              </Typography>
            </TableCell>
          )}
          <TableCell align="center" width="15%">
            <Typography
              variant="body2"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              Datum
            </Typography>
          </TableCell>
          <TableCell align="center" width="7.5%">
            <Badge
              variant="dot"
              overlap="circular"
              color="secondary"
              sx={{
                top: "20%",
                right: "20%",
              }}
            >
              <ChatIcon />
            </Badge>
          </TableCell>
          <TableCell align="center" width="7.5%"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <DocumentTableContent data={data} parentWidth={parentWidth} />
      </TableBody>
    </Table>
  );
};
