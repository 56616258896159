import { Error as ErrorIcon } from "@mui/icons-material";
import { Tooltip as MuiTooltip } from "@mui/material";

export interface IMessageInfos {
  [k: string]: any;
}

export const MSG_INFOS: IMessageInfos = {
  street: "Strasse eingeben",
  zipcode: "PLZ eingeben",
  place: "Ort eingeben",
  website: "Webseit eingeben",
  email: (
    <div>
      Email This is error description
      <br />
      for Further description
    </div>
  ),
  phone: "Telefon eingeben",
};

const StammDatenErrorMsgs = ({ error }: { error: keyof IMessageInfos }) => (
  <MuiTooltip title={MSG_INFOS[error]} sx={{ whiteSpace: "pre-line" }}>
    <ErrorIcon />
  </MuiTooltip>
);

export default StammDatenErrorMsgs;
