import { AnyObject } from "yup/lib/types";

export enum EClientType {
  "NATURAL_PERSON" = "Natürliche Person",
  "COMPANY" = "Unternehmen",
}

export interface IClientProfileFormInputs {
  name: string;
  street: string;
  zipcode: string;
  place: string;
  website: string;
  email: string;
  phone: string;
}

export interface IUser {
  id?: number;
  email?: string;
  avatarURL: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  phone: string;
  clientSince: string;
}

export interface IService {
  id: number;
  clientId: number;
  taxOfficeId: number;
  name: string;
  fee: number;
  payInterval: string;
}

export interface IClient {
  id: number;
  taxOfficeId: number;
  datevId: string;
  name: string;
  street: string;
  zipcode: string;
  place: string;
  website: string;
  email: string;
  branch: string;
  phone: string;
  taxId: number;
  type: string;
  clientSince: string;
  services: Array<IService>;
  permission: string;
  permissionGroup: string;
}

export interface ITask {
  id: number;
  clientId: number;
  userId: number;
  status: string;
  name: string;
  nameOfMatter: string;
  description: string;
  creationDate: string;
  deadlineDate?: string;
  groups: Array<ITaskGroup>;
  type?: string;
  payload?: AnyObject;
}

export interface ITaskGroup {
  id: number;
  name: string;
  documents: Array<IDocument>;
}

// export interface IDocument {
//   id: number;
//   name: string;
//   creationDate: string;
//   category?: string;
//   type?: string;
// }

export interface IDocument {
  id: number;
  name: string;
  category: string;
  type: string;
  creationDate: string;
  downloadPath: string;
  details?: string;
}
