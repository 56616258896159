import { Info as InfoIcon } from "@mui/icons-material";
import {
  Button as MuiButton,
  Grid as MuiGrid,
  InputAdornment as MuiInputAdornment,
  TextField as MuiTextField,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Smile from "../../../../assets/svg/user.svg";
import {
  updateIsChild,
  updateIsWife,
  updateStep,
} from "../../../../store/slices/onboarding";
import { IStore } from "../../../../store/types";
import Toggle from "../../../Atoms/Toggle";
import Container from "../Container";
import ImageContainer from "../ImageContainer";

const endIcon = (
  <MuiInputAdornment position="end">
    <InfoIcon />
  </MuiInputAdornment>
);

const Step2 = () => {
  const isWife = useSelector((state: IStore) => state.onboarding.isWife);
  const isChild = useSelector((state: IStore) => state.onboarding.isChild);
  const dispatch = useDispatch();
  const theme = useTheme();

  const goToNextStep = useCallback(() => {
    if (isWife) {
      dispatch(updateStep(3));
    } else if (isChild) {
      dispatch(updateStep(4));
    } else {
      dispatch(updateStep(5));
    }
  }, [isWife, isChild]);
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const width = isSmallDevice ? "327px" : "440px";

  const onToggleWife = () => {
    dispatch(updateIsWife(!isWife));
  };
  const onToggleChild = () => {
    dispatch(updateIsChild(!isChild));
  };

  return (
    <Container totalSteps={9} withBackground={false} activeStep={2}>
      <ImageContainer
        src={Smile}
        marginTopMd="86px"
        marginTopSm="122px"
        marginBottomMd="6px"
        marginBottomSm="6px"
      />
      <MuiGrid item alignItems="center" justifyContent="center">
        <MuiTypography
          align="center"
          variant="body1"
          color="rgba(61, 65, 77, 0.5)"
        >
          Herr Michael Müller
        </MuiTypography>
        <MuiTypography style={{ maxWidth: width }} align="center" variant="h6">
          Ich benötige weitere Informationen zu Deinem Partner:in.
        </MuiTypography>
      </MuiGrid>
      <MuiGrid
        sx={{ marginTop: "40px" }}
        display="flex"
        flexDirection="column"
        spacing="12px"
      >
        <MuiGrid item>
          <MuiTextField
            style={{ maxWidth: width, width: "100%" }}
            placeholder="Straße & Hausnummer"
          />
        </MuiGrid>
        <MuiGrid item marginTop="12px">
          <MuiTextField sx={{ width: "98px" }} placeholder="PLZ" />
          <MuiTextField
            sx={{ width: isSmallDevice ? "221px" : "334px", marginLeft: "8px" }}
            placeholder="Ort"
          />
        </MuiGrid>
        <MuiGrid item>
          <MuiTextField
            style={{ maxWidth: width, width: "100%", marginTop: "12px" }}
            placeholder="E-Mail"
          />
        </MuiGrid>
        <MuiGrid item>
          <MuiTextField
            style={{ maxWidth: width, width: "100%", marginTop: "12px" }}
            placeholder="Telefon"
          />
        </MuiGrid>
        <MuiGrid item>
          <MuiTypography
            style={{ marginTop: "40px" }}
            align="center"
            variant="h6"
          >
            Wann sind Sie geboren worden?
          </MuiTypography>
          <MuiTextField
            sx={{ width, marginTop: "12px" }}
            placeholder="Geburtsdatum"
            InputProps={{
              endAdornment: endIcon,
            }}
          />
          <MuiTypography
            style={{ marginTop: "40px", maxWidth: width }}
            align="center"
            variant="h6"
          >
            Sind sie verheiratet oder haben Sie Kinder?
          </MuiTypography>
        </MuiGrid>
        <MuiGrid
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "14px",
          }}
        >
          <Toggle onClick={onToggleWife} label="verheiratet" />
          <Toggle onClick={onToggleChild} label="Kinder" />
        </MuiGrid>
        <MuiGrid style={{ paddingBottom: "80px" }} item>
          <MuiButton
            sx={{ width, marginTop: "36px" }}
            color="primary"
            onClick={goToNextStep}
          >
            weiter
          </MuiButton>
        </MuiGrid>
      </MuiGrid>
    </Container>
  );
};

export default Step2;
