import { Grid, Paper, styled, Theme, useMediaQuery } from "@mui/material";
import {
  Form,
  Formik,
  FormikConfig,
  FormikHelpers,
  FormikValues,
} from "formik";
import React, { useState } from "react";
import OnboardingBgMd from "../../../assets/images/onboardingBgMd.png";
import OnboardingBgSm from "../../../assets/images/onboardingBgSm.png";
import ProgressBarBottom from "../../Atoms/ProgressBarBottom";
import ProgressBarTop from "../../Atoms/ProgressBarTop";
import FormMultiStepNavigation, {
  IFormMultiStepNavigation,
} from "./FormMultiStepNavigation";

interface IFormMultiStepWrapper extends FormikConfig<FormikValues> {
  children: React.ReactNode;
  completed: boolean;
}

const PaperBackground = styled(Paper)<{ withBackground?: boolean }>(
  ({ theme, withBackground }) => ({
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top right",
    [theme.breakpoints.down("md")]: {
      minHeight: "inherit",
      width: "100%",
      borderRadius: 0,
      backgroundImage: withBackground ? `url(${OnboardingBgSm})` : "none",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "85vw",
      backgroundImage: withBackground ? `url(${OnboardingBgMd})` : "none",
    },
  })
);

const FormMultiStepWrapper = ({
  children,
  initialValues,
  onSubmit,
  completed,
}: IFormMultiStepWrapper) => {
  const isMdScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(
    children
  ) as React.ReactElement<IFormStepProps>[];

  // means what the current initalValue should be. It updates the initalValue thourgh each step
  const [snapShot, setSnapShot] = useState(initialValues);

  const totalSteps = steps.length;
  const lastStep = stepNumber === totalSteps - 1;
  const step = steps[stepNumber];

  const next = (values: FormikValues) => {
    setStepNumber(stepNumber + 1);
    setSnapShot(values);
  };

  const previous = (values: FormikValues) => {
    setStepNumber(stepNumber - 1);
    setSnapShot(values);
  };

  const handleSubmit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values);
    }

    if (lastStep) {
      return onSubmit(values, actions);
    } else {
      actions.setTouched({});
      next(values);
    }
  };

  return (
    <div>
      <Formik
        initialValues={snapShot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
      >
        {({ values }) => (
          <Form>
            <PaperBackground
              withBackground={step.props.withBackground}
              sx={{ height: "fit-content" }}
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ position: "relative" }}
              >
                {!isMdScreen && !step.props.hideProgress && (
                  <ProgressBarTop
                    totalSteps={totalSteps}
                    activeStep={stepNumber}
                  />
                )}
                {step}
                <FormMultiStepNavigation
                  handleBack={() => previous(values)}
                  activeStep={stepNumber}
                  {...step.props.MultiStepNavigationProps}
                />
                {isMdScreen && !step.props.hideProgress && (
                  <ProgressBarBottom
                    totalSteps={totalSteps}
                    activeStep={stepNumber}
                  />
                )}
              </Grid>
            </PaperBackground>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormMultiStepWrapper;

interface IFormStepProps {
  onSubmit?: (values: FormikValues) => Promise<void>;
  children: React.ReactNode;
  withBackground?: boolean;
  validationSchema?: any;
  hideProgress?: boolean;
  buttonLabelNext?: string;
  MultiStepNavigationProps?: Omit<
    IFormMultiStepNavigation,
    "handleBack" | "activeStep"
  >;
}

export const FormStep: React.FunctionComponent<IFormStepProps> = ({
  children,
}: any) => children;
