import {
  Badge,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../assets/css/theme";
import { ITask } from "../../../types";
import TaskMenu from "./TaskMenu";
import TaskStatus from "./TaskStatus";
import { ReactComponent as ChatIcon } from "../../../assets/svg/chat.svg";
import { arrayAvatar } from "../../../utils";

const TableContent = ({ tableData }: any) => {
  const navigate = useNavigate();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "50%" }}>
            <Typography variant="body2">Name</Typography>
          </TableCell>
          <TableCell sx={{ display: { xs: "none", md: "table-cell" } }}>
            <Typography variant="body2">Id</Typography>
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "none",
                md: "table-cell",
                width: "max-content",
              },
            }}
          >
            <Typography variant="body2">Datum / Zeit</Typography>
          </TableCell>
          <TableCell sx={{ display: { xs: "none", xl: "table-cell" } }}>
            <Typography variant="body2">Ablaufdatum</Typography>
          </TableCell>
          <TableCell>
            <Typography
              variant="body2"
              sx={{ display: { xs: "none", md: "inline" } }}
            >
              User
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              variant="body2"
              sx={{ display: { xs: "none", md: "inline" } }}
            >
              Dialog
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2"></Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tableData?.map((task: ITask) => (
          <TableRow key={task.id}>
            <TableCell>
              <Stack direction="row">
                <TaskStatus status={task.status} />
                <Typography
                  variant="body2"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  onClick={() => navigate(`/task/${task.id}`)}
                  sx={{ cursor: "pointer", width: "100%" }}
                >
                  {task.name}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell sx={{ display: { xs: "none", md: "table-cell" } }}>
              <Typography variant="body2">{task.id}</Typography>
            </TableCell>
            <TableCell sx={{ display: { xs: "none", md: "table-cell" } }}>
              <Typography variant="body2">{task.creationDate}</Typography>
            </TableCell>
            <TableCell sx={{ display: { xs: "none", xl: "table-cell" } }}>
              <Typography variant="body2">{task.deadlineDate}</Typography>
            </TableCell>
            <TableCell>
              <img src={arrayAvatar[task.clientId || 0]} style={{ borderRadius: '12px' }} width="24px" height="24px" alt="profile" />
            </TableCell>
            <TableCell>
              <Badge
                variant="dot"
                overlap="circular"
                color="secondary"
                sx={{
                  top: "20%",
                  right: "0%",
                  width: "24px",
                  height: "24px"
              }}
                >
              <ChatIcon />
            </Badge>
            </TableCell>
            <TableCell>
              <TaskMenu
                iconColor={theme.palette.primary.main}
                taskData={task}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TableContent;
