import { Box, Button, Grid } from "@mui/material";
import { FormikValues } from "formik";

export interface IFormMultiStepNavigation {
  handleBack: (values: FormikValues) => void;
  showBack?: boolean;
  showSkip?: boolean;
  buttonLabelNext?: string;
  activeStep: number;
}

const FormMultiStepNavigation = ({
  activeStep,
  showBack,
  showSkip,
  handleBack,
  buttonLabelNext = "weiter",
}: IFormMultiStepNavigation) => {
  return (
    <Box sx={{ width: "100%", mt: 5, mb: 5, pb: 5 }}>
      <Grid xs={12} container justifyContent="center">
        <Grid
          container
          item
          md={4}
          xs={12}
          justifyContent="center"
          {...(showSkip && { justifyContent: "space-evenly" })}
        >
          {showBack && (
            <Button disabled={activeStep === 0} onClick={handleBack}>
              zurück
            </Button>
          )}
          {showSkip && (
            <Grid xs={5}>
              <Button
                fullWidth
                // onClick={handleBack}
              >
                überspringen
              </Button>
            </Grid>
          )}
          <Grid xs={12} {...(showSkip && { xs: 5 })}>
            <Button type="submit" color="primary" fullWidth>
              {buttonLabelNext}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormMultiStepNavigation;

// <MuiGrid style={{ marginBottom: "80px" }}>
// <MuiButton
//   sx={{ width: "105px", marginRight: "20px" }}
//   onClick={goToStep2}
// >
//   überspringen
// </MuiButton>
// <MuiButton sx={{ width: "105px" }} color="primary" onClick={goToStep2}>
//   weiter
// </MuiButton>
// </MuiGrid>
