import { updateStep } from "../../../../store/slices/onboarding";
import { useDispatch } from "react-redux";
import {
	Grid as MuiGrid,
	Button as MuiButton,
	Typography as MuiTypography, IconButton, useMediaQuery, useTheme, Box,
} from "@mui/material";
import Container from "../Container";
import UploadProfile from "../../../../assets/images/uploadProfile.png";
import ImageContainer from "../ImageContainer";
import React, {useEffect, useState} from "react";

const Step1 = () => {
	const dispatch = useDispatch();
	const [selectedFile, setSelectedFile] = useState();
	const [preview, setPreview] = useState();
	const goToStep2 = () => {
		dispatch(updateStep(2));
	};
	useEffect(() => {
		if (!selectedFile) {
			setPreview(undefined);
			return
		}
		const objectUrl = URL.createObjectURL(selectedFile);
		// @ts-ignore
		setPreview(objectUrl)
		return () => URL.revokeObjectURL(objectUrl)
	}, [selectedFile]);

	const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(undefined)
			return
		}
		// @ts-ignore
		setSelectedFile(e.target.files[0])
	};
	const theme = useTheme();
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
	const marginTop = isSmallDevice ? '300px' : '176px';

	return <Container totalSteps={9} withBackground={false} activeStep={1}>
		<Box style={{marginTop, marginBottom: '30px'}}>
			<IconButton disableRipple color="primary" aria-label="upload picture" component="label">
				<input hidden onChange={(e) => onSelectFile(e)} accept="image/*" type="file"/>
				{selectedFile &&
					<img style={{width: '180px', height: '180px'}}
							 src={preview} alt="logo"/>}
				{!selectedFile &&
					<ImageContainer
						src={UploadProfile}
						marginTopMd="0px"
						marginTopSm="0px"
						marginBottomMd="0px"
						marginBottomSm="0px"
					/>}
			</IconButton>
		</Box>
		<MuiGrid
			item
			alignItems="center"
			justifyContent="center"
			sx={{ marginBottom: "40px" }}
		>
			<MuiTypography align="center" variant="h6">
				Laden Sie ein Profilfoto oder <br/>
				einen Avatar von sich hoch.
			</MuiTypography>
		</MuiGrid>
		<MuiGrid style={{paddingBottom: '80px'}}>
			<MuiButton
				sx={{ width: "105px", marginRight: "20px" }}
				onClick={goToStep2}
			>
				überspringen
			</MuiButton>
			<MuiButton
				sx={{ width: "105px" }}
				color="primary"
				onClick={goToStep2}
			>
				weiter
			</MuiButton>
		</MuiGrid>
	</Container>
};

export default Step1;
