import { styled, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { IStore } from "../../../store/types";
import { IUser } from "../../../types";
import BoxMilia from "../../Atoms/BoxMilia";

const WelcomeBox = styled(BoxMilia)(() => ({
  width: "100%",
  height: 72,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
}));

const WelcomeDashboard = () => {
  const user: IUser = useSelector((state: IStore) => state.user);
  return (
    <WelcomeBox>
      <Typography variant="h6">Willkommen {user.firstName}</Typography>
    </WelcomeBox>
  );
};

export default WelcomeDashboard;
