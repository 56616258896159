import {
  Button as MuiButton,
  Grid as MuiGrid,
  MenuItem,
  TextField as MuiTextField,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Company from "../../../../assets/images/company.png";
import { updateStep } from "../../../../store/slices/onboarding";
import Container from "../Container";
import ImageContainer from "../ImageContainer";

const Step2 = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const [country, setCountry] = useState("0");

  const goToStep3 = () => {
    dispatch(updateStep(3));
  };

  return (
    <Container withBackground={false} activeStep={2}>
      <ImageContainer
        src={Company}
        marginTopMd="86px"
        marginTopSm="122px"
        marginBottomMd="6px"
        marginBottomSm="6px"
      />
      <MuiGrid
        item
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: isSmallDevice ? "100px" : "40px" }}
      >
        <MuiTypography
          align="center"
          variant="body1"
          color="rgba(61, 65, 77, 0.5)"
        >
          Unternehmensname default
        </MuiTypography>
        <MuiTypography align="center" variant="h6">
          Bitte geben Sie <br />
          Ihre Firmenanschrift ein.
        </MuiTypography>
      </MuiGrid>
      <MuiGrid
        style={{ margin: "0px" }}
        display="flex"
        flexDirection="column"
        spacing="12px"
      >
        <MuiGrid item>
          <MuiTextField
            style={{ maxWidth: "327px", width: "100%" }}
            placeholder="Straße & Hausnummer"
          />
        </MuiGrid>
        <MuiGrid item marginTop="12px">
          <MuiTextField sx={{ width: "98px" }} placeholder="PLZ" />
          <MuiTextField
            sx={{ width: "221px", marginLeft: "8px" }}
            placeholder="Ort"
          />
        </MuiGrid>
        <MuiGrid item marginTop="12px" marginBottom="40px">
          <MuiTextField value={country} select sx={{ width: "327px" }}>
            <MenuItem
              onClick={() => setCountry("0")}
              value="0"
              className="SelectMenuItem"
            >
              Deutschland
            </MenuItem>
            <MenuItem
              onClick={() => setCountry("1")}
              value="1"
              className="SelectMenuItem"
            >
              Poland
            </MenuItem>
          </MuiTextField>
        </MuiGrid>
        <MuiGrid
          item
          sx={{ width: "327px", marginBottom: isSmallDevice ? "0px" : "86px" }}
        >
          <MuiButton sx={{ width: "100%" }} color="primary" onClick={goToStep3}>
            weiter
          </MuiButton>
        </MuiGrid>
      </MuiGrid>
    </Container>
  );
};

export default Step2;
