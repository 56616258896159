import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const endAdornment = () => (
  <IconButton
    sx={{
      "&:hover": {
        backgroundColor: "transparent",
      },
    }}
  >
    <InputAdornment position="end">
      <img src="/img/content_copy.svg" alt="copy" />
    </InputAdornment>
  </IconButton>
);

const Steuerdaten = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <Grid container spacing={{ xs: 0 }} columnSpacing={{ md: 3 }}>
        <Grid item xs={12} md={6} marginBottom="10px">
          <TextField
            fullWidth
            label="Gründungsdatum"
            variant="standard"
            value="21.03.2012"
          />
        </Grid>
        <Grid item xs={12} md={6} marginBottom="10px">
          <TextField
            fullWidth
            label="Tag der Eintragung"
            variant="standard"
            value="01.03.2012"
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6} marginBottom="10px">
          <TextField
            fullWidth
            label="unterschriebenes Datum"
            variant="standard"
            value="18.05.2012"
          />
        </Grid>

        <Grid item xs={12} md={6} marginBottom="10px">
          <TextField
            fullWidth
            label="Bankverbindung"
            variant="standard"
            value="DE12 3456 7899 6543 33"
            InputProps={{
              endAdornment: endAdornment(),
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} marginBottom="10px">
          <TextField
            fullWidth
            label="Steuernummer"
            variant="standard"
            value="12342345675567"
            InputProps={{
              endAdornment: endAdornment(),
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} marginBottom="10px">
          <TextField
            fullWidth
            label="USt-Id"
            variant="standard"
            value="DE1234567891"
            InputProps={{
              endAdornment: endAdornment(),
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} marginBottom="10px">
          <TextField
            fullWidth
            label="Registernummer"
            variant="standard"
            value="DE1234567891"
            InputProps={{
              endAdornment: endAdornment(),
              disableUnderline: !isMobile,
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} marginBottom="10px">
          <TextField
            fullWidth
            label="zuständige  Finanzamt"
            variant="standard"
            value="Finanzamt München, Servicezentrum"
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>

        {/* <Grid item xs={12} md={6} marginBottom="10px">
          <TextField
            fullWidth
            label="Steuer-ID"
            variant="standard"
            value={currentClient?.taxId}
            disabled
          />
        </Grid> */}
        {/* <Grid item xs={12} md={6} marginBottom={isMobile ? 0 : "10px"}>
          <TextField
            fullWidth
            label="Mandant seit"
            variant="standard"
            value={getFullDate(currentClient?.clientSince)}
            disabled
            
          /> */}
        {/* </Grid> */}
      </Grid>
    </Box>
  );
};

export default Steuerdaten;
